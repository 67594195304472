import React, { ReactElement } from 'react';
import { ArrowRightIcon } from 'components/icons';
import { SequencedModalForm } from 'components/form/SequencedModalForm';
import { CreateAnnouncementModalContainerProps, FilterRecipientsFormData } from 'containers/announcements/types';
import { getDefaultValues, getFormInputsHook } from './FilterRecipientsModalContainer.forms';

export function FilterRecipientsModalContainer({
  contractorOptions,
  announcement,
  jobsites,
  updateAnnouncement,
  onCancel,
  onBack,
  onAction,
  counts,
}: CreateAnnouncementModalContainerProps): ReactElement {
  const onSubmit = (data: FilterRecipientsFormData): void => {
    updateAnnouncement({
      filterContractorIds: data?.contractorIds?.map((option) => option.value) ?? [],
      filterTrades: data?.trades?.map((option) => option.value) ?? [],
      filterLanguages: data?.languages?.map((option) => option.value) ?? [],
      filterJobsiteAccessStatus: data?.jobsiteAccessStatus?.value,
      filterAccessWithinDayRange: data?.filterAccessWithinDayRange,
    });

    onAction();
  };
  const selectedJobsites = jobsites?.filter((j) => announcement.filterJobsiteIds?.includes(j.jobsiteId));
  // if any of the *selected* jobsites do NOT have access enabled, set to false - restrict access status filters
  const isWorkerAccessEnabled = !selectedJobsites?.some((j) => !j.workerAccessEnabled);

  const formInputs = getFormInputsHook({
    contractorOptions,
    announcement,
    updateAnnouncement,
    counts,
    isWorkerAccessEnabled,
  });
  const defaultValues = React.useMemo(
    () => getDefaultValues(announcement, contractorOptions),
    [announcement, contractorOptions],
  );

  return (
    <SequencedModalForm
      open
      setOpen={onCancel}
      title="Filter Recipients"
      showBackButton
      onBack={onBack}
      actionText="Continue"
      actionButtonEnabled={counts?.totalReachableCount > 0}
      actionIcon={ArrowRightIcon}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
    />
  );
}
