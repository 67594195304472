import { TabConfig } from '@odin-labs/components';
import { useGetOrganizationContainerOrganizationInfoQuery } from 'apollo/generated/client-operations';

import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'utils';
import { AddContractorModal } from 'containers/contractors/modals';
import { AddDeveloperModal } from 'containers/developers/modals';

import { OrganizationTitle } from './components/OrganizationTitle';
import { OrganizationInfoTabContainer } from './tabs/OrganizationInfoTabContainer';
import { OrganizationTabApi, OrganizationTabProps } from './types';

const getTabsConfig = (): TabConfig<OrganizationTabProps>[] => {
  return [{ name: 'Organization Info', relativePath: '', component: OrganizationInfoTabContainer }];
};

export function OrganizationContainer(): ReactElement {
  const { organizationId } = useParams<{ organizationId: string }>();
  const baseRoute = `/organization/${organizationId}`;

  const {
    value: isAddDeveloperModalOpen,
    setTrue: openAddDeveloperModal,
    setFalse: closeAddDeveloperModal,
  } = useBoolean(false);

  const {
    value: isAddContractorModalOpen,
    setTrue: openAddContractorModal,
    setFalse: closeAddContractorModal,
  } = useBoolean(false);

  const { data, loading, error, refetch } = useGetOrganizationContainerOrganizationInfoQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId,
      includeArchived: false,
    },
  });

  const organization = data?.getOrganization;

  const tabsConfig = getTabsConfig();
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig, baseRoute });

  const [tabApi, setTabApi] = React.useState<OrganizationTabApi>(null);

  const tabsPageProps: OrganizationTabProps = {
    loading,
    error,
    refetchOrganizationData: refetch,
    onTabApiChange: setTabApi,
    organization,
    openAddDeveloperModal,
    openAddContractorModal,
  };
  return (
    <Container className="organization-container">
      <NewHeader
        title={<OrganizationTitle organization={organization} />}
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          baseRoute,
          onReloadPressed: tabApi?.refetchData,
          headerActions: ['back', 'reload'],
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      <AddDeveloperModal
        isOpen={isAddDeveloperModalOpen}
        onCancel={closeAddDeveloperModal}
        existingOrganization={organization}
        onConfirm={(): void => {
          closeAddDeveloperModal();
          refetch();
        }}
      />
      <AddContractorModal
        isOpen={isAddContractorModalOpen}
        onCancel={closeAddContractorModal}
        existingOrganization={organization}
        onConfirm={(): void => {
          closeAddContractorModal();
          refetch();
        }}
      />
    </Container>
  );
}
