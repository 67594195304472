import React from 'react';
import {
  BadgeCell,
  BadgeCellComponentProps,
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  getFixedWidthFaIcon,
  SelectOptionElement,
} from '@odin-labs/components';
import { AnnouncementStatus } from 'apollo/generated/client-operations';
import { ensureNonUndefinedFields } from 'utils';
import { getPrettyFormattedDateTimeWithTz } from 'utils/dates';
import { faTrash } from 'components/icons';
import { announcementStatusColor, announcementTitle, announcementTypeColor } from 'containers/announcement/components';
import { Announcement, AnnouncementFilters, EditableAnnouncement } from 'containers/announcements/types';
import { announcementStatusOptions, announcementTypeOptions } from 'containers/announcements/utils';

export type AnnouncementColumn = Column<Announcement>;
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

export const getColumns = ({
  openDeleteModal: openDeleteAnnouncementModal,
}: {
  openDeleteModal: (announcement: EditableAnnouncement) => void;
}): AnnouncementColumn[] => [
  {
    id: 'type',
    Header: 'Type',
    Cell: BadgeCell,
    componentProps: ({ announcementType }: Announcement): BadgeCellComponentProps => ({
      text: announcementType === 'Sms' ? 'SMS' : announcementType,
      color: announcementTypeColor[announcementType],
      size: 'lg',
    }),
  },
  {
    id: 'message',
    Header: 'Message',
    accessor: (announcement: Announcement): React.ReactElement => {
      return announcementTitle(announcement, 50);
    },
  },
  {
    id: 'scheduledDate',
    Header: 'Scheduled Date',
    Cell: BadgeCell,
    componentProps: ({ startAt, status, timeZone }: Announcement): BadgeCellComponentProps => ({
      text: startAt ? getPrettyFormattedDateTimeWithTz(startAt, timeZone) : undefined,
      color: startAt && status === AnnouncementStatus.Scheduled ? announcementStatusColor[status] : undefined,
      size: startAt && status === AnnouncementStatus.Scheduled ? 'lg' : undefined,
    }),
  },
  {
    id: 'sendDate',
    Header: 'Send Date',
    Cell: BadgeCell,
    componentProps: ({ sentAt, timeZone }: Announcement): BadgeCellComponentProps => ({
      text: sentAt ? getPrettyFormattedDateTimeWithTz(sentAt, timeZone) : undefined,
    }),
  },
  {
    id: 'jobsites',
    Header: 'Jobsite(s)',
    accessor: (announcement: Announcement): string => {
      const {
        jobsites: { edges },
      } = announcement;
      return edges.length === 1 ? edges[0]?.node?.name : `${edges.length} jobsites`;
    },
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: BadgeCell,
    componentProps: ({ status }: Announcement): BadgeCellComponentProps => ({
      text: status,
      color: announcementStatusColor[status],
      size: 'lg',
    }),
  },
  {
    Header: '',
    id: 'actions',
    Cell: DropdownButtonCell,
    CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
    componentProps: (announcement: Announcement): DropdownButtonCellComponentProps => ({
      cellAlignment: 'right',
      menuItems: [
        {
          icon: TrashIcon,
          text: 'Delete announcement',
          disabled: announcement.status !== AnnouncementStatus.Scheduled,
          theme: 'danger',
          onClick: (): void => openDeleteAnnouncementModal(announcement),
        },
      ],
    }),
  },
];

export const getFilterItems = ({
  developerOptions,
  jobsiteOptions,
  search,
  developerIds,
  jobsiteIds,
  announcementStatus,
  announcementType,
  enableSearch,
  enableStatusFiltering,
}: {
  developerOptions: SelectOptionElement[];
  jobsiteOptions: SelectOptionElement[];
  search: string;
  developerIds: string[];
  jobsiteIds: string[];
  announcementStatus: string;
  announcementType: string;
  enableSearch: boolean;
  enableStatusFiltering: boolean;
}): FilterItems<AnnouncementFilters> =>
  ensureNonUndefinedFields<FilterItems<AnnouncementFilters>>({
    developerIds: {
      header: 'Client',
      type: 'dropdown',
      defaultValue: developerIds,
      componentProps: {
        options: developerOptions,
        allowSearch: false,
      },
    },
    jobsiteIds: {
      header: 'Jobsite',
      type: 'dropdown',
      defaultValue: jobsiteIds,
      componentProps: {
        options: jobsiteOptions,
      },
    },
    announcementType: {
      header: 'Type',
      type: 'dropdown',
      defaultValue: announcementType,
      componentProps: {
        options: announcementTypeOptions,
        isMulti: false,
        allowSearch: false,
      },
    },
    ...(enableStatusFiltering
      ? {
          announcementStatus: {
            header: 'Status',
            type: 'dropdown',
            defaultValue: announcementStatus,
            componentProps: {
              options: announcementStatusOptions,
              isMulti: false,
              allowSearch: false,
            },
          },
        }
      : undefined),
    ...(enableSearch
      ? {
          search: {
            header: '',
            type: 'searchInput',
            defaultValue: search,
            isVisibleOutsideFilterOnMobile: true,
          },
        }
      : undefined),
  });
