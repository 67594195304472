import React from 'react';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { CheckIcon } from 'components/icons';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { SearchWorkerInfo } from 'components/searchBox/searchWorkerInfo/SearchWorkerInfo';
import { ReassignBadgeState } from './types';

export type ReassignBadgeModalContentProps = {
  setOpen: () => void;
  onAction: () => void;
  jobsiteId: string;
  workerId: string;
  reassignBadgeState: ReassignBadgeState;
};

export function ReassignBadgeModalContent(props: ReassignBadgeModalContentProps): React.ReactElement {
  const { setOpen, onAction, reassignBadgeState } = props;
  const { reassignBadgeAction, workerCardInfo } = reassignBadgeState ?? {};

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const onSubmit: FormOnSubmit<unknown> = async (_data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    try {
      await reassignBadgeAction();
      onAction();
      AlertInstance.alert('tc', 'success', 'Success', 'Badge reassigned');
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const workerInfoContent = (
    <div>
      <div className="odin-divide-y odin-divide-gray-200">
        {workerCardInfo?.map((workerInfo) => {
          const {
            workerCardId,
            workerId,
            firstName,
            lastName,
            phone,
            email,
            quickCode,
            trade,
            title,
            unionAffiliation,
          } = workerInfo;

          return (
            <SearchWorkerInfo
              key={workerCardId}
              isClickable={false}
              className="!odin-p-0 !odin-py-3"
              searchWorkerResult={{
                id: workerId,
                firstName,
                lastName,
                quickCode,
                phone,
                email,
                trade,
                tradeClass: title,
                unionAffiliation,
                searchTerm: null,
                badgeId: null,
                imageUrl: null,
                jobsitesInfo: null,
              }}
            />
          );
        })}
      </div>
      <h3 className="odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900 odin-mt-6">
        Would you like to reassign this badge to this worker?
      </h3>
    </div>
  );

  return (
    <ModalFormContent
      setOpen={setOpen}
      renderAbove={workerInfoContent}
      inputs={null}
      onSubmit={onSubmit}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Yes"
      actionIcon={CheckIcon}
      cancelText="No"
      actionButtonWithSpinner={isFetching}
    />
  );
}
