import React from 'react';
import cn from 'classnames';
import i18n from 'i18n-js';
import { useInterval } from 'utils';
import { ClockIcon } from 'components/icons';
import type { TimerProps } from './types';

const classes = {
  icon: (isLastMinute: boolean): string => (isLastMinute ? 'odin-text-odin-danger' : 'odin-text-odin-primary'),
  text: (isLastMinute: boolean): string =>
    cn('odin-text-sm', isLastMinute ? 'odin-text-odin-danger' : 'odin-text-odin-navy'),
};

const INITIAL_SECONDS_LEFT = 180;

export function Timer(props: TimerProps): React.ReactElement {
  const { seconds = INITIAL_SECONDS_LEFT, text, textCopy, language, onTimeout, resetKey } = props;
  const [secondsLeft, setSecondsLeft] = React.useState<number>(seconds);

  useInterval(() => {
    setSecondsLeft(secondsLeft - 1);
  }, 1000);

  React.useEffect(() => {
    if (secondsLeft === 0) {
      onTimeout?.();
    }
  }, [secondsLeft]);

  React.useEffect(() => {
    if (resetKey > 0) {
      setSecondsLeft(seconds);
    }
  }, [resetKey]);

  const isLastMinute = secondsLeft < 60;
  const leftTime = new Date(secondsLeft * 1000).toISOString().substring(14, 19);
  const leftTimeText = textCopy
    ? i18n.t(textCopy, { locale: language ?? 'en', time: leftTime })
    : text?.replace('{{time}}', leftTime);

  return (
    <div className="odin-flex odin-justify-center odin-items-center odin-gap-x-1.5">
      <ClockIcon className={classes.icon(isLastMinute)} />
      <span className={classes.text(isLastMinute)}>{leftTimeText}</span>
    </div>
  );
}
