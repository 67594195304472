import React from 'react';
import moment from 'moment';
import i18n from 'i18n-js';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import {
  File as ServerFile,
  JobsiteWorkerDocument,
  AdditionalFieldValue,
  WorkerCitizenshipStatus,
  UserUpdateIdentityInput,
  WorkerOnboardingUpdateWorkerMutationVariables,
} from 'apollo/generated/client-operations';
import { DropzoneProps } from 'components/dropzone';
import { dropzoneValidation } from 'components/dropzone/utils';
import {
  FormDefaultValue,
  FormInput,
  FormInputTypes,
  TypedFormInputs,
  UseFormMethods,
  getUpdateInputValueFunction,
} from 'components/form';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { SelectOptionsElement } from 'components/select/types';
import {
  EditFormDataType,
  MedicalConditionsDataType,
  OnboardingStepKey,
  SignatureDataType,
  WorkerDocumentsDataType,
  JobsiteWorker,
  JwDocument,
} from 'containers/workerOnboarding/types';
import {
  toFancySelectOptions,
  statesOptions,
  emergencyContactRelationshipOptions as emergencyContactRelationshipOptionsValues,
  genderOptions as genderOptionsValues,
  jobTitleOptions as jobTitleOptionsValues,
  primaryLanguageOptions as primaryLanguageOptionsValues,
  raceOptions as raceOptionsValues,
  tradeOptions as tradeOptionsValues,
  unionAffiliationOptions as unionAffiliationOptionsValues,
  govCardTypes,
  oshaCardTypes,
  sstCardTypes,
  textFieldInputMaxLengthDefault,
} from 'utils/constants';
import { getCleaveDateFromISODate, getDateFromCleave } from 'utils/dates';
import {
  cleaveDateValidation,
  phoneNumberValidation,
  emailValidation,
  zipCodeValidation,
  ssnValidation,
  cleaveAgeValidation,
} from 'utils/validation';
import { DocumentKey, byCreatedAtDescending } from 'containers/worker/utils';
import { ensureNonUndefinedFields, getPhoneNumberAsE164, nullifyEmptyFields, stringifyEmptyFields } from 'utils';
import { getFields } from 'types';
import { EditJobsiteConfigurationFormData } from 'containers/jobsiteConfiguration/types';
import { Copy } from './languages';
import { getDocumentsConfig, getVisibleDocuments, getStepFieldsConfig, getCompletedSteps } from './utils';

type DocumentWithPreviewUrl = { previewUrl?: string; getPreviewUrl?: (documentKey: string) => string };
type DocumentWithRequiredInputs = { areInputsRequired?: boolean };
type DocumentWithExistingDocumentAlert = {
  showExistingDocumentAlert?: boolean;
  getShowExistingDocumentAlert?: (documentKey: string) => boolean;
};
type DocumentWithConditionalRequiredInputs<TDocument extends WorkerDocumentsDataType = WorkerDocumentsDataType> = {
  suppressRequiredWhenDocIsNotDirty?: boolean;
  dirtyFields?: Record<keyof TDocument, boolean>;
};

export type JobsiteWorkerDocumentField = 'front' | 'back' | 'card-type' | 'expiration-date';

const accept: DropzoneProps['accept'] = {
  'application/pdf': [],
  'image/*': ['.png', '.jpg', '.jpeg', '.heic', '.heif'],
};

export type JobsiteWorkerDocumentArgs<TDocument extends WorkerDocumentsDataType = WorkerDocumentsDataType> =
  DocumentWithPreviewUrl &
    DocumentWithExistingDocumentAlert &
    DocumentWithConditionalRequiredInputs<TDocument> & {
      documentKey: string;
      label: string;
      options?: SelectOptionsElement[] | null;
      hasBack?: boolean;
      hasExpirationDate?: boolean;
      children?: TypedFormInputs<TDocument>;
      requiredFields?: JobsiteWorkerDocumentField[];
    };

/**
 * Suppresses all required fields if `suppressRequiredWhenDocIsNotDirty` is true
 * and the document has not dirty fields.
 */
const withConditionalRequired = <TDocument extends WorkerDocumentsDataType = WorkerDocumentsDataType>(
  args: Pick<JobsiteWorkerDocumentArgs<TDocument>, 'suppressRequiredWhenDocIsNotDirty' | 'dirtyFields'> & {
    document: FormInput<TDocument>;
  },
): FormInput<TDocument> => {
  const { document, suppressRequiredWhenDocIsNotDirty, dirtyFields } = args;
  const { children, files } = document;
  if (suppressRequiredWhenDocIsNotDirty && dirtyFields && (children || files)) {
    const inputNames = [
      ...(Array.isArray(children) ? children.map((c) => c.name) : Object.keys(children ?? {})),
      ...(files ?? []).map((f) => f.name),
    ];
    const isDocumentDirty = Object.keys(dirtyFields).some((df) => inputNames.includes(df));
    if (!isDocumentDirty) {
      const inputs = [...(Array.isArray(children) ? children : Object.values(children ?? {})), ...files];
      inputs.forEach((input: FormInput<TDocument>) => {
        const { required } = input.validation ?? {};
        if (required) {
          Object.assign(input.validation, { required: false });
        }
      });
    }
  }
  return document;
};

export const generateJobsiteWorkerDocument = <TDocument extends WorkerDocumentsDataType = WorkerDocumentsDataType>(
  args: JobsiteWorkerDocumentArgs<TDocument>,
): FormInput<TDocument> => {
  const {
    documentKey,
    label,
    options,
    hasExpirationDate,
    hasBack,
    children,
    requiredFields = [],
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
  } = args;

  const frontFileLabel = `${label} ${hasBack ? 'front' : ''} upload`;
  type TName = FormInput<TDocument>['name'];

  const workerDocument: FormInput<TDocument> = {
    name: `${documentKey}-document` as TName,
    element: FormInputTypes.JobsiteWorkerDocument,
    elementProps: {
      label,
      previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
      showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
    },
    layout: 'col-12',
    children:
      ensureNonUndefinedFields<TypedFormInputs<TDocument>>({
        ...(options?.length && {
          [`${documentKey}-card-type`]: {
            element: FormInputTypes.Select,
            label: 'Card type',
            elementProps: {
              placeholder: 'Select one',
              options,
            },
            validation: {
              required: requiredFields.includes('card-type'),
            },
            layout: 'col-sm-4',
          },
        }),
        ...(hasExpirationDate && {
          [`${documentKey}-expiration-date`]: {
            element: FormInputTypes.Cleave,
            label: 'Expiration date',
            elementProps: {
              cleaveType: 'date',
              placeholder: 'Expiration date',
            },
            validation: {
              pattern: cleaveDateValidation,
              required: requiredFields.includes('expiration-date'),
            },
            layout: 'col-sm-6',
          },
        }),
        ...children,
      }) ?? [],
    files: [
      {
        name: `${documentKey}-front` as TName,
        element: FormInputTypes.Dropzone,
        label: frontFileLabel,
        elementProps: {
          accept,
          uploadEntirePdf: documentKey === DocumentKey.SiteSpecificOrientation,
          multiple: documentKey === DocumentKey.SiteSpecificOrientation,
        },
        validation: {
          required: requiredFields.includes('front'),
          validate: dropzoneValidation,
        },
        layout: hasBack ? 'col-sm-6' : 'col-12',
      },
    ],
  };

  if (hasBack) {
    const backFileLabel = `${label} back upload`;
    workerDocument.files = [
      ...workerDocument.files,
      {
        name: `${documentKey}-back` as TName,
        element: FormInputTypes.Dropzone,
        label: backFileLabel,
        elementProps: {
          accept,
          uploadEntirePdf: documentKey === DocumentKey.SiteSpecificOrientation,
        },
        validation: {
          required: requiredFields.includes('back'),
          validate: dropzoneValidation,
        },
        layout: 'col-sm-6',
      },
    ];
  }

  return withConditionalRequired({ document: workerDocument, suppressRequiredWhenDocIsNotDirty, dirtyFields });
};

export type JobsiteMedicalWorkerDocumentField =
  | 'result'
  | 'front'
  | 'back'
  | 'conditional-access-expiration-date'
  | 'date-signed';

export type JobsiteMedicalWorkerDocumentArgs = DocumentWithConditionalRequiredInputs & {
  documentKey: string;
  label: string;
  previewUrl: string;
  showExistingDocumentAlert: boolean;
  hideDocumentLabel?: boolean;
  hasBack?: boolean;
  onChangeValue?: (key: string, value: string) => void;
  showExpiration?: boolean;
  showDateSigned?: boolean;
  showReason?: boolean;
  showNotes?: boolean;
  selectOptions?: Array<string>;
  requiredFields?: JobsiteMedicalWorkerDocumentField[];
};

const testReasonsOptions = ['Random', 'Suspicion', 'Post accident'];

export const getDocumentPreviewUrl = (documentId: string): string => {
  return documentId && `/onboarding/document/${documentId}/print`;
};

export const generateJobsiteMedicalWorkerDocument = (
  args: JobsiteMedicalWorkerDocumentArgs,
): FormInput<WorkerDocumentsDataType> => {
  const {
    documentKey,
    label,
    previewUrl,
    showExistingDocumentAlert,
    selectOptions,
    onChangeValue,
    hasBack = false,
    showExpiration = false,
    showDateSigned = false,
    hideDocumentLabel = false,
    showReason = false,
    showNotes = false,
    requiredFields = [],
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  } = args;

  const frontFileLabel = `${label} ${hasBack ? 'front' : ''} upload`;
  const workerDocument: FormInput<WorkerDocumentsDataType> = {
    name: `${documentKey}-document`,
    element: FormInputTypes.JobsiteWorkerDocument,
    elementProps: {
      label,
      hideLabel: hideDocumentLabel,
      previewUrl,
      showExistingDocumentAlert,
    },
    layout: 'col-12',
    children: selectOptions?.length
      ? [
          {
            name: `${documentKey}-result`,
            element: FormInputTypes.Select,
            label: 'Test result',
            elementProps: {
              placeholder: 'Select one',
              onChange: (value: string): void => onChangeValue?.(documentKey, value),
              options: selectOptions.map((option) => ({ name: option, value: option })),
            },
            validation: {
              required: requiredFields.includes('result'),
            },
            layout: 'col-sm-4',
          },
        ]
      : [],
    files: [
      {
        name: `${documentKey}-front`,
        element: FormInputTypes.Dropzone,
        label: frontFileLabel,
        elementProps: {
          accept,
        },
        validation: {
          required: requiredFields.includes('front'),
          validate: dropzoneValidation,
        },
        layout: hasBack ? 'col-sm-6' : 'col-12',
      },
    ],
  };

  if (showExpiration) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-conditional-access-expiration-date`]: {
        element: FormInputTypes.Cleave,
        label: 'Conditional access expiration date',
        elementProps: {
          cleaveType: 'date',
          placeholder: 'mm/dd/yyyy',
        },
        validation: { pattern: cleaveDateValidation, required: true },
        layout: 'col-sm-6',
      },
    };
  }

  if (showDateSigned) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-date-signed`]: {
        element: FormInputTypes.Cleave,
        label: 'Date signed',
        elementProps: {
          cleaveType: 'date',
          placeholder: 'Date signed',
        },
        validation: {
          pattern: cleaveDateValidation,
          required: requiredFields.includes('date-signed'),
        },
        layout: 'col-sm-4',
      },
    };
  }

  if (showReason) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-test-reason`]: {
        element: FormInputTypes.Select,
        label: 'Reason for test',
        elementProps: {
          placeholder: 'Select one',
          options: testReasonsOptions.map((option) => ({ name: option, value: option })),
        },
        validation: {
          required: true,
        },
        layout: 'col-sm-4',
      },
    };
  }

  if (showNotes) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-notes`]: {
        element: FormInputTypes.Field,
        label: 'Additional Notes (Max 150 Characters)',
        elementProps: {
          maxLength: textFieldInputMaxLengthDefault,
          type: 'textarea',
        },
        layout: 'col-sm-12',
      },
    };
  }

  if (hasBack) {
    const backFileLabel = `${label} back upload`;
    workerDocument.files = [
      ...workerDocument.files,
      {
        name: `${documentKey}-back`,
        element: FormInputTypes.Dropzone,
        label: backFileLabel,
        elementProps: {
          accept,
        },
        validation: {
          required: requiredFields.includes('back'),
          validate: dropzoneValidation,
        },
        layout: 'col-sm-6',
      },
    ];
  }

  return withConditionalRequired({ document: workerDocument, suppressRequiredWhenDocIsNotDirty, dirtyFields });
};

export const oshaWorkerDocumentInput = (
  args?: DocumentWithPreviewUrl &
    DocumentWithRequiredInputs &
    DocumentWithConditionalRequiredInputs &
    DocumentWithExistingDocumentAlert,
): FormInput<WorkerDocumentsDataType> => {
  const {
    areInputsRequired = false,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
  } = args ?? {};
  const documentKey = DocumentKey.OshaCard;

  return withConditionalRequired({
    document: {
      name: `${documentKey}-document`,
      element: FormInputTypes.JobsiteWorkerDocument,
      label: 'OSHA card',
      elementProps: {
        previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
        showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
      },
      layout: 'col-12',
      children: [
        {
          name: `${documentKey}-type`,
          element: FormInputTypes.Select,
          label: 'Card type',
          elementProps: {
            placeholder: 'Select one',
            options: oshaCardTypes.map((option) => ({ name: option, value: option })),
          },
          validation: {
            required: areInputsRequired,
          },
          layout: 'col-sm-4',
        },
        {
          name: `${documentKey}-issue-date`,
          element: FormInputTypes.Cleave,
          label: 'Course date',
          elementProps: {
            cleaveType: 'date',
            placeholder: 'Course date',
          },
          validation: {
            pattern: cleaveDateValidation,
            required: areInputsRequired,
          },
          layout: 'col-sm-4',
        },
        {
          name: `${documentKey}-number`,
          element: FormInputTypes.Field,
          label: 'ID number',
          elementProps: {
            placeholder: 'ID number',
          },
          validation: {
            required: areInputsRequired,
          },
          layout: 'col-sm-4',
        },
      ],
      files: [
        {
          name: `${documentKey}-front`,
          element: FormInputTypes.Dropzone,
          label: 'OSHA card upload front',
          elementProps: {
            accept,
          },
          validation: {
            required: areInputsRequired,
            validate: dropzoneValidation,
          },
          layout: 'col-12',
        },
      ],
    },
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  });
};

export type NewYorkSstDocumentInputArgs = DocumentWithPreviewUrl &
  DocumentWithRequiredInputs &
  DocumentWithConditionalRequiredInputs &
  DocumentWithExistingDocumentAlert & {
    sstExempt?: boolean;
    sstRequired?: boolean;
    onSSTExemptChange?: (value: boolean) => void;
  };

export const newYorkSstDocumentInput = (args: NewYorkSstDocumentInputArgs): FormInput<WorkerDocumentsDataType> => {
  const {
    sstExempt,
    sstRequired,
    areInputsRequired = false,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    onSSTExemptChange,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
  } = args;

  const children: FormInput<WorkerDocumentsDataType>[] = [];
  const documentKey = DocumentKey.NycSiteSafetyTrainingCard;

  const isSSTExemptVisible = !!onSSTExemptChange;
  if (isSSTExemptVisible) {
    children.push({
      name: `${documentKey}-exempt`,
      element: FormInputTypes.Toggle,
      label: 'Exempt from SST',
      elementProps: {
        toggleDescription: 'Please indicate if this user is exempt from the SST',
        checked: sstExempt,
        onChange: onSSTExemptChange,
      },
      layout: 'col-sm-4',
    });
  }

  children.push({
    name: `${documentKey}-is-training-connect-card`,
    element: FormInputTypes.ToggleField,
    label: 'Training Connect Card',
    layout: isSSTExemptVisible ? 'col-sm-8' : 'col-sm-12',
  });

  if (sstExempt) {
    children.push({
      name: `${documentKey}-exemption-reason`,
      element: FormInputTypes.Field,
      label: 'SST Exempt Reason',
      elementProps: {
        placeholder: 'SST Exempt Reason',
      },
      layout: 'col-sm-8',
    });
  } else {
    children.push(
      {
        name: `${documentKey}-type`,
        element: FormInputTypes.Select,
        label: 'Card type',
        elementProps: {
          placeholder: 'Select one',
          options: sstCardTypes.map((sstCardOption) => ({ name: sstCardOption, value: sstCardOption })),
        },
        validation: {
          required: sstRequired,
        },
        layout: 'col-sm-4',
      },
      {
        name: `${documentKey}-issue-date`,
        element: FormInputTypes.Cleave,
        label: 'Issue date',
        elementProps: {
          cleaveType: 'date',
          placeholder: 'Issue date',
          onValueChange: (value: string, { setValue, getValues }: UseFormMethods<WorkerDocumentsDataType>) => {
            const isTrainingConnectCardFieldName = `${documentKey}-is-training-connect-card`;
            const isTrainingConnectCard = getValues(isTrainingConnectCardFieldName)?.toString() ?? 'false';

            // only make toggle smart if it not previously set to true
            if (isTrainingConnectCard !== 'true') {
              const issueMoment = moment(value, 'MM/DD/YYYY', true);
              const newIsTrainingConnectCard = issueMoment.isSameOrAfter('01/01/2022') ? 'true' : 'false';
              const isTrainingConnectCardChanged = newIsTrainingConnectCard !== isTrainingConnectCard;

              if (isTrainingConnectCardChanged) {
                setValue(isTrainingConnectCardFieldName, newIsTrainingConnectCard, { shouldDirty: true });
              }
            }
          },
        },
        validation: {
          pattern: cleaveDateValidation,
          required: areInputsRequired,
        },
        layout: 'col-sm-4',
      },
      {
        name: `${documentKey}-expiration-date`,
        element: FormInputTypes.Cleave,
        label: 'Expiration date',
        elementProps: {
          cleaveType: 'date',
          placeholder: 'Expiration date',
        },
        validation: {
          pattern: cleaveDateValidation,
          required: areInputsRequired,
        },
        layout: 'col-sm-4',
      },
    );
  }

  return withConditionalRequired({
    document: {
      name: `${documentKey}-document`,
      element: FormInputTypes.JobsiteWorkerDocument,
      label: 'NYC Site Safety Training Card',
      elementProps: {
        previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
        showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
      },
      layout: 'col-12',
      files: sstExempt
        ? []
        : [
            {
              name: `${documentKey}-front`,
              element: FormInputTypes.Dropzone,
              label: 'SST card front upload',
              elementProps: {
                accept,
              },
              validation: {
                required: areInputsRequired,
                validate: dropzoneValidation,
              },
              layout: 'col-sm-6',
            },
            {
              name: `${documentKey}-back`,
              element: FormInputTypes.Dropzone,
              label: 'SST card back upload',
              elementProps: {
                accept,
              },
              layout: 'col-sm-6',
            },
          ],
      children,
    },
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  });
};

const selectStatesOptions = statesOptions.map(({ value, label }) => ({ value, name: label }));

export const governmentIdDocumentInput = (
  args?: DocumentWithPreviewUrl &
    DocumentWithRequiredInputs &
    DocumentWithConditionalRequiredInputs &
    DocumentWithExistingDocumentAlert,
): FormInput<WorkerDocumentsDataType> => {
  const {
    areInputsRequired,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
  } = args ?? {};
  const documentKey = DocumentKey.GovernmentIssuedId;

  return withConditionalRequired({
    document: {
      name: `${documentKey}-document`,
      element: FormInputTypes.JobsiteWorkerDocument,
      label: 'Government ID',
      elementProps: {
        previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
        showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
      },
      layout: 'col-12',
      children: [
        {
          name: `${documentKey}-type`,
          element: FormInputTypes.Select,
          label: 'Card type',
          elementProps: {
            placeholder: 'Select one',
            options: govCardTypes.map((govCardOption) => {
              return {
                name: govCardOption,
                value: govCardOption,
              };
            }),
          },
          layout: 'col-sm-4',
        },
        {
          name: `${documentKey}-state-issued`,
          element: FormInputTypes.Select,
          label: 'State issued',
          elementProps: {
            placeholder: 'Select one',
            options: selectStatesOptions,
          },
          layout: 'col-sm-4',
        },
        {
          name: `${documentKey}-number`,
          element: FormInputTypes.Field,
          label: 'ID number',
          elementProps: {
            placeholder: 'ID number',
          },
          validation: { required: areInputsRequired },
          layout: 'col-sm-4',
        },
        {
          name: `${documentKey}-issue-date`,
          element: FormInputTypes.Cleave,
          label: 'Issue date',
          elementProps: {
            cleaveType: 'date',
            placeholder: 'Issue date',
          },
          validation: { pattern: cleaveDateValidation, required: areInputsRequired },
          layout: 'col-sm-4',
        },
        {
          name: `${documentKey}-expiration-date`,
          element: FormInputTypes.Cleave,
          label: 'Expiration date',
          elementProps: {
            cleaveType: 'date',
            placeholder: 'Expiration date',
          },
          validation: { pattern: cleaveDateValidation, required: areInputsRequired },
          layout: 'col-sm-4',
        },
      ],
      files: [
        {
          name: `${documentKey}-front`,
          element: FormInputTypes.Dropzone,
          label: 'Government card upload',
          elementProps: {
            accept,
          },
          validation: {
            required: areInputsRequired,
            validate: dropzoneValidation,
          },
          layout: 'col-12',
        },
      ],
    },
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  });
};

export const lirrBlueTrainingDocumentInput = (
  args?: DocumentWithPreviewUrl & DocumentWithRequiredInputs & DocumentWithExistingDocumentAlert,
): FormInput<WorkerDocumentsDataType> => {
  const { areInputsRequired, ...restArgs } = args;
  return generateJobsiteWorkerDocument({
    documentKey: DocumentKey.LIRRBlueTraining,
    label: 'LIRR Blue Training',
    hasExpirationDate: true,
    requiredFields: areInputsRequired ? ['front', 'expiration-date'] : undefined,
    ...restArgs,
  });
};

export const confinedSpacesTrainingDocumentInput = (
  args?: DocumentWithPreviewUrl & DocumentWithRequiredInputs & DocumentWithExistingDocumentAlert,
): FormInput<WorkerDocumentsDataType> => {
  const { areInputsRequired, ...restArgs } = args;
  return generateJobsiteWorkerDocument({
    documentKey: DocumentKey.ConfinedSpacesTraining,
    label: 'Confined Spaces Training',
    hasExpirationDate: true,
    requiredFields: areInputsRequired ? ['front', 'expiration-date'] : undefined,
    ...restArgs,
  });
};

export const workerDocumentsKeys = [
  DocumentKey.NycSiteSafetyTrainingCard,
  DocumentKey.OshaCard,
  DocumentKey.GovernmentIssuedId,
  DocumentKey.LIRRBlueTraining,
  DocumentKey.ConfinedSpacesTraining,
  // DocumentKey.AdditionalCertifications,
] as const;

type WorkerDocumentKey = typeof workerDocumentsKeys[number];

type WorkerDocumentsInputArgs = Omit<NewYorkSstDocumentInputArgs, 'sstRequired' | 'previewUrl'> & {
  jobsiteWorker: JobsiteWorker;
  filteredDocumentTypeIds: Record<string, string>;
  getPreviewUrl: DocumentWithPreviewUrl['getPreviewUrl'];
};

export const getDocumentsWithExistingDocumentAlerts = (
  args: Pick<WorkerDocumentsInputArgs, 'jobsiteWorker'> & {
    stepKey: OnboardingStepKey;
    documentKeys: readonly string[];
    documents: JwDocument[];
  },
): Record<string, boolean> => {
  const { stepKey, jobsiteWorker, documentKeys, documents } = args;
  const completedSteps = jobsiteWorker && getCompletedSteps(jobsiteWorker);
  const workerHasMultipleJobsites = jobsiteWorker?.contractorWorker.worker.jobsiteWorkers.count > 1;
  const isStepNotStarted = !completedSteps?.[stepKey];
  const existingDocumentKeys = documents.map(({ key }) => key);

  return Object.fromEntries(
    documentKeys.map((documentKey) => [
      documentKey,
      workerHasMultipleJobsites && isStepNotStarted && existingDocumentKeys.includes(documentKey),
    ]),
  );
};

export const workerDocumentsInput = ({
  jobsiteWorker,
  sstExempt,
  filteredDocumentTypeIds,
  suppressRequiredWhenDocIsNotDirty,
  dirtyFields,
  onSSTExemptChange,
  getPreviewUrl,
  getShowExistingDocumentAlert,
}: WorkerDocumentsInputArgs): FormInput<WorkerDocumentsDataType>[] => {
  if (!jobsiteWorker) return [];

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const documentsConfig = getDocumentsConfig(jobsite.modules);
  const sstRequired = !!documentsConfig[DocumentKey.NycSiteSafetyTrainingCard]?.isRequired;
  const getDocumentIndex = (key: string): number => documentsConfig[key as DocumentKey]?.index ?? 999;
  const visibleDocuments = getVisibleDocuments(jobsiteWorker);

  const commonArgs: Pick<
    WorkerDocumentsInputArgs,
    | 'getPreviewUrl'
    | 'suppressRequiredWhenDocIsNotDirty'
    | 'dirtyFields'
    | 'getShowExistingDocumentAlert'
    | 'areInputsRequired'
  > = {
    getPreviewUrl,
    getShowExistingDocumentAlert,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    areInputsRequired: true,
  };

  const workerDocuments: Record<WorkerDocumentKey, () => FormInput<WorkerDocumentsDataType>> = {
    [DocumentKey.NycSiteSafetyTrainingCard]: () =>
      newYorkSstDocumentInput({
        sstExempt,
        sstRequired,
        onSSTExemptChange,
        ...commonArgs,
      }),
    [DocumentKey.OshaCard]: () => oshaWorkerDocumentInput(commonArgs),
    [DocumentKey.GovernmentIssuedId]: () => governmentIdDocumentInput(commonArgs),
    [DocumentKey.LIRRBlueTraining]: () => lirrBlueTrainingDocumentInput(commonArgs),
    [DocumentKey.ConfinedSpacesTraining]: () => confinedSpacesTrainingDocumentInput(commonArgs),
    // [DocumentKey.AdditionalCertifications]: () =>
    //   generateJobsiteWorkerDocument({
    //     documentKey: DocumentKey.AdditionalCertifications,
    //     label: 'Additional certifications',
    //     ...commonArgs,
    //   }),
  };

  return Object.entries(workerDocuments)
    .filter(([key]) => filteredDocumentTypeIds[key] && visibleDocuments?.includes(key))
    .sort(([aKey], [bKey]) => getDocumentIndex(aKey) - getDocumentIndex(bKey))
    .flatMap(([, getInputs]) => getInputs());
};

export const medicalConditionsInputs = (): FormInput<MedicalConditionsDataType>[] => {
  return [
    {
      name: 'customContent',
      element: FormInputTypes.CustomContent,
      elementProps: {
        content: <h3 className="mt-4">Medical conditions</h3>,
      },
      layout: 'col-12',
    },
    {
      name: 'medicalNotes',
      element: FormInputTypes.Field,
      layout: 'col-8',
      label: 'Please enter any pre-existing conditions or other notes for this worker',
      elementProps: {
        placeholder: 'Medical notes',
      },
    },
  ];
};

type PersonalInfoInputArgs = {
  contractorOptions: SelectOptionElement[];
  jobsiteWorker: JobsiteWorker;
  canAddUnions: boolean;
  language?: string;
  raceOptions: SelectOptionElement[];
  citizenshipStatusOptions: SelectOptionElement<WorkerCitizenshipStatus>[];
  veteranOptions: SelectOptionElement[];
  isEmailEditable: boolean;
  isPhoneEditable: boolean;
};

const unionAffiliationOptions = toFancySelectOptions(unionAffiliationOptionsValues);
const jobTitleOptions = toFancySelectOptions(jobTitleOptionsValues);
const tradeOptions = toFancySelectOptions(tradeOptionsValues);
const emergencyContactRelationshipOptions = toFancySelectOptions(emergencyContactRelationshipOptionsValues);
const primaryLanguageOptions = toFancySelectOptions(primaryLanguageOptionsValues);
const genderOptions = toFancySelectOptions(genderOptionsValues);

export const getRaceOptions = (language: string): SelectOptionElement[] => {
  return toFancySelectOptions(raceOptionsValues).map((option) => ({
    ...option,
    label: i18n.t(`race_option_${option.key}`, { locale: language }),
  }));
};
export const getVeteranOptions = (language: string): SelectOptionElement[] => [
  {
    label: i18n.t(Copy.VETERAN, { locale: language }),
    value: 'Veteran',
  },
  {
    label: i18n.t(Copy.NON_VETERAN, { locale: language }),
    value: 'Non veteran',
  },
];
export const getCitizenshipStatusOptions = (language: string): SelectOptionElement<WorkerCitizenshipStatus>[] => [
  {
    label: i18n.t(Copy.CITIZEN, { locale: language }),
    value: WorkerCitizenshipStatus.Citizen,
  },
  {
    label: i18n.t(Copy.NON_CITIZEN, { locale: language }),
    value: WorkerCitizenshipStatus.NonCitizen,
  },
];

const jobsiteConfigWorkerInfo = getFields<EditJobsiteConfigurationFormData['workerInfo']>();

export const getPersonalFormInputs = (args?: PersonalInfoInputArgs): TypedFormInputs<EditFormDataType> => {
  const {
    contractorOptions,
    jobsiteWorker,
    canAddUnions,
    language,
    raceOptions,
    citizenshipStatusOptions,
    veteranOptions,
    isEmailEditable,
    isPhoneEditable,
  } = args ?? {};

  const fieldsConfig = getStepFieldsConfig(OnboardingStepKey.PersonalInfo, jobsiteWorker);

  const getFieldConfig = (
    configFieldKey: keyof typeof jobsiteConfigWorkerInfo,
  ): { isRequired: boolean; isHidden: boolean } => {
    const { isRequired, isHidden } = fieldsConfig[configFieldKey] ?? {};
    return { isRequired: !!isRequired, isHidden: !!isHidden };
  };

  const phone = getFieldConfig(jobsiteConfigWorkerInfo.phone);
  const email = getFieldConfig(jobsiteConfigWorkerInfo.email);
  const ssnLastFour = getFieldConfig(jobsiteConfigWorkerInfo.ssnLastFour);
  const race = getFieldConfig(jobsiteConfigWorkerInfo.race);
  const gender = getFieldConfig(jobsiteConfigWorkerInfo.gender);
  const trade = getFieldConfig(jobsiteConfigWorkerInfo.trade);
  const title = getFieldConfig(jobsiteConfigWorkerInfo.title);
  const emergencyContact = getFieldConfig(jobsiteConfigWorkerInfo.emergencyContact);
  const address = getFieldConfig(jobsiteConfigWorkerInfo.address);
  const addressZipCode = getFieldConfig(jobsiteConfigWorkerInfo.addressZipCode);
  const citizenshipStatus = getFieldConfig(jobsiteConfigWorkerInfo.citizenshipStatus);
  const veteranStatus = getFieldConfig(jobsiteConfigWorkerInfo.veteranStatus);
  const unionAffiliation = getFieldConfig(jobsiteConfigWorkerInfo.unionAffiliation);

  const getLabel = (copy: Copy, isRequired: boolean): string =>
    [i18n.t(copy, { locale: language }), isRequired ? '' : i18n.t(Copy.OPTIONAL, { locale: language })].join(' ');

  const getPlaceholder = (copy: Copy): string => i18n.t(copy, { locale: language });

  const getRequiredValidationMessage = (copy: Copy): string =>
    `${i18n.t(copy, { locale: language })} ${i18n.t(Copy.REQUIRED, { locale: language })}`;

  const getInvalidValidationMessage = (copy: Copy): string => i18n.t(copy, { locale: language });

  return {
    firstName: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.FIRST_NAME, true),
      elementProps: {
        placeholder: getPlaceholder(Copy.FIRST_NAME),
      },
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.FIRST_NAME),
        },
      },
      layout: 'col-sm-6',
    },
    middleInitial: {
      element: FormInputTypes.Cleave,
      label: getLabel(Copy.MIDDLE_INITIAL, false),
      elementProps: {
        placeholder: getPlaceholder(Copy.MIDDLE_INITIAL),
        cleaveType: 'initial',
      },
      layout: 'col-sm-6',
    },
    lastName: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.LAST_NAME, true),
      elementProps: {
        placeholder: getPlaceholder(Copy.LAST_NAME),
      },
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.LAST_NAME),
        },
      },
      layout: 'col-sm-6',
    },
    suffix: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.SUFFIX, false),
      elementProps: {
        placeholder: getPlaceholder(Copy.SUFFIX),
      },
      layout: 'col-sm-6',
    },
    birthDate: {
      element: FormInputTypes.Cleave,
      label: getLabel(Copy.BIRTH_DATE, true),
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        cleaveType: 'birthDate',
      },
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.BIRTH_DATE),
        },
        pattern: {
          value: cleaveDateValidation,
          message: getInvalidValidationMessage(Copy.INVALID_DATE),
        },
        validate: cleaveAgeValidation(getInvalidValidationMessage(Copy.INVALID_DATE), 14, 100),
      },
      layout: 'col-sm-6',
    },
    ssnLastFour: {
      element: FormInputTypes.Cleave,
      label: getLabel(Copy.LAST_FOUR_SSN, ssnLastFour.isRequired),
      isHidden: ssnLastFour.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.LAST_FOUR_SSN),
        cleaveType: 'ssn4',
      },
      validation: {
        pattern: {
          value: ssnValidation,
          message: getInvalidValidationMessage(Copy.SSN_4_DIGIT),
        },
        required: {
          value: ssnLastFour.isRequired,
          message: getRequiredValidationMessage(Copy.LAST_FOUR_SSN),
        },
      },
      layout: 'col-sm-6',
    },
    race: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.RACE, race.isRequired),
      isHidden: race.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        options: raceOptions,
      },
      validation: {
        required: {
          value: race.isRequired,
          message: getRequiredValidationMessage(Copy.RACE),
        },
      },
      layout: 'col-sm-6',
    },
    gender: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.GENDER, gender.isRequired),
      isHidden: gender.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        options: genderOptions,
      },
      validation: {
        required: {
          value: gender.isRequired,
          message: getRequiredValidationMessage(Copy.GENDER),
        },
      },
      layout: 'col-sm-6',
    },
    primaryLanguage: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.CHOOSE_LANGUAGE, false),
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        options: primaryLanguageOptions,
      },
      layout: 'col-sm-6',
    },
    citizenshipStatus: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.CITIZENSHIP_STATUS, citizenshipStatus.isRequired),
      isHidden: citizenshipStatus.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        options: citizenshipStatusOptions,
      },
      validation: {
        required: {
          value: citizenshipStatus.isRequired,
          message: getRequiredValidationMessage(Copy.CITIZENSHIP_STATUS),
        },
      },
      layout: 'col-sm-3',
    },
    isVeteran: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.VETERAN_STATUS, veteranStatus.isRequired),
      isHidden: veteranStatus.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        options: veteranOptions,
      },
      validation: {
        required: {
          value: veteranStatus.isRequired,
          message: getRequiredValidationMessage(Copy.VETERAN_STATUS),
        },
      },
      layout: citizenshipStatus.isHidden ? 'col-sm-6' : 'col-sm-3',
    },
    addressLine1: {
      element: FormInputTypes.PlacesAutocomplete,
      label: getLabel(Copy.ADDRESS, address.isRequired),
      isHidden: address.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.ADDRESS),
        onCommit: fillAddressDetails,
      },
      validation: {
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.ADDRESS),
        },
      },
      layout: 'col-sm-6',
    },
    addressLine2: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.ADDRESS_2, false),
      isHidden: address.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.ADDRESS_2),
      },
      layout: 'col-sm-6',
    },
    addressCity: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.CITY, address.isRequired),
      isHidden: address.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.CITY),
      },
      validation: {
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.CITY),
        },
      },
      layout: 'col-sm-6',
    },
    addressState: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.STATE, address.isRequired),
      isHidden: address.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.STATE),
        options: statesOptions,
      },
      validation: {
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.STATE),
        },
      },
      layout: addressZipCode.isHidden ? 'col-sm-6' : 'col-sm-3',
    },
    addressZipCode: {
      element: FormInputTypes.Cleave,
      label: getLabel(Copy.ZIP, addressZipCode.isRequired),
      isHidden: addressZipCode.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.ZIP),
        cleaveType: 'zipcode',
      },
      validation: {
        pattern: {
          value: zipCodeValidation,
          message: getInvalidValidationMessage(Copy.ZIP_CODE_5_DIGITS),
        },
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.ZIP),
        },
      },
      layout: address.isHidden ? 'col-sm-6' : 'col-sm-3',
    },
    phoneNumber: {
      element: FormInputTypes.Cleave,
      label: getLabel(Copy.PHONE_NUMBER, phone.isRequired),
      isHidden: phone.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.PHONE_NUMBER),
        cleaveType: 'tel',
        disabled: !isPhoneEditable,
      },
      validation: {
        pattern: {
          value: phoneNumberValidation,
          message: getInvalidValidationMessage(Copy.INVALID_PHONE),
        },
        required: {
          value: phone.isRequired,
          message: getRequiredValidationMessage(Copy.PHONE_NUMBER),
        },
      },
      layout: 'col-sm-6',
    },
    email: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.EMAIL_ADDRESS, email.isRequired),
      isHidden: email.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.EMAIL_ADDRESS),
        disabled: !isEmailEditable,
      },
      validation: {
        pattern: {
          value: emailValidation,
          message: getInvalidValidationMessage(Copy.INVALID_EMAIL),
        },
        required: {
          value: email.isRequired,
          message: getRequiredValidationMessage(Copy.EMAIL_ADDRESS),
        },
      },
      layout: 'col-sm-6',
    },
    emergencyContactName: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.EMERGENCY_CONTACT, emergencyContact.isRequired),
      isHidden: emergencyContact.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.EMERGENCY_CONTACT),
      },
      validation: {
        required: {
          value: emergencyContact.isRequired,
          message: getRequiredValidationMessage(Copy.EMERGENCY_CONTACT),
        },
      },
      layout: 'col-sm-6',
    },
    emergencyContactRelationship: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.RELATIONSHIP, emergencyContact.isRequired),
      isHidden: emergencyContact.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        isCreateable: true,
        options: emergencyContactRelationshipOptions,
      },
      validation: {
        required: {
          value: emergencyContact.isRequired,
          message: getRequiredValidationMessage(Copy.RELATIONSHIP),
        },
      },
      layout: 'col-sm-6',
    },
    emergencyContactPhone: {
      element: FormInputTypes.Cleave,
      label: getLabel(Copy.EMERGENCY_CONTACT_PHONE_NUMBER, emergencyContact.isRequired),
      isHidden: emergencyContact.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.EMERGENCY_CONTACT_PHONE_NUMBER),
        cleaveType: 'tel',
      },
      validation: {
        pattern: {
          value: phoneNumberValidation,
          message: getInvalidValidationMessage(Copy.INVALID_PHONE),
        },
        required: {
          value: emergencyContact.isRequired,
          message: getRequiredValidationMessage(Copy.EMERGENCY_CONTACT_PHONE_NUMBER),
        },
      },
      layout: 'col-sm-6',
    },
    trade: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.TRADE, trade.isRequired),
      isHidden: trade.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        isCreateable: false,
        options: tradeOptions,
      },
      validation: {
        required: {
          value: trade.isRequired,
          message: getRequiredValidationMessage(Copy.TRADE),
        },
      },
      layout: 'col-sm-6',
    },
    jobTitle: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.JOB_TITLE, title.isRequired),
      isHidden: title.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        isCreateable: false,
        options: jobTitleOptions,
      },
      validation: {
        required: {
          value: title.isRequired,
          message: getRequiredValidationMessage(Copy.JOB_TITLE),
        },
      },
      layout: 'col-sm-6',
    },
    unionAffiliation: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.UNION_AFFILIATION, unionAffiliation.isRequired),
      isHidden: unionAffiliation.isHidden,
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        isCreateable: canAddUnions,
        menuPlacement: 'top',
        options: unionAffiliationOptions,
      },
      validation: {
        required: {
          value: unionAffiliation.isRequired,
          message: getRequiredValidationMessage(Copy.UNION_AFFILIATION),
        },
      },
      layout: 'col-sm-6',
    },
    contractor: {
      element: FormInputTypes.FancySelect,
      label: getLabel(Copy.CONTRACTOR, true),
      elementProps: {
        placeholder: getPlaceholder(Copy.SELECT_ONE),
        options: contractorOptions,
        menuPlacement: 'top',
      },
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.CONTRACTOR),
        },
      },
      layout: 'col-sm-6',
    },
  };
};

export const signatureFormInput = (): FormInput<SignatureDataType> => {
  return {
    name: 'signature-front',
    element: FormInputTypes.Dropzone,
    label: 'Signature',
    elementProps: {
      accept,
      sourceType: 'fileAndStylus',
    },
    validation: {
      required: true,
    },
    layout: 'col-sm-6',
  };
};

export const getPersonalDefaultValues = ({
  jobsiteWorker,
  raceOptions,
  citizenshipStatusOptions,
  veteranOptions,
  contractorOptions,
}: {
  jobsiteWorker: JobsiteWorker;
  raceOptions: SelectOptionElement[];
  veteranOptions: SelectOptionElement[];
  contractorOptions: SelectOptionElement[];
  citizenshipStatusOptions: SelectOptionElement<WorkerCitizenshipStatus>[];
}): EditFormDataType => {
  const { contractorWorker, payrollId, jobsiteContractor } = jobsiteWorker ?? {};
  const { worker, contractor } = contractorWorker ?? {};
  const { contractorId, organization } = contractor ?? {};
  const {
    workerId,
    user,
    middleInitial,
    suffix,
    birthDate,
    ssnLastFour,
    race,
    gender,
    primaryLanguage,
    citizenshipStatus,
    isVeteran,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    jobTitle,
    emergencyContactName,
    emergencyContactRelationship,
    emergencyContactPhone,
    trade,
    unionAffiliation,
    isSkilled,
  } = worker ?? {};
  const { userAccountId, identity } = user ?? {};
  const { firstName, lastName, email, phoneNumber } = identity ?? {};

  return {
    isSkilled,
    workerId,
    userAccountId,
    ...stringifyEmptyFields({
      firstName,
      lastName,
      email,
      middleInitial,
      suffix,
      birthDate: getCleaveDateFromISODate(birthDate),
      ssnLastFour,
      addressLine1,
      addressLine2,
      addressCity,
      addressZipCode,
      phoneNumber,
      emergencyContactName,
      emergencyContactPhone,
      payrollId,
      organizationName: organization?.name,
      contractorId,
      jobsiteId: jobsiteContractor?.jobsite?.jobsiteId,
    }),
    ...nullifyEmptyFields({
      contractor: contractorOptions.find((opt) => opt.value === contractorId),
      race: raceOptions.find((opt) => opt.value === race),
      gender: genderOptions.find((opt) => opt.value === gender),
      primaryLanguage: primaryLanguageOptions.find((opt) => opt.value === primaryLanguage),
      citizenshipStatus: citizenshipStatusOptions.find((opt) => opt.value === citizenshipStatus),
      isVeteran: veteranOptions.find((opt) => opt.value === (isVeteran ? 'Veteran' : 'Non veteran')),
      addressState: statesOptions.find((opt) => opt.value === addressState),
      jobTitle: jobTitleOptions.find((opt) => opt.value === jobTitle),
      emergencyContactRelationship: emergencyContactRelationshipOptions.find(
        (opt) => opt.value === emergencyContactRelationship,
      ),
      trade: tradeOptions.find((opt) => opt.value === trade),
      unionAffiliation: unionAffiliationOptions.find((opt) => opt.value === unionAffiliation),
    }),
  };
};

export type PersonalInfoUpdateInput = {
  userUpdateIdentityInput: UserUpdateIdentityInput;
} & WorkerOnboardingUpdateWorkerMutationVariables;

export const getPersonalInfoUpdateInput = (
  jobsiteWorker: JobsiteWorker,
  data: EditFormDataType,
  dirtyFields: DeepMap<EditFormDataType, true>,
): PersonalInfoUpdateInput => {
  const { contractorWorker } = jobsiteWorker ?? {};
  const { worker } = contractorWorker ?? {};
  const { workerId, user } = worker ?? {};

  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const isVeteranInputValue = dirtyFields.isVeteran ? data.isVeteran?.value === 'Veteran' : undefined;
  const birthDateInputValue = dirtyFields.birthDate ? getDateFromCleave(data.birthDate) || null : undefined;
  const phoneNumberInputValue = getPhoneNumberAsE164(getUpdateInputValue('phoneNumber'));

  return ensureNonUndefinedFields<PersonalInfoUpdateInput>({
    workerId,
    workerInput: {
      middleInitial: getUpdateInputValue('middleInitial'),
      suffix: getUpdateInputValue('suffix'),
      race: getUpdateInputValue('race'),
      gender: getUpdateInputValue('gender'),
      primaryLanguage: getUpdateInputValue('primaryLanguage'),
      citizenshipStatus: getUpdateInputValue('citizenshipStatus'),
      isVeteran: isVeteranInputValue,
      addressLine1: getUpdateInputValue('addressLine1'),
      addressLine2: getUpdateInputValue('addressLine2'),
      addressCity: getUpdateInputValue('addressCity'),
      addressState: getUpdateInputValue('addressState'),
      addressZipCode: getUpdateInputValue('addressZipCode'),
      emergencyContactName: getUpdateInputValue('emergencyContactName'),
      emergencyContactRelationship: getUpdateInputValue('emergencyContactRelationship'),
      emergencyContactPhone: getPhoneNumberAsE164(getUpdateInputValue('emergencyContactPhone')),
      trade: getUpdateInputValue('trade'),
      jobTitle: getUpdateInputValue('jobTitle'),
      unionAffiliation: getUpdateInputValue('unionAffiliation'),
      isSkilled: getUpdateInputValue('isSkilled'),
      isSupervisor: getUpdateInputValue('isSupervisor'),
      workerBaseInfoInput: {
        birthDate: birthDateInputValue,
        ssnLastFour: getUpdateInputValue('ssnLastFour'),
      },
    },
    userUpdateIdentityInput: {
      userAccountId: user?.userAccountId,
      userIdentityInput: {
        firstName: getUpdateInputValue('firstName'),
        lastName: getUpdateInputValue('lastName'),
        email: getUpdateInputValue('email'),
        phoneNumber: phoneNumberInputValue,
      },
    },
  });
};

export type JobsiteWorkerDocumentsData = {
  defaultFormValues: FormDefaultValue<WorkerDocumentsDataType>[];
  documents: JwDocument[];
};

export const getFilesData = (
  workerDocumentKey: string,
  files: ServerFile[],
): JobsiteWorkerDocumentsData['defaultFormValues'] => {
  if (workerDocumentKey === DocumentKey.SiteSpecificOrientation) {
    return [
      {
        name: `${workerDocumentKey}-front`,
        value: files ?? null,
      },

      {
        name: `${workerDocumentKey}-back`,
        value: null,
      },
    ];
  }

  const [frontFile, backFile] = files ?? [];
  return [
    {
      name: `${workerDocumentKey}-front`,
      value: frontFile ? [frontFile] : null,
    },
    {
      name: `${workerDocumentKey}-back`,
      value: backFile ? [backFile] : null,
    },
  ];
};

const getDefaultValue = (additionalField: AdditionalFieldValue): unknown => {
  if (additionalField?.key?.includes('date')) {
    return getCleaveDateFromISODate(additionalField?.value);
  }
  if (additionalField?.key === 'acknowledgment-status') {
    return additionalField.value === 'confirmed';
  }
  return additionalField?.value;
};

export const getWorkerDocumentKey = (workerDocumentTypeKey: string, createdAt: string): string => {
  if (workerDocumentTypeKey === DocumentKey.Generic || workerDocumentTypeKey === DocumentKey.AdditionalCertifications) {
    const documentDiscriminator = moment(createdAt).format('YYYYMMDDHHmmssSSS');
    return `${workerDocumentTypeKey}-${documentDiscriminator}`;
  }
  return workerDocumentTypeKey;
};

export const getJobsiteWorkerDocumentsData = (
  jobsiteWorkerDocuments: JobsiteWorkerDocument[],
): JobsiteWorkerDocumentsData =>
  jobsiteWorkerDocuments.reduce(
    (result, jobsiteWorkerDocument) => {
      const { jobsiteWorkerDocumentVersions, jobsiteWorkerDocumentType, jobsiteWorkerDocumentId } =
        jobsiteWorkerDocument;

      const documentVersions = [...(jobsiteWorkerDocumentVersions ?? [])].sort(byCreatedAtDescending);
      const [documentLatestVersion] = documentVersions;

      const workerDocumentKey = getWorkerDocumentKey(
        jobsiteWorkerDocumentType?.workerDocumentType.key,
        jobsiteWorkerDocument.objectHistory.createdAt,
      );

      const { files } = documentLatestVersion ?? {};
      const additionalFields = documentLatestVersion?.additionalFieldValues ?? [];

      const filesData = getFilesData(workerDocumentKey, files);

      result.defaultFormValues.push(
        ...filesData,
        ...additionalFields.map((additionalField) => {
          return {
            name: `${workerDocumentKey}-${additionalField?.key}`,
            value: getDefaultValue(additionalField),
          };
        }),
      );

      result.documents.push({ key: workerDocumentKey, id: jobsiteWorkerDocumentId });

      return result;
    },
    {
      defaultFormValues: [],
      documents: [],
    } as JobsiteWorkerDocumentsData,
  );
