import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardFooter, CardHeader, CardImg, Col, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  GetWorkerDataDocument,
  UserPendingChangeType,
  MutationUpdateWorkerArgs,
  MutationUploadSingleFileArgs,
  SelfOnboardingStepKey,
} from 'apollo/generated/client-operations';
import { InputFile, ServerFile } from 'types';
import { NewButton, Table } from '@odin-labs/components';
import { DebugIcon, EyeIcon, EyeSlashIcon } from 'components/icons';
import { AlertInstance } from 'components/alertNotification';
import { NewDropzone } from 'components/dropzone';
import { JobsiteWorkerSiteSafetyStatus } from 'components/jobsiteWorkerCard/jobsiteWorkerSiteSafetyStatus';
import { JobsiteWorkerAccessComponent } from 'components/jobsiteWorkerCard/jobsiteWorkerAccessComponent';
import { LoadingError } from 'components/loadingError';
import { BaseModal } from 'components/modals';
import {
  CancelUserChangeModal,
  EditPersonalInfoModal,
  EditSelfOnboardingWorkerWarningModal,
  RemoveWorkerProfilePhotoModal,
  SubmitUserChangeModal,
} from 'containers/worker/modals';

import { JsonTreeModal } from 'components/modals/JsonTreeModal';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistorySource } from 'components/objectHistory/types';
import { JobsiteWorkerOnboardingStatus } from 'components/jobsiteWorkerCard/jobsiteWorkerOnboardingStatus';
import { FILE_UPLOAD, UPDATE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import { UploadFileResponse } from 'containers/workerOnboarding/types';
import { citizenshipStatusOptions } from 'containers/worker/helpers/utils';
import { WorkerTabProps } from 'containers/worker/types';
import {
  getFormattedPhoneNumber,
  getWorkerFullName,
  isServerFile,
  uploadCompressedFile,
  useBoolean,
  useIsMounted,
  useModalState,
  useResettableState,
} from 'utils';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { AuthContext } from 'auth';
import { to } from 'acl';
import workerImage from 'images/worker.png';
import { CancelUserChangeAction, SubmitUserChangeAction } from 'containers/worker/components';
import { WorkerOverviewBasic } from './WorkerOverviewBasic';
import { getCommentsColumns } from './WorkerOverview.tables';

export function WorkerOverview(props: WorkerTabProps): ReactElement {
  const { loading, worker, refetchWorkerData, jobsiteWorkers, jobsiteIds, isLocked, onTabApiChange } = props;
  const initialProfilePictureFile = worker?.profilePictureCropped;

  const { phoneNumber, email, pendingChanges } = worker?.user.identity ?? {};
  const isPhoneEditable = !phoneNumber || worker?.editableFields.phone;
  const isEmailEditable = !email || worker?.editableFields.email;

  const { selfOnboardingCurrentStep } = worker ?? {};
  const isSelfOnboardingWorker =
    selfOnboardingCurrentStep &&
    selfOnboardingCurrentStep !== SelfOnboardingStepKey.ReviewCompletedProfile &&
    selfOnboardingCurrentStep !== SelfOnboardingStepKey.Closed;

  const isMounted = useIsMounted();

  const {
    value: isEditPersonalInfoModalOpen,
    setTrue: openEditPersonalInfoModal,
    setFalse: closeEditPersonalInfoModal,
  } = useBoolean(false);

  const {
    value: userChangeToSubmit,
    isModalOpen: isSubmitUserChangeModalOpen,
    openModal: openSubmitUserChangeModal,
    closeModal: closeSubmitUserChangeModal,
    resetModalValue: resetUserChangeToSubmit,
  } = useModalState<{ type: UserPendingChangeType }>(null);

  const {
    value: userChangeToCancel,
    isModalOpen: isCancelUserChangeModalOpen,
    openModal: openCancelUserChangeModal,
    closeModal: closeCancelUserChangeModal,
    resetModalValue: resetUserChangeToCancel,
  } = useModalState<{ type: UserPendingChangeType }>(null);

  const {
    value: { then: onEditSelfOnboardingWorkerConfirm },
    setValue: confirmSelfOnboardingWorkerEdit,
    resetValue: closeEditSelfOnboardingWorkerWarningModal,
  } = useResettableState<{ then: () => void }>({ then: null }, { then: null });

  const confirmSelfOnboardingWorkerEditIfNeeded = ({ then }: { then: () => void }): void => {
    if (isSelfOnboardingWorker) {
      confirmSelfOnboardingWorkerEdit({ then });
    } else {
      then();
    }
  };

  const [hideSsn, setHideSsn] = useState<boolean>(true);
  const { value: isWorkerIdsModalOpen, setTrue: openWorkerIdsModal, setFalse: closeWorkerIdsModal } = useBoolean(false);
  const [profilePictureFile, setProfilePictureFile] = useState<File | ServerFile>(initialProfilePictureFile);
  const [isFetchingUpdateWorker, setIsFetchingUpdateWorker] = useState<boolean>(false);
  const {
    value: isUploadProfilePictureModalOpen,
    setTrue: openUploadProfilePictureModal,
    setFalse: closeUploadProfilePictureModal,
  } = useBoolean(false);

  const {
    value: isRemoveProfilePictureModalOpen,
    setTrue: openRemoveProfilePictureModal,
    setFalse: closeRemoveProfilePictureModal,
  } = useBoolean(false);

  // UploadProfilePictureModal doesn't unmount when closed,
  // so we need to reset profilePictureFile when the modal is canceled
  // if the profilePictureFile was altered by user
  const onUploadProfilePictureModalCancel = React.useCallback(() => {
    if (profilePictureFile !== initialProfilePictureFile) {
      setProfilePictureFile(initialProfilePictureFile);
    }
    closeUploadProfilePictureModal();
  }, [initialProfilePictureFile, profilePictureFile, setProfilePictureFile, closeUploadProfilePictureModal]);

  const activeJobsiteData = jobsiteWorkers.filter((jw) => {
    return jw.endDate == null || new Date(jw.endDate) > new Date();
  });

  const { currentUser: user } = React.useContext(AuthContext);
  const isNotContractorMemberUser = !user.isContractor;

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  const [updateWorker] = useMutation<Worker, MutationUpdateWorkerArgs>(UPDATE_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: [GetWorkerDataDocument],
  });

  const workerComments = worker?.workerComments.edges
    ?.map(({ node }) => node)
    .filter((workerComment) => jobsiteIds.includes(workerComment?.jobsite?.jobsiteId));

  const activeJobsiteWorkerIds = activeJobsiteData.map((jw) => jw.jobsiteWorkerId);
  const activeJobsiteIds = activeJobsiteData.map((jw) => jw.jobsiteContractor?.jobsite?.jobsiteId);

  const idsToDisplay = {
    userAccountId: worker?.user?.userAccountId,
    workerId: worker?.workerId,
    fusionAuthId: worker?.user?.identity?.fusionAuthId,
    jobsiteWorkerIds: activeJobsiteWorkerIds,
    jobsiteIds: activeJobsiteIds,
    createdMethod: worker?.createdMethod,
  };

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  const commentsColumns = React.useMemo(() => getCommentsColumns(), []);

  if (isLocked) {
    return <WorkerOverviewBasic worker={worker} />;
  }

  const workerCitizenshipStatus = citizenshipStatusOptions.find(
    (opt) => opt.value === worker?.citizenshipStatus,
  )?.label;

  return (
    <>
      <Row>
        <Col lg="3">
          <Card className="pt-4">
            <CardImg
              className="m-auto w-50"
              src={worker?.profilePictureCropped?.downloadUrl || workerImage}
              alt="Card image cap"
            />
            <div className="w-50 m-auto odin-flex odin-justify-between odin-pt-2">
              <NewButton
                text="Clear"
                onClick={(): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openRemoveProfilePictureModal })}
                theme="naked"
                size="sm"
              />
              <NewButton
                text="Update"
                onClick={(): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openUploadProfilePictureModal })}
                theme="naked"
                size="sm"
              />
            </div>
            <div className="card-body text-center">
              <h2 className="card-title">{getWorkerFullName(worker)}</h2>
              <p className="small text-muted mb-0 text-gray-700">{worker?.trade}</p>
            </div>
            <BaseModal
              isOpen={isUploadProfilePictureModalOpen}
              toggle={onUploadProfilePictureModalCancel}
              onCancel={onUploadProfilePictureModalCancel}
              actionButtonEnabled={!isFetchingUpdateWorker}
              onAction={async (): Promise<void> => {
                setIsFetchingUpdateWorker(true);
                if (!isServerFile(profilePictureFile)) {
                  const fileId = profilePictureFile
                    ? await uploadCompressedFile(profilePictureFile as File, uploadFile)
                    : null;
                  await updateWorker({
                    variables: {
                      workerId: worker.workerId,
                      workerInput: {
                        profilePictureFileId: fileId,
                      },
                    },
                  });
                }
                if (isMounted()) {
                  setIsFetchingUpdateWorker(false);
                  closeUploadProfilePictureModal();
                }
              }}
            >
              {isFetchingUpdateWorker ? (
                <LoadingError loading />
              ) : (
                <>
                  <h2>Update profile photo</h2>
                  <NewDropzone
                    name="profilePicture"
                    label="Profile Picture"
                    accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
                    value={profilePictureFile}
                    onChange={(file: InputFile): void => {
                      setProfilePictureFile(file);
                    }}
                  />
                </>
              )}
            </BaseModal>
            <RemoveWorkerProfilePhotoModal
              isOpen={isRemoveProfilePictureModalOpen}
              closeModal={closeRemoveProfilePictureModal}
              onConfirm={(): void => {
                setProfilePictureFile(null);
                closeRemoveProfilePictureModal();
              }}
              workerId={worker.workerId}
            />
          </Card>
          {activeJobsiteData.length ? (
            <>
              <p className="small text-muted">Active Jobsite Assignments</p>
              {activeJobsiteData.map((jobsiteWorker) => {
                return (
                  <Card className="p-4" key={jobsiteWorker?.jobsiteWorkerId}>
                    <div>
                      <h2 className="m-0">{jobsiteWorker?.jobsiteContractor?.jobsite?.name}</h2>
                      <p className="mb-0">{jobsiteWorker?.jobsiteContractor?.contractor?.organization?.name}</p>
                      <JobsiteWorkerAccessComponent jobsiteWorker={jobsiteWorker} type="badge" />
                      <div className="d-flex justify-content-between">
                        <p className="font-weight-bold mb-0">Onboarding Status</p>
                        <JobsiteWorkerOnboardingStatus jobsiteWorker={jobsiteWorker} showLabel={false} />
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="font-weight-bold mb-0">Site Orientation Status</p>
                        <JobsiteWorkerSiteSafetyStatus jobsiteWorker={jobsiteWorker} showLabel={false} />
                      </div>
                    </div>
                  </Card>
                );
              })}
            </>
          ) : null}
        </Col>
        <Col lg="9">
          <Card>
            <CardHeader>
              <h4 className="card-header-title mr-auto">Personal</h4>
              {user.isAllowed(to.editWorkerPersonalInfo) && (
                <Button
                  onClick={(): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openEditPersonalInfoModal })}
                  outline
                >
                  Edit
                </Button>
              )}
            </CardHeader>
            <JsonTreeModal
              title={`IDs for ${worker?.user?.identity?.firstName} ${worker?.user?.identity?.lastName}`}
              isOpen={isWorkerIdsModalOpen}
              closeModal={closeWorkerIdsModal}
              data={idsToDisplay}
            />
            <div className="card-footer card-footer-boxed">
              <Row>
                <Col lg="6">
                  <Row className="mb-3">
                    <Col md="6">Date of Birth</Col>
                    <Col md="6">{getISODateTimeToFormattedDate(convertUTCtoLocalDate(worker?.birthDate))}</Col>
                  </Row>
                  <Row className="mb-3 data-hj-suppress">
                    <Col md="6">
                      Last 4 of SSN{' '}
                      <span
                        className="no-print text-primary odin-cursor-pointer"
                        onClick={(): void => setHideSsn(!hideSsn)}
                      >
                        {hideSsn ? <EyeSlashIcon /> : <EyeIcon />}
                      </span>
                    </Col>
                    <Col md="6">{!hideSsn ? worker?.ssnLastFour : null}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Race</Col>
                    <Col md="6">{worker?.race}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Gender</Col>
                    <Col md="6">{worker?.gender}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Primary Language</Col>
                    <Col md="6">{worker?.primaryLanguage}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Citizenship Status</Col>
                    <Col md="6">{workerCitizenshipStatus}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Veteran Status</Col>
                    <Col md="6">{worker?.isVeteran ? 'Yes' : 'No'}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Trade</Col>
                    <Col md="6">{worker?.trade}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Job Title</Col>
                    <Col md="6">{worker?.jobTitle}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">Union affiliation</Col>
                    <Col md="6">{worker?.unionAffiliation}</Col>
                  </Row>
                </Col>
                <Col lg="6">
                  <Row className="mb-3">
                    <Col md="5">Address</Col>
                    <Col md="7">
                      {worker?.addressLine1} {worker?.addressLine2}
                      <br />
                      {worker?.addressCity}, {worker?.addressState} {worker?.addressZipCode}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="5">Phone</Col>
                    <Col md="7" className="odin-flex odin-gap-y-1 odin-justify-between odin-flex-wrap">
                      <span className="odin-w-5/6">{getFormattedPhoneNumber(phoneNumber)}</span>
                      <div className="odin-flex odin-gap-x-3">
                        <SubmitUserChangeAction
                          type={UserPendingChangeType.Phone}
                          hasPendingChange={!!pendingChanges?.phoneNumber}
                          isHidden={isPhoneEditable}
                          onClick={openSubmitUserChangeModal}
                        />
                        <CancelUserChangeAction
                          type={UserPendingChangeType.Phone}
                          isHidden={!pendingChanges?.phoneNumber}
                          onClick={openCancelUserChangeModal}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="5">Email address</Col>
                    <Col md="7" className="odin-flex odin-gap-y-1 odin-justify-between odin-flex-wrap">
                      <span className="odin-w-5/6">{email}</span>
                      <div className="odin-flex odin-gap-x-3">
                        <SubmitUserChangeAction
                          type={UserPendingChangeType.Email}
                          hasPendingChange={!!pendingChanges?.email}
                          isHidden={isEmailEditable}
                          onClick={openSubmitUserChangeModal}
                        />
                        <CancelUserChangeAction
                          type={UserPendingChangeType.Email}
                          isHidden={!pendingChanges?.email}
                          onClick={openCancelUserChangeModal}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="5">Emergency Contact</Col>
                    <Col md="7">{worker?.emergencyContactName}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="5">Emergency Relationship</Col>
                    <Col md="7">{worker?.emergencyContactRelationship}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="5">Emergency Phone</Col>
                    <Col md="7">{getFormattedPhoneNumber(worker?.emergencyContactPhone)}</Col>
                  </Row>
                  <Row>
                    <Col>
                      {user.isAllowed(to.viewWorkerIDs) && (
                        <a
                          href=""
                          onClick={(e): void => {
                            e.preventDefault();
                            openWorkerIdsModal();
                          }}
                          className="odin-underline"
                        >
                          <DebugIcon className="odin-mr-3 odin-text-base" />
                          View worker IDs
                        </a>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <CardFooter>
              <ObjectHistory source={ObjectHistorySource.Worker} object={worker} />
            </CardFooter>
          </Card>
          {jobsiteWorkers.length && isNotContractorMemberUser ? (
            <Card>
              <CardHeader className="!odin-border-b-0">
                <h4 className="card-header-title mr-auto">Comments</h4>
              </CardHeader>
              <div className="card-footer card-footer-boxed m-0 p-0">
                <Table
                  loading={loading}
                  columns={commentsColumns}
                  data={workerComments}
                  remote
                  cellClassName="!odin-pl-5"
                  disableGlobalFilter
                  disableSortBy
                />
              </div>
              {workerComments?.length ? (
                <CardFooter className="!odin-border-t-0">
                  <Link to={`/worker/${worker?.workerId}/comments`}>See all comments</Link>
                </CardFooter>
              ) : null}
            </Card>
          ) : null}
        </Col>
      </Row>
      <EditPersonalInfoModal
        worker={worker}
        isOpen={isEditPersonalInfoModalOpen}
        onCancel={closeEditPersonalInfoModal}
        refetch={refetchWorkerData}
      />
      <EditSelfOnboardingWorkerWarningModal
        isOpen={!!onEditSelfOnboardingWorkerConfirm}
        closeModal={closeEditSelfOnboardingWorkerWarningModal}
        onConfirm={(): void => {
          onEditSelfOnboardingWorkerConfirm();
          closeEditSelfOnboardingWorkerWarningModal();
        }}
      />
      <SubmitUserChangeModal
        isOpen={isSubmitUserChangeModalOpen}
        onCancel={closeSubmitUserChangeModal}
        onClosed={resetUserChangeToSubmit}
        onConfirm={closeSubmitUserChangeModal}
        type={userChangeToSubmit?.type}
        worker={worker}
        refetch={refetchWorkerData}
      />
      <CancelUserChangeModal
        isOpen={isCancelUserChangeModalOpen}
        onCancel={closeCancelUserChangeModal}
        onClosed={resetUserChangeToCancel}
        onConfirm={closeCancelUserChangeModal}
        type={userChangeToCancel?.type}
        worker={worker}
        refetch={refetchWorkerData}
      />
    </>
  );
}
