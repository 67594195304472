import React from 'react';
import { Modal, ModalProps } from '@odin-labs/components';
import { getFullNameForUser } from 'utils';
import { UserHardHatIcon } from 'components/icons';
import { AssignWorkerModalProps, AssignWorkerModalState } from './types';
import { AssignWorkerModalContent, AssignWorkerStep, CompleteJobsiteAssignmentModalContent } from './components';

export function AssignWorkerModal(props: AssignWorkerModalProps): React.ReactElement {
  const { isOpen, closeModal, selectedWorker, defaultContractorId, jobsiteWorkers } = props;
  const [step, setStep] = React.useState<AssignWorkerStep>(AssignWorkerStep.AssignWorker);
  const [state, setState] = React.useState<AssignWorkerModalState>();

  React.useEffect(() => {
    if (isOpen && step !== AssignWorkerStep.AssignWorker) {
      setStep(AssignWorkerStep.AssignWorker);
    }
  }, [isOpen]);

  const getModalProps = (): Omit<ModalProps, 'open' | 'setOpen'> => {
    switch (step) {
      case AssignWorkerStep.AssignWorker:
        return {
          actionsPanel: null,
          title: selectedWorker?.user
            ? `Assign ${getFullNameForUser(selectedWorker?.user)} to jobsite`
            : 'Assign workers',
          children: (
            <AssignWorkerModalContent
              closeModal={closeModal}
              onConfirm={(data, nextStep): void => {
                setState(data);
                setStep(nextStep);
              }}
              selectedWorker={selectedWorker}
              defaultContractorId={defaultContractorId}
              jobsiteWorkers={jobsiteWorkers}
            />
          ),
        };

      case AssignWorkerStep.CompleteJobsiteAssignment: {
        const currentAssignments = state.siblingJobsiteWorker
          ?.map((jw) => jw?.jobsiteContractor?.jobsite?.name)
          .join(' & ');

        return {
          actionsPanel: null,
          title: `The worker is currently assigned to ${currentAssignments}`,
          children: (
            <CompleteJobsiteAssignmentModalContent
              closeModal={closeModal}
              onConfirm={setStep}
              selectedWorker={selectedWorker}
              assignWorkerModalState={state}
            />
          ),
        };
      }

      case AssignWorkerStep.AlreadyAssignedWorker:
        return {
          subtitle: 'This worker is already assigned to this jobsite and cannot be functional transferred here',
          cancelText: 'Close',
          actionText: 'Go to profile',
          actionIcon: UserHardHatIcon,
          onAction: (): void => {
            window.open(`/worker/${selectedWorker?.workerId}/jobsites`, '_blank');
          },
        };

      default:
        return {};
    }
  };

  const modalProps = getModalProps();
  return <Modal open={isOpen} setOpen={closeModal} {...modalProps} />;
}
