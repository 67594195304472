import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { RadioGroupOption } from '@odin-labs/components';
import { BriefcaseIcon } from 'components/icons';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import {
  CreateAnnouncementData,
  MakeAnnouncementFormData,
  AnnouncementType,
  JobsiteSelectOptionElement,
} from 'containers/announcements/types';

const announcementTypeOptions: RadioGroupOption<AnnouncementType>[] = [
  {
    label: 'Text Message (SMS)',
    value: AnnouncementType.Sms,
  },
  {
    label: 'Email',
    value: AnnouncementType.Email,
  },
];

export const getFormInputs = (
  jobsiteOptions: JobsiteSelectOptionElement[],
): TypedFormInputs<MakeAnnouncementFormData> => ({
  jobsiteIds: {
    element: FormInputTypes.OdinSelect,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsiteOptions,
      icon: BriefcaseIcon,
      disabled: (jobsiteOptions?.length ?? 0) < 1,
      multiple: true,
      clearToNull: true,
    },
    validation: { required: true },
    layout: GridColSpan.SpanFull,
  },
  announcementType: {
    element: FormInputTypes.RadioGroup,
    label: 'Type',
    elementProps: {
      options: announcementTypeOptions,
      preventSubmitOnEnter: true,
      layout: 'horizontal',
    },
    validation: { required: true },
    layout: GridColSpan.SpanFull,
  },
});

type MakeAnnouncementFormDataInputHook = {
  jobsiteOptions: JobsiteSelectOptionElement[];
  announcement: CreateAnnouncementData;
  updateAnnouncement: (data: Partial<CreateAnnouncementData>) => void;
};

export const getFormInputsHook =
  (args: MakeAnnouncementFormDataInputHook) =>
  ({ watch }: UseFormMethods<MakeAnnouncementFormData>): TypedFormInputs<MakeAnnouncementFormData> => {
    const { jobsiteOptions, updateAnnouncement } = args;
    const jobsiteIds = watch('jobsiteIds');

    React.useEffect(() => {
      updateAnnouncement({ filterJobsiteIds: jobsiteIds?.map(({ value }) => value) });
    }, [jobsiteIds]);

    return React.useMemo(() => getFormInputs(jobsiteOptions), [jobsiteOptions]);
  };

export const getDefaultValues = (
  jobsiteOptions: JobsiteSelectOptionElement[],
  announcement: CreateAnnouncementData,
): MakeAnnouncementFormData => ({
  jobsiteIds:
    jobsiteOptions?.length === 1
      ? [jobsiteOptions[0]]
      : jobsiteOptions?.filter((jo) => announcement.filterJobsiteIds?.includes(jo.value)) ?? null,
  announcementType: announcement.announcementType
    ? announcementTypeOptions.find((ao) => ao.value === announcement.announcementType) ?? null
    : announcementTypeOptions[0],
});
