import React from 'react';
import cn from 'classnames';
import { Badge, getFaIcon, Label } from '@odin-labs/components';
import { faCalendar } from 'components/icons';

const classes = {
  title: cn('odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium'),
  count: cn('odin-ml-3'),
  icon: cn('odin-text-odin-primary odin-mr-1.5'),
  dates: cn('odin-ml-9'),
};

const CalendarIcon = getFaIcon({ icon: faCalendar, className: classes.icon });

export type FormSubmissionReportSectionTitleProps = React.PropsWithChildren<{
  title: string;
  count: number;
  dates?: string;
}>;

export function FormSubmissionReportSectionTitle(props: FormSubmissionReportSectionTitleProps): React.ReactElement {
  const { title, count, dates, children } = props;
  return (
    <div>
      <span className={classes.title}>{title}</span>
      <Badge className={classes.count} color="gray" text={count.toString()} />
      {dates && <Label className={classes.dates} size="lg" text={dates} icon={CalendarIcon} />}
      {children}
    </div>
  );
}
