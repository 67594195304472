import React, { ReactElement } from 'react';
import { ModalForm } from 'components/form';
import { ArrowRightIcon } from 'components/icons';
import { CreateAnnouncementModalContainerProps, MakeAnnouncementFormData } from 'containers/announcements/types';
import { getDefaultValues, getFormInputsHook } from './MakeAnnouncementModalContainer.forms';

export function MakeAnnouncementModalContainer({
  jobsiteOptions,
  announcement,
  updateAnnouncement,
  onCancel,
  onAction,
}: CreateAnnouncementModalContainerProps): ReactElement {
  const onSubmit = (data: MakeAnnouncementFormData): void => {
    updateAnnouncement({
      filterJobsiteIds: data?.jobsiteIds.map(({ value }) => value),
      announcementType: data?.announcementType.value,
      timeZone: data?.jobsiteIds?.[0]?.jobsite?.timeZone,
    });
    onAction();
  };

  const formInputs = getFormInputsHook({ jobsiteOptions, announcement, updateAnnouncement });
  const defaultValues = React.useMemo(
    () => getDefaultValues(jobsiteOptions, announcement),
    [jobsiteOptions, announcement],
  );

  return (
    <ModalForm
      open
      setOpen={onCancel}
      title="Make Announcement"
      actionText="Continue"
      actionButtonEnabled={!!(announcement?.filterJobsiteIds?.length && announcement?.announcementType)}
      actionIcon={ArrowRightIcon}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
    />
  );
}
