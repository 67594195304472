import React from 'react';
import cn from 'classnames';
import {
  BadgeCell,
  BadgeCellComponentProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  getFaIcon,
  NewMenuItemProps,
  getFixedWidthFaIcon,
  DropdownButtonSkeleton,
} from '@odin-labs/components';
import { CardStatus, WorkerCardType } from 'apollo/generated/client-operations';
import { ensureNonEmptyItems } from 'utils';
import { getISODateTimeToFormattedDate, getPrettyFormattedDateTime } from 'utils/dates';
import { to } from 'acl';
import { AuthUser } from 'auth/types';
import { faIdBadge, faMobile, faQrcode, faIdCard, faMinusOctagon, faRedo } from 'components/icons';
import { WorkerAccessHistory } from 'containers/worker/types';
import { MatchedWorkerCard } from 'containers/workerOnboarding/types';

const workerCardStatusConfig: Record<CardStatus, Pick<BadgeCellComponentProps, 'text' | 'color'>> = {
  Active: { text: 'Active', color: 'green' },
  Inactive: { text: 'Inactive', color: 'gray' },
  SentToWorker: { text: 'Sent to worker', color: 'yellow' },
  [CardStatus.Failed]: { text: 'Failed', color: 'red' },
};

const IdCardIcon = getFixedWidthFaIcon({ icon: faIdCard });
const MinusOctagonIcon = getFixedWidthFaIcon({ icon: faMinusOctagon });
const RedoIcon = getFixedWidthFaIcon({ icon: faRedo });

export const getWorkerCardTableColumns = ({
  openReactivateBadgeModal,
  openDeactivateBadgeModal,
  submitIssueMobileBadge,
  user,
}: {
  openReactivateBadgeModal: (workerCard: MatchedWorkerCard) => void;
  openDeactivateBadgeModal: (workerCard: MatchedWorkerCard) => void;
  submitIssueMobileBadge: (workerCard: MatchedWorkerCard, target?: 'worker' | 'apple' | 'google') => void;
  openAddBluetoothCardModal: (workerCard: MatchedWorkerCard) => void;
  user: AuthUser;
}): Column<MatchedWorkerCard>[] => {
  const isNotContractorMember = !user.isContractor;
  const canDeactivateBadges = user.isAllowed(to.deactivateBadges);
  const canGenerateWalletWorkerCard = user.isAllowed(to.generateWalletWorkerCard);

  return ensureNonEmptyItems<Column<MatchedWorkerCard>>([
    {
      Header: 'Badge Number',
      accessor: 'cardNumber',
    },
    {
      Header: 'Status',
      Cell: BadgeCell,
      componentProps: (workerCard: MatchedWorkerCard): BadgeCellComponentProps => ({
        text: workerCardStatusConfig[workerCard.cardStatus].text,
        color: workerCardStatusConfig[workerCard.cardStatus].color,
        className: cn('odin-uppercase odin-leading-relaxed odin-font-bold'),
      }),
    },
    {
      Header: 'Authorization',
      accessor: (workerCard: MatchedWorkerCard): string => {
        return workerCard.isTemporary ? 'Temporary' : 'Permanent';
      },
      cellClassName: cn('odin-text-center'),
      headerClassName: cn('odin-w-full odin-text-center'),
    },
    {
      Header: 'Badge Type',
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (workerCard: MatchedWorkerCard): ChipsCellComponentProps => {
        const { cardType } = workerCard?.workerCardFormat ?? {};
        let cardIcon = faIdBadge;
        if (cardType === WorkerCardType.MobileBadge) {
          cardIcon = faMobile;
        } else if (cardType === WorkerCardType.QrCode) {
          cardIcon = faQrcode;
        }
        return {
          chips: [
            {
              text: workerCard.workerCardFormat?.name,
              secondaryText: workerCard.workerCardFormat?.facilityCode?.toString(),
              withDot: false,
              icon: getFaIcon({ icon: cardIcon }),
            },
          ],
        };
      },
    },
    {
      Header: 'Issued On',
      accessor: (workerCard: MatchedWorkerCard): string => getPrettyFormattedDateTime(workerCard.issuedAt) ?? 'None',
    },
    {
      Header: 'Expires On',
      accessor: (workerCard: MatchedWorkerCard): string => getPrettyFormattedDateTime(workerCard.expiredAt) ?? 'None',
    },
    isNotContractorMember &&
      user.isAllowed(to.seeBadgeActions) && {
        Header: 'Actions',
        Cell: DropdownButtonCell,
        CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
        componentProps: (workerCard: MatchedWorkerCard): DropdownButtonCellComponentProps => {
          const { cardType } = workerCard.workerCardFormat ?? {};
          const adminBadgeActions = ensureNonEmptyItems<NewMenuItemProps>([
            cardType === WorkerCardType.MobileBadge &&
              canGenerateWalletWorkerCard && {
                icon: IdCardIcon,
                text: 'Generate Apple Badge',
                theme: 'secondary',
                onClick: (): void => {
                  submitIssueMobileBadge(workerCard, 'apple');
                },
              },
            cardType === WorkerCardType.MobileBadge &&
              canGenerateWalletWorkerCard && {
                icon: IdCardIcon,
                text: 'Generate Google Badge',
                theme: 'secondary',
                onClick: (): void => {
                  submitIssueMobileBadge(workerCard, 'google');
                },
              },
          ]);
          const activeBadgeActions = ensureNonEmptyItems<NewMenuItemProps>([
            canDeactivateBadges && {
              icon: MinusOctagonIcon,
              text: 'Deactivate Badge',
              theme: 'danger',
              onClick: (): void => {
                openDeactivateBadgeModal(workerCard);
              },
            },
            cardType === WorkerCardType.MobileBadge && {
              icon: RedoIcon,
              text: 'Re-issue Mobile Badge',
              theme: 'secondary',
              onClick: (): void => {
                submitIssueMobileBadge(workerCard, 'worker');
              },
            },
          ]);
          const inactiveBadgeActions: NewMenuItemProps[] = [
            {
              icon: RedoIcon,
              text: 'Reactivate Badge',
              theme: 'secondary',
              onClick: (): void => {
                openReactivateBadgeModal(workerCard);
              },
            },
          ];

          return {
            cellAlignment: 'right',
            menuItems: [
              ...(workerCard.cardStatus === CardStatus.Inactive ? inactiveBadgeActions : activeBadgeActions),
              ...adminBadgeActions,
            ],
          };
        },
      },
  ]);
};

export const getJobsiteAccessHistoryColumns = (): Column<WorkerAccessHistory>[] => {
  return [
    {
      id: 'changeDate',
      Header: 'Date of action',
      accessor: (accessHistory: WorkerAccessHistory): string => getPrettyFormattedDateTime(accessHistory?.changeDate),
    },
    {
      id: 'changeToAccess',
      Header: 'Change to access',
      accessor: (accessHistory: WorkerAccessHistory): string => {
        if (accessHistory?.changeToAccess !== 'Allowed') {
          return accessHistory?.banEndDate ? 'Suspended' : 'Banned';
        }
        return 'Allowed';
      },
    },
    {
      id: 'jobsiteName',
      Header: 'Site',
      accessor: (accessHistory: WorkerAccessHistory): string => accessHistory?.jobsiteName,
    },
    {
      id: 'changeCategory',
      Header: 'Category',
      accessor: (accessHistory: WorkerAccessHistory): string => accessHistory?.changeCategory,
    },
    {
      id: 'changeReason',
      Header: 'Reason',
      accessor: (accessHistory: WorkerAccessHistory): string => accessHistory?.changeReason,
    },
    {
      id: 'banEndDate',
      Header: 'Suspension end date',
      accessor: (accessHistory: WorkerAccessHistory): string =>
        getISODateTimeToFormattedDate(accessHistory?.banEndDate),
    },
    {
      id: 'changeApprovedBy',
      Header: 'Change approved by',
      accessor: (accessHistory: WorkerAccessHistory): string => accessHistory?.changeApprovedBy,
    },
    {
      id: 'updatedBy',
      Header: 'Change updated by',
      accessor: (accessHistory: WorkerAccessHistory): string => accessHistory?.updatedBy,
    },
  ];
};
