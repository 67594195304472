// * ************************************************************************************************
// * This file is used  by `codegen.yml` to generate the below enums in the generated types file.
// * https://the-guild.dev/graphql/codegen/plugins/typescript/typescript#import-all-enums-from-a-file
// * The content should be the same in both files:
// * - api/src/graphqlServer/types/enums.ts
// * - app/src/graphql/types/enums.ts
// *
// * ****************************** IMPORTANT *******************************************************
// * This file can be modified ONLY IF there is a message at the top of the file
// * specifying that it can be modified. Otherwise, IT SHOULD NOT BE MODIFIED MANUALLY,
// * because it will be overwritten when generating the graphql types.
// * Apply needed changes to the `enums` file from the `api` folder
// * and run `yarn generate:graphQLTypes` to copy the file in `app` folder.
// * ************************************************************************************************

export enum SelfOnboardingStepKey {
  LanguageSelection = 'language-selection',
  ContractorSelection = 'contractor-selection',
  BasicInfo = 'basic-info',
  WorkerConsentDocument = 'worker-consent-document',
  Profile = 'profile',
  EmergencyContactInfo = 'emergency-contact-info',
  GovernmentIssuedId = 'government-issued-id',
  NycSiteSafetyTrainingCard = 'nyc-site-safety-training-card',
  OshaCard = 'osha-card',
  JobsiteSafetyVideo = 'jobsite-safety-video',
  JobsiteSafetyDocument = 'jobsite-safety-document',
  LIRRBlueTraining = 'lirr-blue-training',
  ConfinedSpacesTraining = 'confined-spaces-training',
  ProfilePhoto = 'profile-photo',
  ReviewCompletedProfile = 'review-completed-profile',
  VerifyMobileDevice = 'verify-mobile-device',
  VerifyMobileDeviceToSignIn = 'verify-mobile-device-to-sign-in',
  ConfirmMobileDevice = 'confirm-mobile-device',
  ConfirmMobileDeviceToSignIn = 'confirm-mobile-device-to-sign-in',
  UnableToVerifyAccount = 'unable-to-verify-account',
  Closed = 'closed',
  AlreadyCompleted = 'already-completed',
}

export enum DocumentKey {
  Generic = 'generic',
  GovernmentIssuedId = 'government-issued-id',
  MedicalDrugTestingConsentForm = 'medical-drug-testing-consent-form',
  MedicalBreathAlcoholTest = 'medical-breath-alcohol-test',
  MedicalUrineDrugTest = 'medical-urine-drug-test',
  MedicalCovid19OrientationAndProcedures = 'medical-covid-19-orientation-and-procedures',
  MedicalCovid19Survey = 'medical-covid-19-survey',
  PostEmploymentBreathAlcoholTest = 'post-employment-breath-alcohol-test',
  PostEmploymentUrineDrugTest = 'post-employment-urine-drug-test',
  AdditionalCertifications = 'additional-certifications',
  SiteSpecificOrientation = 'site-specific-orientation',
  NycSiteSafetyTrainingCard = 'nyc-site-safety-training-card',
  Signature = 'signature',
  OshaCard = 'osha-card',
  HudsonYardsEmployeeOrientation = 'hudson-yards-employee-orientation',
  MonadnockEyeProtectionAcknowledgement = 'monadnock-eye-protection-acknowledgement',
  LIRRBlueTraining = 'lirr-blue-training',
  ConfinedSpacesTraining = 'confined-spaces-training',
  JobsiteSafetyVideo = 'jobsite-safety-video',
  JobsiteSafetyDocument = 'jobsite-safety-document',
  WorkerConsentDocument = 'worker-consent-document',
}
