import React from 'react';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import {
  GetWorkerDataDocument,
  useChangeJobsiteWorkersJobsiteAssignmentMutation,
  useRemoveWorkerFromJobsiteMutation,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { BriefcaseIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import {
  CorrectJobsiteAssignmentFormData,
  CorrectJobsiteAssignmentModalProps,
  JobsiteAssignmentCorrectionType,
} from './types';
import { getDefaultValues, getFormInputsHook, useContractorsOptions } from './CorrectJobsiteAssignmentModal.forms';

export function CorrectJobsiteAssignmentModal(props: CorrectJobsiteAssignmentModalProps): React.ReactElement {
  const { isOpen, closeModal, onClosed, jobsiteWorker, jobsiteWorkers } = props;
  const { jobsiteWorkerId, jobsiteContractor } = jobsiteWorker ?? {};
  const { jobsite: jobsiteToEdit } = jobsiteContractor ?? {};

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [changeJobsiteAssignment] = useChangeJobsiteWorkersJobsiteAssignmentMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetWorkerDataDocument, 'GetJobsiteWorkers'],
  });

  const [removeJobsiteAssignment] = useRemoveWorkerFromJobsiteMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetWorkerDataDocument, 'GetJobsiteWorkers'],
  });

  const { currentUser: user } = React.useContext(AuthContext);
  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const onSubmit: FormOnSubmit<CorrectJobsiteAssignmentFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { correctionType, newJobsiteId, newContractorId } = data;

    try {
      switch (correctionType.value) {
        case JobsiteAssignmentCorrectionType.Change:
          await changeJobsiteAssignment({
            variables: {
              input: {
                jobsiteId: newJobsiteId.value,
                jobsiteWorkerIds: [jobsiteWorkerId],
                contractorId: newContractorId.value,
              },
            },
          });
          AlertInstance.alert('tc', 'success', 'Success', 'Successfully updated jobsite assignment');
          break;

        case JobsiteAssignmentCorrectionType.Deletion:
          await removeJobsiteAssignment({
            variables: {
              input: { jobsiteWorkerId },
            },
          });
          AlertInstance.alert('tc', 'success', 'Success', 'Successfully removed jobsite assignment');
          break;

        default:
          break;
      }

      closeModal();
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const editJobsiteOptions = React.useMemo(() => getJobsitesOptions([jobsiteToEdit].filter(Boolean)), [jobsiteToEdit]);
  const newJobsiteOptions = React.useMemo(() => {
    return getJobsitesOptions(jobsites.filter((j) => j.jobsiteId !== jobsiteToEdit?.jobsiteId));
  }, [jobsites, jobsiteToEdit]);
  const defaultNewJobsiteId = newJobsiteOptions?.length === 1 ? newJobsiteOptions[0].value : null;
  const newContractorOptions = useContractorsOptions(defaultNewJobsiteId, jobsiteWorkers);

  const formInputs = getFormInputsHook({ user, editJobsiteOptions, newJobsiteOptions, jobsiteWorkers });

  const defaultValues = React.useMemo(
    () =>
      getDefaultValues({
        editJobsiteOptions,
        newJobsiteOptions,
        newContractorOptions,
      }),
    [editJobsiteOptions, newJobsiteOptions, newContractorOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      title="Correct Jobsite Assignment"
      setOpen={closeModal}
      afterLeave={onClosed}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Correct Assignment"
      actionIcon={BriefcaseIcon}
      actionButtonWithSpinner={userJobsitesLoading || isFetching}
      error={userJobsitesError}
    />
  );
}
