import { Moment } from 'moment';
import { Column } from '@odin-labs/components';
import { GetJobsiteFormSubmissionsQuery } from 'apollo/generated/client-operations';
import { CustomForm, FormsTabApi } from 'containers/forms/types';
import { PageSearchParams } from 'utils';
import { ReplaceSelectOptionElement } from 'types';

type JobsiteFormSubmissions = GetJobsiteFormSubmissionsQuery['getCurrentSession']['user']['jobsiteFormSubmissions'];
export type FormSubmission = JobsiteFormSubmissions['edges'][number]['node'];
export type FormSubmissionFiltersOptions = JobsiteFormSubmissions['filtersOptions'];

export type TypedFormSubmission<TExtraData extends Record<string, unknown>> = Omit<FormSubmission, 'extraData'> & {
  extraData?: ReplaceSelectOptionElement<TExtraData>;
};

export type FormSubmissionFilters = Pick<
  PageSearchParams,
  'developerIds' | 'jobsiteIds' | 'contractorIds' | 'createdByIds' | 'formIds' | 'hasAttachments'
> & {
  dateRange: {
    startDate: Moment;
    endDate: Moment;
  };
};

export type DefaultExtraData = Record<string, unknown>;

export type FormSubmissionColumn<TExtraData extends DefaultExtraData = DefaultExtraData> = Column<
  TypedFormSubmission<TExtraData>
>;

export type FormSubmissionsWidgetByForm = { form: CustomForm };
export type FormSubmissionsWidgetByWorker = {
  workerId: string;
  extraColumns?: FormSubmissionColumn[];
  filters?: Array<keyof FormSubmissionFilters>;
  navigateInNewTab?: boolean;
};
export type FormSubmissionsWidgetCommonProps = {
  onTabApiChange: (api: FormsTabApi) => void;
};
export type FormSubmissionsWidgetProps = FormSubmissionsWidgetCommonProps &
  (FormSubmissionsWidgetByForm | FormSubmissionsWidgetByWorker);

export enum FormColumnName {
  JobsiteContractor = 'jobsiteContractor',
  Form = 'form',
  Manpower = 'manpower',
  Date = 'date',
  StartTime = 'startTime',
  EndTime = 'endTime',
  CreatedBy = 'createdBy',
  Actions = 'actions',
}
