import React from 'react';
import { Form, FormOnSubmit } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import { useGetIsSelfOnboardingClosed } from 'containers/selfOnboarding/helpers/useGetIsSelfOnboardingClosed';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import {
  SelfOnboardingContractorSelectionFormData,
  SelfOnboardingStepKey,
  SelfOnboardingStepProps,
} from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { StepActions, StepInfo, StepInfoTitle, StepJobsiteInfo } from 'containers/selfOnboarding/steps/components';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { getFormInputsHook } from './ContractorSelectionStep.forms';

export function ContractorSelectionStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, navigation, stepConfig, localize, language, jobsiteInvitation } = props;
  const { contractorOptions, contractorId, worker } = state;
  const { workerId } = worker ?? {};
  const { goToStep, goToNextStep, updateQueryParams, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;

  const [isSaving, setIsSaving] = React.useState(false);
  const { isSelfOnboardingClosed } = useGetIsSelfOnboardingClosed();

  const [isContinueActionEnabled, setIsContinueActionEnabled] = React.useState(!!contractorId);

  const onSubmit: FormOnSubmit<SelfOnboardingContractorSelectionFormData> = async (data, event): Promise<void> => {
    try {
      if (isSaving) return;
      setIsSaving(true);

      if (workerId && (await isSelfOnboardingClosed())) {
        goToStep(SelfOnboardingStepKey.Closed);
        return;
      }

      updateState({ ...data });
      updateQueryParams({ contractorId: data.contractorId.value });

      setIsSaving(false);

      goToNextStep();
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const inputs = getFormInputsHook({ localize, contractorOptions, setIsContinueActionEnabled });
  const defaultValues = React.useMemo(() => ({ contractorId }), [contractorId]);

  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={`${localize(Copy.self_onboarding_worker_match_header)} 👋`}
          subtitle={localize(Copy.self_onboarding_worker_match_instructions)}
          withBottomBorder
          hideOnMobile
        />
        <StepJobsiteInfo jobsiteInvitation={jobsiteInvitation} />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingContractorSelectionFormData) ?? true}
        renderBelow={
          <StepActions
            jobsiteInvitation={jobsiteInvitation}
            localize={localize}
            isReview={isReview}
            actions={isReview ? 'all' : 'continue'}
            onSkip={goToNextStep}
            continueActionEnabled={isContinueActionEnabled}
            continueActionWithSpinner={isSaving}
          />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
