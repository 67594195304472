import React from 'react';
import { InboxIcon } from 'components/icons';

export function SearchBoxNoMatch(): React.ReactElement {
  return (
    <div className="odin-p-6">
      <div className="odin-p-6 odin-bg-gray-50">
        <div className="odin-flex odin-justify-center">
          <InboxIcon className="odin-text-3xl odin-transform odin-scale-x-125" aria-hidden="true" />
        </div>
        <div className="odin-text-base odin-mt-3 odin-text-gray-900 odin-text-center">
          Sorry, we couldn’t find any matching workers. Please update your search and try again.
        </div>
      </div>
    </div>
  );
}
