import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import { GetWorkerDataDocument, useUpdateWorkerMutation } from 'apollo/generated/client-operations';
import { TrashIcon } from 'components/icons';
import { RemoveWorkerProfilePhotoModalProps } from './types';

export function RemoveWorkerProfilePhotoModal(props: RemoveWorkerProfilePhotoModalProps): ReactElement {
  const { isOpen, workerId, closeModal: onCancel, onConfirm } = props;

  const [updateWorker, { loading }] = useUpdateWorkerMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      onConfirm();
      AlertInstance.alert('tc', 'success', 'Success', 'Profile photo successfully removed.');
    },
    onError: (responseError: ApolloError) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    variables: {
      workerId,
      workerInput: {
        profilePictureFileId: null,
      },
    },
    refetchQueries: [GetWorkerDataDocument],
  });

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title="Remove profile photo?"
      titleAlignment="center"
      text="Are you sure you want to remove this worker's profile photo?"
      textAlignment="center"
      onAction={updateWorker}
      actionText="Remove"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={loading}
    />
  );
}
