import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { TrashIcon } from 'components/icons';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import { useRemoveStripePaymentMethodMutation } from 'apollo/generated/client-operations';
import { RemovePaymentMethodModalProps } from './types';

export function RemovePaymentMethodModal(props: RemovePaymentMethodModalProps): ReactElement {
  const { contractorId, isOpen, paymentMethodId, onCancel, onConfirm, refetchQueries } = props;

  const [removePaymentMethod, { loading }] = useRemoveStripePaymentMethodMutation({
    onCompleted: (): void => {
      AlertInstance.alert('tc', 'success', 'Success', 'Worker onboarding link successfully removed');
      onConfirm();
    },
    onError: (err): void => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(err));
    },
    variables: { input: { contractorId, paymentMethodId } },
    refetchQueries,
  });

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="sm"
      title="Remove this payment method?"
      titleAlignment="center"
      text="This will remove the payment method, and invalidate any of the places it is set as default."
      textAlignment="center"
      onAction={removePaymentMethod}
      actionText="Remove payment method"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={loading}
    />
  );
}
