import React from 'react';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { getWorkerFullName } from 'utils';
import { InfoRow } from 'components/infoRow';
import { InfoList } from 'components/infoList';
import workerImage from 'images/worker.png';
import { Worker } from 'containers/worker/types';

export function WorkerOverviewBasic({ worker }: { worker: Worker }): React.ReactElement {
  const { trade, jobTitle, unionAffiliation } = worker ?? {};
  return (
    <div className="odin-space-y-9">
      <LockedWorkerAlert worker={worker} />
      <div className="odin-flex odin-gap-x-10">
        <img
          className="odin-w-72 odin-h-72 odin-object-cover"
          src={worker?.profilePictureCropped?.downloadUrl || workerImage}
          alt="Worker"
        />
        <InfoList className="odin-w-full odin-px-9" noEdit>
          <InfoRow label="Name" value={getWorkerFullName(worker)} />
          <InfoRow label="Trade" value={trade} />
          <InfoRow label="Title" value={jobTitle} />
          <InfoRow label="Union Affiliation" value={unionAffiliation} withBottomBorder />
        </InfoList>
      </div>
    </div>
  );
}
