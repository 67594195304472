import { SelectOptionElement } from '@odin-labs/components';
import { BriefcaseIcon, IdCardIcon } from 'components/icons';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { MatchedWorkerCard } from 'containers/workerOnboarding/types';
import { AssignBluetoothCardData } from './types';

export const getFormInputs = ({
  jobsitesOptions,
}: {
  jobsitesOptions: SelectOptionElement[];
}): TypedFormInputs<AssignBluetoothCardData> => {
  return {
    jobsiteId: {
      element: FormInputTypes.OdinSelect,
      label: 'Jobsite',
      elementProps: {
        placeholder: 'Select one',
        icon: BriefcaseIcon,
        disabled: (jobsitesOptions?.length ?? 0) < 2,
        options: jobsitesOptions ?? [],
      },
      validation: {
        required: true,
      },
      layout: GridColSpan.SpanFull,
    },
    cardNumber: {
      element: FormInputTypes.OdinField,
      label: 'Bluetooth card number',
      elementProps: {
        icon: IdCardIcon,
        fieldType: 'uppercase',
      },
      validation: {
        required: true,
      },
      layout: GridColSpan.SpanFull,
    },
  };
};

export const getFormDefaultValues = ({
  jobsitesOptions,
  workerCard,
}: {
  jobsitesOptions: SelectOptionElement[];
  workerCard: MatchedWorkerCard;
}): AssignBluetoothCardData => {
  return {
    jobsiteId: jobsitesOptions?.length === 1 ? jobsitesOptions[0] : null,
    cardNumber: workerCard?.matchingWorkerCard?.cardNumber,
  };
};
