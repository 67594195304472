import React from 'react';
import { getFaIcon } from '@odin-labs/components';
import { EnvelopeIcon, HelmetSafetyIcon, IdBadgeIcon, PhoneIcon, ShieldCheckIcon, ToolsIcon } from 'components/icons';
import { classes } from './SearchWorkerInfo.style';
import { SearchWorkerInfoItemProps, SearchWorkerInfoItemType } from './types';

const icons: { [key in SearchWorkerInfoItemType]: ReturnType<typeof getFaIcon> } = {
  quickCode: ShieldCheckIcon,
  badgeId: IdBadgeIcon,
  phone: PhoneIcon,
  email: EnvelopeIcon,
  trade: HelmetSafetyIcon,
  unionAffiliation: ToolsIcon,
};

export function SearchWorkerInfoItem(props: SearchWorkerInfoItemProps): React.ReactElement {
  const { type, text = null } = props;
  const Icon = icons[type];
  return (
    text && (
      <div className={classes.workerInfoItem}>
        <Icon className={classes.workerInfoItemIcon} />
        {text}
      </div>
    )
  );
}
