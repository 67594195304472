import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { getFaIcon, Icon, Label } from '@odin-labs/components';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { useWorkforceStatsData } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/helpers/useWorkforceStatsData';
import { momentFormatter } from 'utils/dates';
import { BusinessTimeIcon, ConstructionIcon, faCalendar, UserClockIcon, UserHardHatIcon } from 'components/icons';

export const classes = {
  title: cn('odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium odin-mr-9'),
  container: cn('sm:odin-grid sm:odin-grid-cols-4 odin-gap-x-6'),
  detailLabel: cn('odin-text-sm odin-leading-5 odin-font-normal odin-text-gray-900'),
  detailValue: cn('odin-text-3xl odin-leading-10 odin-font-bold odin-text-odin-black odin-mt-3'),
  detailValueSkeleton: cn('odin-animate-pulse odin-w-4/6 odin-bg-gray-300 odin-h-8 odin-rounded-md -odin-ml-1'),
  icon: 'odin-text-odin-primary odin-w-6 odin-text-2xl',
};

const CalendarIcon = getFaIcon({ icon: faCalendar, className: 'odin-text-odin-primary odin-mr-1.5' });

export type JobsiteReportWorkforceStatsItemProps = {
  label: string;
  value?: number;
  icon: Icon;
  loading: boolean;
};

function JobsiteReportWorkforceStatsItem(props: JobsiteReportWorkforceStatsItemProps): React.ReactElement {
  const { label, value, icon: IconComponent, loading } = props;
  const valueContent = loading ? <div className={classes.detailValueSkeleton} /> : value?.toLocaleString('en-US');
  return (
    <div>
      <IconComponent className={classes.icon} />
      <div className={classes.detailValue}>{valueContent}</div>
      <div className={classes.detailLabel}>{label}</div>
    </div>
  );
}

export type JobsiteReportWorkforceStatsProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
};

export function JobsiteReportWorkforceStats(props: JobsiteReportWorkforceStatsProps): React.ReactElement {
  const { jobsiteFormSubmission } = props;
  const {
    loading,
    workforceStats: { uniqueWorkers, totalManPower, totalHours, averageHours },
  } = useWorkforceStatsData(jobsiteFormSubmission);

  return (
    <div className="odin-space-y-9">
      <div>
        <span className={classes.title}>Workforce Stats</span>
        <Label size="lg" text={moment(jobsiteFormSubmission.startAt).format(momentFormatter)} icon={CalendarIcon} />
      </div>
      <div className={classes.container}>
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Workers on site at time of incident"
          value={uniqueWorkers}
          icon={UserHardHatIcon}
        />
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Total Manpower"
          value={totalManPower}
          icon={ConstructionIcon}
        />
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Total Hours"
          value={totalHours}
          icon={BusinessTimeIcon}
        />
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Average Time Spent Onsite"
          value={averageHours}
          icon={UserClockIcon}
        />
      </div>
    </div>
  );
}
