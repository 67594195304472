import React, { ReactElement } from 'react';
import { FormDialog } from 'dialog';
import { CheckIcon } from 'components/icons';
import { EditDocumentFileFormData } from 'containers/jobsiteConfiguration/types';
import { EditDocumentFileModalProps } from './types';
import { getDefaultValues, getFormInputs } from './EditDocumentFileModal.forms';

export function EditDocumentFileModal(props: EditDocumentFileModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, documentFile, languageOptions, accept } = props;
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const onSubmit = React.useCallback(
    (data: EditDocumentFileFormData): void => {
      const { file, title } = data;
      onConfirm?.({
        ...documentFile,
        title: title?.trim() || null,
        file,
      });
    },
    [onConfirm, documentFile],
  );

  const formInputs = React.useMemo(
    () => getFormInputs({ accept, languageOptions, editType: 'update' }),
    [accept, languageOptions],
  );

  const defaultValues = React.useMemo(
    () => getDefaultValues({ documentFile, languageOptions, editType: 'update' }),
    [documentFile, languageOptions],
  );

  return (
    <FormDialog
      id="editDocumentFile"
      open={isOpen}
      setOpen={onCancel}
      title="Edit File"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionIcon={CheckIcon}
      onIsDirtyChange={setIsFormDirty}
      actionButtonEnabled={isFormDirty}
    />
  );
}
