import React from 'react';
import { WorkerSearchResult } from 'containers/searchWorkers/types';
import { getInitialsForUser, getUserFullName } from 'utils';
import { AvatarCell, AvatarCellComponentProps, AvatarSkeleton, Column } from '@odin-labs/components';

export type WorkerSearchResultColumn = Column<WorkerSearchResult>;

export const getColumns = (): WorkerSearchResultColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: ({ worker }: WorkerSearchResult): string => getUserFullName(worker.user),
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size="xs" hideDetails />,
    componentProps: ({ worker }: WorkerSearchResult): AvatarCellComponentProps => ({
      src: worker.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(worker.user),
      objectFit: 'cover',
      size: 'xs',
    }),
  },
  {
    id: 'quickCode',
    Header: 'Quick Code',
    accessor: ({ worker }: WorkerSearchResult): string => worker.quickCode,
  },
  {
    id: 'trade',
    Header: 'Trade',
    accessor: ({ worker }: WorkerSearchResult): string => worker.trade,
  },
  {
    id: 'jobTitle',
    Header: 'Title',
    accessor: ({ worker }: WorkerSearchResult): string => worker.jobTitle,
  },
  {
    id: 'unionAffiliation',
    Header: 'Union affiliation',
    accessor: ({ worker }: WorkerSearchResult): string => worker.unionAffiliation,
  },
];
