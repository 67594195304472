import React from 'react';
import { Field } from '@odin-labs/components';
import { useDebouncedValue, useUpdatableState } from 'utils';

export type FormSubmissionWorkerEditSearchBoxProps = {
  onSearchTermChange: (searchTerm: string) => void;
};

export function FormSubmissionWorkerEditSearchBox({
  onSearchTermChange,
}: FormSubmissionWorkerEditSearchBoxProps): React.ReactElement {
  // useUpdatableState hook is used here for updating the state based on props
  const [searchValue, setSearchValue] = useUpdatableState<string>('');
  const isFirstTimeRef = React.useRef(true);

  const inputRef = React.useRef<HTMLInputElement>();
  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const debouncedSearchValue = useDebouncedValue(searchValue, 300);
  React.useEffect(() => {
    if (!isFirstTimeRef.current) {
      onSearchTermChange(debouncedSearchValue);
    }
    isFirstTimeRef.current = false;
  }, [debouncedSearchValue]);

  return (
    <Field
      ref={inputRef}
      name="workerSearchBox"
      value={searchValue}
      onChange={(value: string): void => setSearchValue(value)}
      placeholder="Search workers"
    />
  );
}
