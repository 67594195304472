import React from 'react';
import { useChangeContractorForJobsiteWorkersMutation } from 'apollo/generated/client-operations';
import { getCurrentISOFormattedDate, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { WrenchIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { ChangeContractorFormData, ChangeContractorModalProps, ChangeType } from './types';
import { getDefaultValues, getFormInputsHook, useContractorsOptions } from './ChangeContractorModal.forms';

export function ChangeContractorModal(props: ChangeContractorModalProps): React.ReactElement {
  const { isOpen, closeModal, onClosed, jobsiteId, contractorId, jobsiteWorkers, refetchQueries } = props;
  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [changeContractorForJobsiteWorkers] = useChangeContractorForJobsiteWorkersMutation({
    fetchPolicy: 'no-cache',
    refetchQueries,
  });

  const onSubmit: FormOnSubmit<ChangeContractorFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { changeType, newContractorId } = data;

    try {
      await changeContractorForJobsiteWorkers({
        variables: {
          input: {
            jobsiteWorkerIds: jobsiteWorkers?.map((jobsiteWorker) => jobsiteWorker.jobsiteWorkerId),
            newContractorId: newContractorId.value,
            startDate: changeType.value === ChangeType.Today ? new Date(getCurrentISOFormattedDate()) : null,
          },
        },
      });

      closeModal();
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const contractorOptions = useContractorsOptions({ jobsiteId, contractorId, skip: !isOpen });
  const formInputs = getFormInputsHook({ contractorOptions });
  const defaultValues = React.useMemo(() => getDefaultValues({ contractorOptions }), [contractorOptions]);

  return (
    <ModalForm
      open={isOpen}
      title="Change Contractor"
      setOpen={closeModal}
      afterLeave={onClosed}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Change Contractor"
      actionIcon={WrenchIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
