import React, { ReactElement } from 'react';
import moment from 'moment';
import { ModalActionsPanelProps } from '@odin-labs/components';
import { GetWorkerDataDocument, useUpdateJobsiteWorkerMutation } from 'apollo/generated/client-operations';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getCurrentISOFormattedDate } from 'utils/dates';
import { UserClockIcon, UserMinusIcon, UserTimesIcon } from 'components/icons';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { getDefaultValues, getFormInputs } from './RevokeWorkerAccessModalContent.forms';
import { AccessRevokingType, RevokeWorkerAccessFormData, RevokeWorkerAccessModalContentProps } from './types';

const revokeWorkerAccessConfig: Record<
  AccessRevokingType,
  Pick<ModalActionsPanelProps, 'actionText' | 'actionIcon'>
> = {
  suspend: { actionText: 'Suspend Worker', actionIcon: UserClockIcon },
  ban: { actionText: 'Ban Worker', actionIcon: UserTimesIcon },
  offboard: { actionText: 'Offboard Worker', actionIcon: UserMinusIcon },
};

export function RevokeWorkerAccessModalContent(props: RevokeWorkerAccessModalContentProps): ReactElement {
  const { closeModal, onConfirm, jobsiteWorker, accessRevokingType } = props;
  const { jobsiteWorkerId } = jobsiteWorker ?? {};
  const isSuspension = accessRevokingType === AccessRevokingType.Suspend;
  const isOffboarding = accessRevokingType === AccessRevokingType.Offboard;
  const isNotOffboarding = !isOffboarding;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    refetchQueries: [GetWorkerDataDocument],
  });

  const onSubmit: FormOnSubmit<RevokeWorkerAccessFormData> = async (data, event): Promise<void> => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const { category, reason, banExpiresAt, endDate, approvedBy } = data;

    try {
      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            siteAccessChangeApprovedBy: isNotOffboarding ? approvedBy : null,
            isBanned: isNotOffboarding,
            bannedAt: isNotOffboarding ? moment(getCurrentISOFormattedDate()).toDate() : null,
            banCategory: isNotOffboarding ? category.value : null,
            bannedReason: isNotOffboarding ? reason : null,
            banExpiresAt: isSuspension ? banExpiresAt.toDate() : null,
            endDate: isOffboarding ? endDate.toDate() : null,
          },
        },
      });
      onConfirm();
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  const formInputs = React.useMemo(() => getFormInputs({ accessRevokingType }), [accessRevokingType]);
  const defaultValues = React.useMemo(() => getDefaultValues({ accessRevokingType }), [accessRevokingType]);

  const { actionText, actionIcon } = revokeWorkerAccessConfig[accessRevokingType] ?? {};

  return (
    <ModalFormContent
      setOpen={closeModal}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText={actionText}
      actionIcon={actionIcon}
      actionButtonWithSpinner={isSaving}
    />
  );
}
