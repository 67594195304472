import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import { Alert, NewButton } from '@odin-labs/components';
import { PreviewIcon } from 'components/icons';
import { JobsiteWorkerDocumentUploadProps } from './types';

export function JobsiteWorkerDocumentUpload(props: JobsiteWorkerDocumentUploadProps): ReactElement {
  const { children, files, label, hideLabel, previewUrl, showExistingDocumentAlert } = props;
  const rightContainerWidth = hideLabel ? '12' : '9';

  return (
    <div className="jobsite-worker-document-upload">
      <Row className="mt-4">
        {!hideLabel && (
          <Col sm="3" className="label">
            <h3>{label}</h3>
            {previewUrl && (
              <NewButton
                text="Preview Document"
                theme="secondary"
                size="sm"
                icon={PreviewIcon}
                as="a"
                href={previewUrl}
                target="_blank"
              />
            )}
          </Col>
        )}
        <Col sm={rightContainerWidth}>
          <Row>{children}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        {!hideLabel && <Col sm="3" className="label" />}
        <Col sm={rightContainerWidth}>
          <Row>{files}</Row>
          {showExistingDocumentAlert && (
            <Row className="odin-mt-3 odin-px-3">
              <Alert
                text={
                  <span>
                    This worker already has a <b>{label}</b> on file: please review and update it as necessary.
                  </span>
                }
              />
            </Row>
          )}
        </Col>
      </Row>
      <hr />
    </div>
  );
}
