import React, { ReactElement } from 'react';
import { FormDialog } from 'dialog';
import { CheckIcon } from 'components/icons';
import { EditSiteAreaFormData } from 'containers/jobsiteConfiguration/types';
import { EditSiteAreaModalProps } from './types';
import { getDefaultValues, getFormInputs, getAsNumber } from './EditSiteAreaModal.forms';

export function EditSiteAreaModal(props: EditSiteAreaModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, siteArea } = props;

  const onSubmit = React.useCallback(
    (data: EditSiteAreaFormData): void => {
      const { radius, latitude, longitude, ...restData } = data;
      onConfirm?.({
        id: siteArea.id,
        radius: getAsNumber(radius),
        latitude: getAsNumber(latitude),
        longitude: getAsNumber(longitude),
        ...restData,
      });
    },
    [onConfirm, siteArea],
  );

  const formInputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues(siteArea), [siteArea]);

  return (
    <FormDialog
      id="editSiteArea"
      open={isOpen}
      setOpen={onCancel}
      title="Edit Area"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionIcon={CheckIcon}
    />
  );
}
