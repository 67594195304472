import React from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError, hasGraphQLErrorName } from 'utils/error';
import {
  MutationRemoveCardFromWorkerArgs,
  Success,
  useAddCardToWorkerMutation,
  WorkerCard,
  WorkerCardType,
} from 'apollo/generated/client-operations';
import { CheckIcon } from 'components/icons';
import { REMOVE_CARD_FROM_WORKER } from 'containers/worker/helpers/queries';
import { getFormDefaultValues, getFormInputs } from './AssignQRModalContent.forms';
import { AssignQRFormData } from './types';

export type AssignQRModalContentProps = {
  setOpen: () => void;
  onAction: (reassignQRData?: AssignQRFormData) => void;
  jobsiteId: string;
  workerId: string;
  workerCardFormatId: string;
  workerCards: WorkerCard[];
};

export function AssignQRModalContent(props: AssignQRModalContentProps): React.ReactElement {
  const { setOpen, onAction, workerId, jobsiteId, workerCardFormatId, workerCards } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [addCardToWorker] = useAddCardToWorkerMutation();

  const [removeCardFromWorker] = useMutation<Success, MutationRemoveCardFromWorkerArgs>(REMOVE_CARD_FROM_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      setIsFetching(false);
    },
  });

  const onSubmit: FormOnSubmit<AssignQRFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    try {
      const { qrCodeNumber, badges } = nullifyEmptyFields(data);

      await addCardToWorker({
        variables: {
          jobsiteId,
          workerId,
          workerCardInput: {
            isTemporary: false,
            cardIssueReason: null,
            cardNumber: qrCodeNumber,
            startDate: moment().toDate(),
            endDate: null,
            type: WorkerCardType.QrCode,
            workerCardFormatId,
          },
        },
      });

      const workerCardIds = badges.filter(({ selected }) => selected).map(({ workerCard }) => workerCard.workerCardId);
      if (workerCardIds.length) {
        await removeCardFromWorker({
          variables: {
            workerCardIds,
          },
        });
      }

      onAction();
      AlertInstance.alert('tc', 'success', 'Success', 'QR Code successfully assigned');
    } catch (error) {
      event.preventDefault();
      if (hasGraphQLErrorName(error, 'ResourceAlreadyExistsError')) {
        onAction(data);
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const formInputs = getFormInputs();
  const defaultValues = React.useMemo(() => getFormDefaultValues(workerCards), [workerCards]);

  return (
    <ModalFormContent
      setOpen={setOpen}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Assign QR Code"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
