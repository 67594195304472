import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDidUpdateEffect } from '@odin-labs/components';
import {
  useGetJobsiteInvitationQuery,
  useGetSelfOnboardingContainerSessionQuery,
} from 'apollo/generated/client-operations';
import { localizeFactory } from 'utils/localization';
import { useLegacyState } from 'utils/useLegacyState';
import { useQueryParams } from 'utils/useQueryParams';
import { LoadingError } from 'components/loadingError';
import { defaultLanguage } from './utils';
import { SelfOnboardingState, SelfOnboardingStepComponent, SelfOnboardingStepKey } from './types';
import {
  LanguageSelectionStep,
  ContractorSelectionStep,
  BasicInfoStep,
  ProfileStep,
  EmergencyContactStep,
  DocumentStep,
  MatchedWorkerStep,
  ErrorStep,
  ProfilePhotoStep,
  ReviewCompletedProfileStep,
  VerifyMobileDeviceStartStep,
  VerifyMobileDeviceCompleteStep,
  VerifyMobileDeviceToSignInStartStep,
  VerifyMobileDeviceToSignInCompleteStep,
  UnableToVerifyAccountStep,
} from './steps';
import { getStepConfig } from './helpers/utils';
import { classes } from './SelfOnboardingContainer.style';
import { SelfOnboardingHeader } from './SelfOnboardingHeader';
import { getSelfOnboardingLanguageDependentState, getSelfOnboardingState } from './SelfOnboardingContainer.forms';
import { useSelfOnboardingNavigation } from './helpers/useSelfOnboardingNavigation';

const componentsByStep: Record<SelfOnboardingStepKey, SelfOnboardingStepComponent> = {
  [SelfOnboardingStepKey.LanguageSelection]: LanguageSelectionStep,
  [SelfOnboardingStepKey.ContractorSelection]: ContractorSelectionStep,
  [SelfOnboardingStepKey.BasicInfo]: BasicInfoStep,
  [SelfOnboardingStepKey.WorkerConsentDocument]: DocumentStep,
  [SelfOnboardingStepKey.Profile]: ProfileStep,
  [SelfOnboardingStepKey.EmergencyContactInfo]: EmergencyContactStep,
  [SelfOnboardingStepKey.GovernmentIssuedId]: DocumentStep,
  [SelfOnboardingStepKey.NycSiteSafetyTrainingCard]: DocumentStep,
  [SelfOnboardingStepKey.OshaCard]: DocumentStep,
  [SelfOnboardingStepKey.JobsiteSafetyVideo]: DocumentStep,
  [SelfOnboardingStepKey.JobsiteSafetyDocument]: DocumentStep,
  [SelfOnboardingStepKey.LIRRBlueTraining]: DocumentStep,
  [SelfOnboardingStepKey.ConfinedSpacesTraining]: DocumentStep,
  [SelfOnboardingStepKey.ProfilePhoto]: ProfilePhotoStep,
  [SelfOnboardingStepKey.VerifyMobileDevice]: VerifyMobileDeviceStartStep,
  [SelfOnboardingStepKey.ConfirmMobileDevice]: VerifyMobileDeviceCompleteStep,
  [SelfOnboardingStepKey.VerifyMobileDeviceToSignIn]: VerifyMobileDeviceToSignInStartStep,
  [SelfOnboardingStepKey.ConfirmMobileDeviceToSignIn]: VerifyMobileDeviceToSignInCompleteStep,
  [SelfOnboardingStepKey.ReviewCompletedProfile]: ReviewCompletedProfileStep,
  [SelfOnboardingStepKey.AlreadyCompleted]: MatchedWorkerStep,
  [SelfOnboardingStepKey.Closed]: MatchedWorkerStep,
  [SelfOnboardingStepKey.UnableToVerifyAccount]: UnableToVerifyAccountStep,
};

const noHeaderSteps: SelfOnboardingStepKey[] = [
  SelfOnboardingStepKey.LanguageSelection,
  SelfOnboardingStepKey.ReviewCompletedProfile,
];

const initialState: SelfOnboardingState = {} as SelfOnboardingState;

export function SelfOnboardingContainer(): ReactElement {
  const { jobsiteInvitationId } = useParams<{ jobsiteInvitationId: string; step: string }>();
  const urlSearchParams = useQueryParams();
  const contractorId = urlSearchParams.get('contractorId');

  const [state, updateState] = useLegacyState<SelfOnboardingState>(initialState);
  const [language, setLanguage] = useState(defaultLanguage);

  const {
    data: jobsiteInvitationData,
    loading: jobsiteInvitationLoading,
    error: jobsiteInvitationError,
  } = useGetJobsiteInvitationQuery({
    fetchPolicy: 'no-cache',
    variables: { jobsiteInvitationId },
  });
  const jobsiteInvitation = jobsiteInvitationData?.getJobsiteInvitation;
  const { jobsite, contractor } = jobsiteInvitation?.jobsiteContractor ?? {};

  const {
    data: selfOnboardingData,
    loading: selfOnboardingLoading,
    error: selfOnboardingError,
    refetch,
  } = useGetSelfOnboardingContainerSessionQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsite?.jobsiteId,
    variables: {
      jobsiteWorkersInput: {
        jobsiteIds: [jobsite?.jobsiteId],
        contractorIds: [contractorId ?? contractor?.contractorId],
      },
      workerDocumentsInput: { jobsiteIds: [jobsite?.jobsiteId] },
    },
  });
  const selfOnboardingSession = selfOnboardingData?.getCurrentSession;
  const { user: selfOnboardingUser } = selfOnboardingSession ?? {};

  const navigation = useSelfOnboardingNavigation({
    jobsiteModules: jobsite?.modules,
    selfOnboardingState: state,
  });

  const { currentStep, state: navigationState } = navigation;
  const { isReview } = navigationState;

  React.useEffect(() => {
    if (selfOnboardingData && jobsiteInvitation) {
      const selfOnboardingState = getSelfOnboardingState({
        user: selfOnboardingUser,
        jobsiteInvitation,
        contractorId,
        language,
      });
      updateState(selfOnboardingState);
    }
  }, [
    selfOnboardingData,
    jobsiteInvitation,
    contractorId,
    // language // it will be handled within a separate useEffect to update language dependent state only
  ]);

  // update language dependent state
  useDidUpdateEffect(() => {
    if (selfOnboardingData && jobsiteInvitation) {
      const languageDependentState = getSelfOnboardingLanguageDependentState({
        user: selfOnboardingUser,
        jobsiteInvitation,
        language,
      });
      updateState(languageDependentState);
    }
  }, [selfOnboardingData, jobsiteInvitation, language]);

  const stepConfig = React.useMemo(() => getStepConfig(jobsite?.modules, currentStep), [jobsite?.modules, currentStep]);
  const localize = localizeFactory(language.value);

  const loading = jobsiteInvitationLoading || selfOnboardingLoading;
  const error = jobsiteInvitationError || selfOnboardingError;

  if (!error && (loading || navigation.loading)) {
    return <LoadingError loading={loading} />;
  }

  const getCurrentStepContent = (): ReactElement => {
    const StepComponent: SelfOnboardingStepComponent = error ? ErrorStep : componentsByStep[currentStep];
    return (
      <StepComponent
        loading={loading}
        refetchData={refetch}
        user={selfOnboardingUser}
        state={state}
        updateState={updateState}
        navigation={navigation}
        jobsiteInvitation={jobsiteInvitation}
        stepConfig={stepConfig}
        language={language.value}
        onLanguageChange={setLanguage}
        localize={localize}
      />
    );
  };

  const withBackground = !error && currentStep === SelfOnboardingStepKey.LanguageSelection;
  const isHeaderVisible = !!error || !noHeaderSteps.includes(currentStep);
  const canGoToPreviousStep = !error && !isReview && navigation.canGoToPreviousStep(currentStep);

  return (
    <div className={classes.fullContainer(withBackground)}>
      {isHeaderVisible && (
        <SelfOnboardingHeader
          language={language.value}
          onLanguageChange={setLanguage}
          localize={localize}
          navigation={navigation}
          canGoToPreviousStep={canGoToPreviousStep}
        />
      )}
      <div className={classes.container}>
        <div className={classes.stepContainer}>{getCurrentStepContent()}</div>
      </div>
    </div>
  );
}
