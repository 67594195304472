import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { useJobsiteArchiveInvitationMutation } from 'apollo/generated/client-operations';
import { getGraphQLError } from 'utils/error';
import { TrashIcon } from 'components/icons';
import { AlertInstance } from 'components/alertNotification';
import { RemoveOnboardingLinkModalProps } from './types';

export function RemoveOnboardingLinkModal(props: RemoveOnboardingLinkModalProps): ReactElement {
  const { isOpen, jobsiteInvitationId, onCancel, onConfirm } = props;

  const [jobsiteArchiveInvitation, { loading }] = useJobsiteArchiveInvitationMutation({
    onCompleted: (): void => {
      AlertInstance.alert('tc', 'success', 'Success', 'Worker onboarding link successfully removed');
      onConfirm();
    },
    onError: (err): void => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(err));
    },
    variables: { input: { jobsiteInvitationId } },
  });

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="sm"
      title="Delete this worker onboarding link?"
      titleAlignment="center"
      text={`After deletion workers won't be able to register using this link.`}
      textAlignment="center"
      onAction={jobsiteArchiveInvitation}
      actionText="Delete worker onboarding link"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={loading}
    />
  );
}
