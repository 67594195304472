import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { to } from 'acl';
import { AuthContext } from 'auth';
import classNames from 'classnames/bind';
import { DownloadIcon, ExclamationTriangleIcon, MagnetIcon } from 'components/icons';
import { JobsiteWorkerDocumentCardProps } from 'components/cards/types';
import { classes } from 'components/jobsiteWorkerCard/JobsiteWorkerCard.style';
import { ObjectHistory } from 'components/objectHistory';
import { Thumbnail } from 'components/thumbnail';
import { WorkerDocumentFormModalContainer } from 'containers/modals/WorkerDocumentFormModalContainer';
import { DocumentKey, getWorkerDocumentVersionLastUpdated } from 'containers/worker/utils';
import { Button, Col, Row } from 'reactstrap';
import { useBoolean } from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import {
  ExtractionProcessorType,
  IdCardData,
  useExtractIdDataFromFileMutation,
} from 'apollo/generated/client-operations';
import { getGraphQLError } from 'utils/error';
import { AlertInstance } from 'components/alertNotification';
import * as styles from './scss/jobsite-worker-document-card.scss';

const cx = classNames.bind(styles);

type DocumentCardProps = Pick<
  JobsiteWorkerDocumentCardProps,
  'jobsiteWorkerDocument' | 'jobsiteWorkerDocumentVersion'
> & {
  canEditMetadata: boolean;
  isAdmin: boolean;
};

function ActiveDocumentCard(props: DocumentCardProps): ReactElement {
  const { jobsiteWorkerDocumentVersion, jobsiteWorkerDocument, canEditMetadata, isAdmin } = props;
  const { value: isEditModalOpen, setTrue: openEditModal, setFalse: closeEditModal } = useBoolean();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [extractData, setExtractData] = React.useState<IdCardData>(null);
  const { key: documentTypeKey } = jobsiteWorkerDocument.jobsiteWorkerDocumentType?.workerDocumentType ?? {};
  let extractionProcessor: ExtractionProcessorType;
  switch (documentTypeKey) {
    case DocumentKey.GovernmentIssuedId:
      extractionProcessor = ExtractionProcessorType.IdCard;
      break;
    case DocumentKey.NycSiteSafetyTrainingCard:
      extractionProcessor = ExtractionProcessorType.SstCard;
      break;
    case DocumentKey.OshaCard:
      extractionProcessor = ExtractionProcessorType.OshaCard;
      break;
    default:
      break;
  }

  const [extractIdDataFromFile] = useExtractIdDataFromFileMutation({
    fetchPolicy: 'no-cache',
    variables: {
      extractionInput: {
        fileId: jobsiteWorkerDocumentVersion?.files[0]?.fileId,
        extractionType: extractionProcessor,
      },
    },
    onCompleted: async (data) => {
      setIsFetching(false);
      setExtractData(data.extractIdDataFromFile?.extractedData);
      // AlertInstance.alert(
      //   'tc',
      //   'success',
      //   'Extracted Data',
      //   JSON.stringify(data.extractIdDataFromFile?.extractedData, null, 2),
      // );
    },
    onError: async (resetError: ApolloError) => {
      setIsFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(resetError));
    },
  });

  const { additionalFieldValues } = jobsiteWorkerDocumentVersion ?? {};
  const getFieldValue = (fieldKeys: string[]): string => {
    return additionalFieldValues?.find((fieldValue) => fieldKeys.includes(fieldValue?.key))?.value;
  };

  const cardType = getFieldValue(['type', 'card-type']);
  const stateIssued = getFieldValue(['state-issued']);
  const issueDate = getISODateTimeToFormattedDate(getFieldValue(['issue-date']));

  const { workerDocumentType } = jobsiteWorkerDocument?.jobsiteWorkerDocumentType ?? {};
  const workerDocumentKey = workerDocumentType?.key;
  const showDataExtraction =
    isAdmin &&
    (workerDocumentKey === DocumentKey.GovernmentIssuedId ||
      workerDocumentKey === DocumentKey.NycSiteSafetyTrainingCard ||
      workerDocumentKey === DocumentKey.OshaCard);
  const isTrainingConnectCard =
    workerDocumentKey === DocumentKey.NycSiteSafetyTrainingCard &&
    getFieldValue(['is-training-connect-card'])?.toLowerCase() === 'true'
      ? 'Yes'
      : 'No';

  const lastUpdated = getWorkerDocumentVersionLastUpdated(jobsiteWorkerDocumentVersion);
  const fileDownloadUrl = jobsiteWorkerDocumentVersion?.files[0]?.downloadUrl;

  return (
    <div className={cx('jobsite-worker-document-card', 'mb-1')}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column justify-content-around">
          <div className="jobsite-worker-document-card__text-bold">{cardType}</div>
          <div className="font-size-sm">Uploaded: {getISODateTimeToFormattedDate(lastUpdated)}</div>
        </div>
        <div className="d-flex">
          {jobsiteWorkerDocumentVersion?.files.map((file, index) => {
            return <Thumbnail key={file.fileId} file={file} className={cx('avatar-md', { 'ml-2': index > 0 })} />;
          })}
        </div>
      </div>
      {showDataExtraction && (
        <>
          <Row className="mt-2 align-items-center">
            <div className={classes.butterBar}>
              Extract data using Document-AI
              <Button
                size="sm"
                color="primary"
                className={cx({
                  'w-100': !canEditMetadata,
                })}
                onClick={(): void => {
                  if (isFetching) {
                    return;
                  }
                  setIsFetching(true);
                  setExtractData(null);
                  extractIdDataFromFile();
                }}
              >
                <div className="odin-flex odin-items-center">
                  <MagnetIcon className="odin-mr-2" />
                  <span className="d-none d-sm-inline-block">Extract</span>
                </div>
              </Button>
            </div>
          </Row>
          {extractData && (
            <>
              <Row className={classes.butterBar}>
                <Col className="font-size-sm" xs="6">
                  Name
                </Col>
                <Col className="font-size-sm" xs="6">
                  {extractData.fullName}
                </Col>
              </Row>
              <Row className={classes.butterBar}>
                <Col className="font-size-sm" xs="6">
                  ID
                </Col>
                <Col className="font-size-sm" xs="6">
                  {extractData.identifier}
                </Col>
              </Row>
              <Row className={classes.butterBar}>
                <Col className="font-size-sm" xs="6">
                  Issued
                </Col>
                <Col className="font-size-sm" xs="6">
                  {extractData.issueDate}
                </Col>
              </Row>
              <Row className={classes.butterBar}>
                <Col className="font-size-sm" xs="6">
                  Expiration
                </Col>
                <Col className="font-size-sm" xs="6">
                  {extractData.expirationDate}
                </Col>
              </Row>
              <Row className={classes.butterBar}>
                <Col className="font-size-sm" xs="6">
                  DOB
                </Col>
                <Col className="font-size-sm" xs="6">
                  {extractData.dateOfBirth}
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <Row className="mt-2 align-items-center">
        <Col className="jobsite-worker-document-card__text-bold font-size-sm mb-2" xs="6">
          {fileDownloadUrl ? (
            <Button
              size="sm"
              color="primary"
              className={cx({
                'w-100': !canEditMetadata,
              })}
              onClick={(): void => {
                const url =
                  fileDownloadUrl?.endsWith('.html') || fileDownloadUrl?.includes('.html?')
                    ? `/onboarding/document/${jobsiteWorkerDocument?.jobsiteWorkerDocumentId}/print`
                    : fileDownloadUrl;
                window.open(url);
              }}
            >
              <DownloadIcon className="mr-sm-2" />
              <span className="d-none d-sm-inline-block">Download</span>
            </Button>
          ) : (
            <div className="jobsite-worker-document-card__no-file-found">
              <ExclamationTriangleIcon className="odin-text-white odin-mr-2" />
              No file found
            </div>
          )}
        </Col>
        <Col className="font-size-sm" xs="6">
          {canEditMetadata && (
            <span
              className="d-flex text-decoration-underline cursor-pointer font-size-sm"
              style={{ color: '#3E5BFE' }}
              onClick={openEditModal}
            >
              Edit Document
            </span>
          )}
        </Col>
        {stateIssued && (
          <>
            <Col className="jobsite-worker-document-card__text-bold font-size-sm" xs="6">
              State issued
            </Col>
            <Col className="font-size-sm" xs="6">
              {stateIssued}
            </Col>
          </>
        )}
        {issueDate && (
          <>
            <Col className="jobsite-worker-document-card__text-bold font-size-sm" xs="6">
              Issue date
            </Col>
            <Col className="font-size-sm" xs="6">
              {issueDate}
            </Col>
          </>
        )}
        {workerDocumentKey === DocumentKey.NycSiteSafetyTrainingCard && (
          <>
            <Col className="jobsite-worker-document-card__text-bold font-size-sm" xs="6">
              Training Connect Card
            </Col>
            <Col className="font-size-sm" xs="6">
              {isTrainingConnectCard}
            </Col>
          </>
        )}
      </Row>
      <ObjectHistory
        className="mt-2 font-size-sm jobsite-worker-document-card__text-sm"
        object={jobsiteWorkerDocumentVersion}
      />

      <WorkerDocumentFormModalContainer
        isModalOpen={isEditModalOpen}
        jobsiteId={jobsiteWorkerDocument?.jobsiteWorkerDocumentType?.jobsite?.jobsiteId}
        jobsiteWorkerDocument={jobsiteWorkerDocument}
        jobsiteWorkerDocumentVersion={jobsiteWorkerDocumentVersion}
        setModalOpen={closeEditModal}
      />
    </div>
  );
}

function PreviousDocumentCard(props: DocumentCardProps): ReactElement {
  const { jobsiteWorkerDocumentVersion, jobsiteWorkerDocument, canEditMetadata } = props;
  const { additionalFieldValues } = jobsiteWorkerDocumentVersion ?? {};
  const getFieldValue = (fieldKeys: string[]): string => {
    return additionalFieldValues?.find((fieldValue) => fieldKeys.includes(fieldValue?.key))?.value;
  };

  const cardType = getFieldValue(['type', 'card-type']);
  const lastUpdated = getWorkerDocumentVersionLastUpdated(jobsiteWorkerDocumentVersion);

  return (
    <div className={cx('jobsite-worker-document-card', 'mb-1')}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column justify-content-around">
          <div className="jobsite-worker-document-card__text-bold">{cardType}</div>
          <div className="font-size-sm">Uploaded: {getISODateTimeToFormattedDate(lastUpdated)}</div>
        </div>
        <div className="odin-flex odin-gap-x-1.5">
          {jobsiteWorkerDocumentVersion?.files.map((file) => {
            return <Thumbnail key={file.fileId} file={file} className="avatar-md" />;
          })}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <Button
          size="sm"
          color="primary"
          className={cx({
            'w-100': !canEditMetadata,
          })}
          onClick={(): void => {
            const fileDownloadUrl = jobsiteWorkerDocumentVersion?.files[0]?.downloadUrl;
            const url =
              fileDownloadUrl?.endsWith('.html') || fileDownloadUrl?.includes('.html?')
                ? `/onboarding/document/${jobsiteWorkerDocument?.jobsiteWorkerDocumentId}/print`
                : fileDownloadUrl;
            window.open(url);
          }}
        >
          <DownloadIcon className="mr-sm-2" />
          <span className="d-none d-sm-inline-block">Download</span>
        </Button>
      </div>
    </div>
  );
}

export function JobsiteWorkerDocumentCard(props: JobsiteWorkerDocumentCardProps): ReactElement {
  const { isActive, isEditable, jobsiteWorkerDocument, jobsiteWorkerDocumentVersion } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const { workerDocumentType } = jobsiteWorkerDocument?.jobsiteWorkerDocumentType ?? {};
  const canEditMetadata =
    isEditable &&
    !workerDocumentType?.isPhi &&
    user.isAllowed(to.editMetadata, jobsiteWorkerDocument?.jobsiteWorkerDocumentType?.jobsite?.jobsiteId);
  const isAdmin = user.isAllowed(to.addOrganizations);

  const DocumentCard = isActive ? ActiveDocumentCard : PreviousDocumentCard;

  return (
    <DocumentCard
      jobsiteWorkerDocument={jobsiteWorkerDocument}
      jobsiteWorkerDocumentVersion={jobsiteWorkerDocumentVersion}
      canEditMetadata={canEditMetadata}
      isAdmin={isAdmin}
    />
  );
}
