import { NewButton } from '@odin-labs/components';
import { InfoList } from 'components/infoList';
import { InfoRow } from 'components/infoRow';
import { LoadingError } from 'components/loadingError';
import { OrganizationTabProps } from 'containers/organization/types';
import React, { ReactElement } from 'react';
import { TrashIcon } from 'components/icons';
import { AuthContext } from 'auth/';
import { to } from 'acl/';
import { useBoolean } from 'utils/';
import { DeleteOrganizationModal } from 'containers/organization/modals/DeleteOrganizationModal';
import { getInfoRows } from './utils';

export function OrganizationInfoTabContainer(props: OrganizationTabProps): ReactElement {
  const { onTabApiChange, loading, error, refetchOrganizationData, organization } = props;
  const { currentUser: user } = React.useContext(AuthContext);
  const { contractors, developers } = organization ?? {};

  const {
    value: isDeleteOrganizationModalOpen,
    setTrue: openDeleteOrganizationModal,
    setFalse: closeDeleteOrganizationModal,
  } = useBoolean(false);

  const organizationInfoRows = getInfoRows(props);

  const { count: contractorCount, edges: contractorsEdges } = contractors ?? {};
  const { count: developerCount } = developers ?? {};
  const jobsiteContractorsCount =
    contractorCount && !developerCount
      ? contractorsEdges
          ?.map((contractor) => contractor.node.jobsiteContractors?.count)
          .filter(Boolean)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      : 0;
  const allowDelete = user.isAllowed(to.addOrganizations) && developerCount < 1 && jobsiteContractorsCount < 1;

  React.useEffect(() => onTabApiChange({ refetchData: refetchOrganizationData }), [refetchOrganizationData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <>
      <InfoList withBottomBorder loading={loading}>
        {organizationInfoRows.map((row) => (
          <InfoRow key={row.label} {...row} allowEdit={false} loading={loading} />
        ))}
      </InfoList>
      {allowDelete ? (
        <div className="odin-flex odin-justify-end odin-pb-9">
          <NewButton
            theme="danger"
            icon={TrashIcon}
            text="Delete Organization / Contractor"
            onClick={openDeleteOrganizationModal}
          />
          <DeleteOrganizationModal
            isOpen={isDeleteOrganizationModalOpen}
            closeModal={closeDeleteOrganizationModal}
            organization={organization}
          />
        </div>
      ) : undefined}
    </>
  );
}
