import cn from 'classnames';
import { Column, getFaIcon } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import {
  GetDeveloperContainerDeveloperInfoQuery,
  GetDeveloperWorkersContainerCurrentSessionQuery,
} from 'apollo/generated/client-operations';
import { PageSearchParams } from 'utils';
import { faLandmark } from 'components/icons';
import { UsersTabProps } from 'containers/users/UsersTab';

export type User = GetDeveloperWorkersContainerCurrentSessionQuery['getCurrentSession']['user'];
export type DeveloperWorker = User['workers']['edges'][number]['node'];
export type Jobsite = GetDeveloperContainerDeveloperInfoQuery['getDeveloper']['jobsites']['edges'][number]['node'];

export type DeveloperInfo = GetDeveloperContainerDeveloperInfoQuery['getDeveloper'];

export type DeveloperWorkerColumn = Column<DeveloperWorker>;

export type DeveloperWorkersFilters = Pick<PageSearchParams, 'jobsiteIds' | 'search' | 'page'>;
export type DeveloperUsersFilters = Pick<PageSearchParams, 'search'>;

export const DeveloperIcon = getFaIcon({ icon: faLandmark, className: cn('odin-text-base odin-text-odin-primary') });

export type DeveloperTabApi = {
  refetchData: () => void;
};

export type DeveloperTabProps = UsersTabProps & {
  jobsites?: Jobsite[];
  developer: DeveloperInfo;
  loading?: boolean;
  error?: ApolloError;
  onTabApiChange?: (api: DeveloperTabApi) => void;
  refetchDeveloperData?: () => void;
};
