import React, { ReactElement } from 'react';
import { Copy } from 'containers/userPendingChange/helpers/languages';
import { UserPendingChangeStepProps } from 'containers/userPendingChange/steps/types';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { UserPendingChangeType } from 'apollo/generated/client-operations';
import { ExclamationTriangleIcon } from 'components/icons';

export function NoPendingChangeStep({ localize, changeType }: UserPendingChangeStepProps): ReactElement {
  const headingCopy =
    changeType === UserPendingChangeType.Email
      ? Copy.no_email_pending_change_header
      : Copy.no_phone_pending_change_header;

  const textCopy =
    changeType === UserPendingChangeType.Email ? Copy.no_email_pending_change_text : Copy.no_phone_pending_change_text;

  return (
    <div className={commonClasses.stepContainer}>
      <div className="odin-p-7.5 sm:odin-col-span-3">
        <div
          className={`odin-p-6 odin-rounded-xl odin-bg-odin-light-yellow odin-text-center 
        odin-border odin-border-yellow-300`}
        >
          <ExclamationTriangleIcon className="odin-text-3xl odin-text-odin-black" />
          <h2 className="odin-my-3 odin-text-1.5xl odin-text-gray-900 odin-font-medium">{localize(headingCopy)}</h2>
          <div className="odin-text-sm odin-text-gray-600">{localize(textCopy)}</div>
        </div>
      </div>
    </div>
  );
}
