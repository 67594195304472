import React, { ReactElement, useCallback } from 'react';
import cn from 'classnames';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Alert, Modal } from '@odin-labs/components';
import { MergeUnionType, ServerFile, TransformableFile } from 'types';
import { dataURLtoBlob, useBoolean, useUpdatableState } from 'utils';
import { setRefFactory } from 'components/utils';
import { CheckIcon, FileSignatureIcon, PlusIcon, SignatureIcon } from 'components/icons';
import { convertHeic } from 'components/filePreview';
import { DropzoneFile } from './DropzoneFile';
import { DropzoneAvatar } from './DropzoneAvatar';
import { NewDropzoneProps, InputFile, NewDropzoneSingleProps, NewDropzoneMultiProps } from './types';
import { extractPdfFirstPage, PdfFile } from './utils';
import { classes } from './NewDropzone.style';

const supportsMultipleFiles = (
  props: NewDropzoneMultiProps | NewDropzoneSingleProps,
): props is NewDropzoneMultiProps => {
  return props.multiple;
};

export const NewDropzone = React.forwardRef(
  (props: NewDropzoneProps, ref: React.ForwardedRef<HTMLInputElement>): ReactElement => {
    const {
      name,
      label,
      error,
      loading,
      accept,
      uploadEntirePdf,
      sourceType = 'file',
      multiple = false,
      maxFiles = 2,
      isAvatar,
      avatarSize,
      disabled,
      hideAvatarChangePhotoButton,
      value,
      // `onChange` and `onBeforeChange` are destructured to not be passed further to `input` component
      onChange, // eslint-disable-line @typescript-eslint/no-unused-vars
      onBeforeChange, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...outerInputProps
    } = props as MergeUnionType<NewDropzoneMultiProps | NewDropzoneSingleProps>;

    const [innerValue, setInnerValue] = useUpdatableState<InputFile | InputFile[]>(value);

    const { value: isStylusModalOpen, setTrue: openStylusModal, setFalse: closeStylusModal } = useBoolean(false);
    const {
      value: isSignatureTypeModalOpen,
      setTrue: openSignatureTypeModal,
      setFalse: closeSignatureTypeModal,
    } = useBoolean(false);
    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const signatureRef = React.useRef<ReactSignatureCanvas>();

    const shouldExtractFirstPdfPage = (file: File): boolean => file.type.includes('pdf') && !uploadEntirePdf;
    const shouldTransformHeicFile = (file: File): boolean => file.type.includes('heic') || file.type.includes('heif');

    const onDrop = useCallback<DropzoneOptions['onDrop']>(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (acceptedFiles: File[], fileRejections: FileRejection[], _event: DropEvent) => {
        // if the user selected more than the allowed number of files (maxFiles)
        // then `acceptedFiles` will be empty and `fileRejections` will contain the selected files
        const droppedFiles =
          !multiple || acceptedFiles.length
            ? acceptedFiles
            : fileRejections
                .filter((fr) => fr.errors.some((err) => err.code === 'too-many-files'))
                .slice(-maxFiles) // get the latest files from the array
                .map(({ file }) => file);

        const newInnerValue = droppedFiles.map((file: File): TransformableFile => {
          return shouldExtractFirstPdfPage(file) || shouldTransformHeicFile(file)
            ? Object.assign(file, { isTransformationInProgress: true })
            : file;
        });
        setInnerValue(newInnerValue);

        const newFiles = await Promise.all(
          droppedFiles.map(async (file: File): Promise<File> => {
            if (shouldExtractFirstPdfPage(file)) {
              return extractPdfFirstPage(file);
            }
            if (shouldTransformHeicFile(file)) {
              return convertHeic(file);
            }
            return file;
          }),
        );

        if (supportsMultipleFiles(props)) {
          const newValue = (await props.onBeforeChange?.(newFiles)) ?? newFiles;
          // overwrite the existing files if the total selected number of files exceeds `maxFiles`
          const existingFiles = props.value?.slice(0, Math.max(0, maxFiles - newValue.length)) ?? [];
          props.onChange?.([...existingFiles, ...newValue]);
        } else {
          const [firstFile] = newFiles;
          const newValue = (await props.onBeforeChange?.(firstFile)) ?? firstFile;
          props.onChange?.(newValue);
        }
      },
      [value, props.onChange],
    );

    const onRemove = (file: InputFile): void => {
      if (props.multiple) {
        const newValue = props.value.filter((item) => file !== item);
        props.onChange?.(newValue.length ? newValue : null);
      } else {
        props.onChange?.(null);
      }
    };

    const { getRootProps, getInputProps, inputRef } = useDropzone({
      onDrop,
      accept,
      multiple,
      maxFiles,
      disabled,
    });

    const hasPartialPdf =
      !uploadEntirePdf &&
      (Array.isArray(innerValue)
        ? innerValue?.some((file) => (file as PdfFile)?.isMultiPagePdf)
        : (innerValue as PdfFile)?.isMultiPagePdf);

    const inputProps = getInputProps();

    const setRef = setRefFactory<HTMLInputElement>({ innerRef: inputRef, outerRef: ref });

    const { onClick, ...rootProps } = getRootProps();

    const onDropzoneAreaClick =
      sourceType !== 'file'
        ? (): void => {
            switch (sourceType) {
              case 'stylus':
                openStylusModal();
                break;
              case 'fileAndStylus':
                openSignatureTypeModal();
                break;
              default:
                break;
            }
          }
        : onClick;

    const onConfirmStylusSignature = async (): Promise<void> => {
      setIsFetching(true);
      const dataUrl = signatureRef.current.toDataURL();
      const blob = dataURLtoBlob(dataUrl);
      const file = new File([blob], 'signature.png', {
        type: 'image/png',
      });
      if (!supportsMultipleFiles(props)) {
        const newValue = (await props.onBeforeChange?.(file)) ?? file;
        props.onChange?.(newValue);
      }
    };

    const getFilesContent = (): React.ReactNode => {
      if (isAvatar) {
        const imageFile = Array.isArray(innerValue) ? innerValue[0] : innerValue;
        return (
          <div className="odin-flex odin-justify-center">
            <DropzoneAvatar
              imageAlt="profile"
              imageFile={imageFile}
              size={avatarSize}
              hideChangePhotoButton={hideAvatarChangePhotoButton || disabled}
            />
          </div>
        );
      }

      return (
        <div className="odin-p-6">
          {innerValue ? (
            <div className={classes.dropzoneFiles}>
              {(Array.isArray(innerValue) ? innerValue : [innerValue]).map((file: InputFile): ReactElement => {
                const fileKey = (file as ServerFile)?.fileId ?? (file as File)?.name ?? '';
                return (
                  <DropzoneFile
                    file={file}
                    key={fileKey}
                    onRemove={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                      event.stopPropagation();
                      onRemove(file);
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className="odin-flex odin-flex-col odin-items-center odin-justify-center odin-h-28 odin-gap-y-1.5">
              <PlusIcon className="odin-text-2.33xl odin-text-odin-primary" />
              <div className={classes.dropzoneLabel}>{label}</div>
            </div>
          )}
        </div>
      );
    };

    return (
      <div className={classes.dropzone}>
        {hasPartialPdf && (
          <Alert text="This file is a multi-page PDF: only the first page (displayed below) will be saved." />
        )}
        {loading && <div className={classes.skeleton} />}
        <div {...rootProps} onClick={onDropzoneAreaClick} className={classes.dropzoneArea({ isAvatar, disabled })}>
          <input {...outerInputProps} id={name} {...inputProps} ref={setRef} />
          {getFilesContent()}
        </div>
        {error ? <div className={cn('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div> : null}

        <Modal
          open={isSignatureTypeModalOpen}
          size="sm"
          title="Upload signature or sign with stylus"
          titleAlignment="center"
          setOpen={closeSignatureTypeModal}
          onCancel={(): void => inputRef.current?.click()}
          onAction={(): void => {
            openStylusModal();
            closeSignatureTypeModal();
          }}
          cancelText="Upload photo"
          cancelIcon={FileSignatureIcon}
          actionText="Sign with stylus"
          actionIcon={SignatureIcon}
        />
        <Modal
          open={isStylusModalOpen}
          size="sm"
          title="Sign your signature below."
          setOpen={closeStylusModal}
          onAction={(): void => {
            onConfirmStylusSignature();
            closeStylusModal();
            setIsFetching(false);
          }}
          cancelText="Cancel"
          actionText="Save"
          actionIcon={CheckIcon}
          actionButtonEnabled={!isFetching}
        >
          <div className="odin-border odin-border-gray-400 odin-border-dashed odin-rounded-sm">
            <ReactSignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            />
          </div>
        </Modal>
      </div>
    );
  },
);
