import React, { ReactElement } from 'react';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { getFormattedPhoneNumber, useBoolean } from 'utils';
import { PenIcon } from 'components/icons';
import { EditPersonalInfoModal } from 'containers/worker/beta/modals/EditPersonalInfoModal';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { WorkerPersonalInfoField } from './WorkerPersonalInfoField';
import { WorkerPersonalInfoProps } from './types';
import { classes } from './WorkerPersonalInfo.style';

export function WorkerPersonalInfo({ worker, refetch }: WorkerPersonalInfoProps): ReactElement {
  const { value: isSsnVisible, setTrue: showSsn, setFalse: hideSsn } = useBoolean(false);
  const { value: isEditModalOpen, setTrue: openEditModal, setFalse: closeEditModal } = useBoolean(false);

  const { currentUser: user } = React.useContext(AuthContext);

  if (!worker) {
    return null;
  }

  const getLast4SsnValue = (): JSX.Element => {
    const ssnText = isSsnVisible ? worker.ssnLastFour : '****';
    const actionText = isSsnVisible ? 'Hide' : 'Show';
    const actionHandler = isSsnVisible ? hideSsn : showSsn;

    return (
      <>
        <span className="odin-text-odin-dark-gray">{ssnText}</span>{' '}
        <button className="odin-text-odin-primary hover:odin-underline" onClick={actionHandler}>
          {actionText}
        </button>
      </>
    );
  };

  const addressValue = (
    <>
      {worker?.addressLine1} {worker?.addressLine2}
      <br />
      {worker?.addressCity}, {worker?.addressState} {worker?.addressZipCode}
    </>
  );

  const workerBirthDate = getISODateTimeToFormattedDate(convertUTCtoLocalDate(worker?.birthDate));
  const workerPhoneNumber = getFormattedPhoneNumber(worker?.user?.identity?.phoneNumber);
  const workerEmail = worker?.user?.identity?.email;
  const canAddUnions = user.isAllowed(to.addUnions);

  return (
    <div data-test="worker-personal-info" className={classes.workerPersonalInfo}>
      <div data-test="worker-personal-info-data" className={classes.workerPersonalInfoData}>
        <WorkerPersonalInfoField label="Date of Birth" value={workerBirthDate} />
        <WorkerPersonalInfoField
          label="Address"
          value={addressValue}
          labelClassName={classes.addressLabel}
          valueClassName={classes.addressValue}
        />
        <WorkerPersonalInfoField
          label="Union affiliation"
          value={worker?.unionAffiliation}
          labelClassName={classes.unionAffiliationLabel}
          valueClassName={classes.unionAffiliationValue}
        />
        <WorkerPersonalInfoField
          label="Last 4 of SSN"
          value={getLast4SsnValue()}
          labelClassName={classes.last4ssnLabel}
          valueClassName={classes.last4ssnValue}
        />
        <WorkerPersonalInfoField
          label="Race"
          value={worker?.race}
          labelClassName={classes.raceLabel}
          valueClassName={classes.raceValue}
        />
        <WorkerPersonalInfoField
          label="Primary Language"
          value={worker?.primaryLanguage}
          labelClassName={classes.primaryLanguageLabel}
          valueClassName={classes.primaryLanguageValue}
        />
        <WorkerPersonalInfoField
          label="Phone"
          value={workerPhoneNumber}
          labelClassName={classes.phoneLabel}
          valueClassName={classes.phoneValue}
        />
        <WorkerPersonalInfoField
          label="Gender"
          value={worker?.gender}
          labelClassName={classes.genderLabel}
          valueClassName={classes.genderValue}
        />
        <WorkerPersonalInfoField
          label="Email address"
          value={workerEmail}
          labelClassName={classes.emailLabel}
          valueClassName={classes.emailValue}
        />
        <WorkerPersonalInfoField
          label="Veteran Status"
          value={worker?.isVeteran ? 'Yes' : 'No'}
          labelClassName={classes.veteranStatusLabel}
          valueClassName={classes.veteranStatusValue}
        />
      </div>
      <div data-test="worker-personal-info-edit">
        <button className={classes.editButton} onClick={openEditModal}>
          <PenIcon className={classes.editButtonIcon} />
        </button>
      </div>
      {isEditModalOpen && (
        <EditPersonalInfoModal
          isOpen
          canAddUnions={canAddUnions}
          worker={worker}
          onCancel={closeEditModal}
          refetch={refetch}
        />
      )}
    </div>
  );
}
