import React, { useState, ReactElement } from 'react';
import { AppErrorCode, UserErrorCode, useUserCreateMutation } from 'apollo/generated/client-operations';
import { isEmpty, useIsMounted } from 'utils';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { UserPlusIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { EmailAlreadyExistsErrorExtensions, PhoneAlreadyExistsErrorExtensions } from 'types';
import { getDefaultValues, getFormInputs, getAddInput } from './AddUserModal.forms';
import { AddUserFormData, AddUserModalProps } from './types';
import { useUserModalOptions } from './useUserModalOptions';

export function AddUserModal(props: AddUserModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, refetchQueries, userRoleType, userRoles, jobsite, contractor } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [createUser] = useUserCreateMutation({ refetchQueries });

  const onSubmit: FormOnSubmit<AddUserFormData> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (isEmpty(dirtyFields)) return;
      const input = getAddInput(userRoleType, data, dirtyFields);
      if (input) {
        await createUser({ variables: { input } });
        AlertInstance.alert('tc', 'success', 'Success', 'User added');
        onConfirm();
      }
    } catch (error) {
      event.preventDefault();

      const emailAlreadyExistsError = getGraphQLErrorByCode<EmailAlreadyExistsErrorExtensions>(
        error,
        UserErrorCode.EmailAlreadyExists,
      );
      if (emailAlreadyExistsError) {
        formApi.setError('email', {
          message: <AlreadyExistingItem itemType="Email" workerId={emailAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        return;
      }
      const phoneAlreadyExistsError = getGraphQLErrorByCode<PhoneAlreadyExistsErrorExtensions>(
        error,
        AppErrorCode.PhoneAlreadyExists,
      );
      if (phoneAlreadyExistsError) {
        formApi.setError('phoneNumber', {
          message: <AlreadyExistingItem itemType="Phone" workerId={phoneAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        return;
      }
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  const { userRolesOptions, contractorsOptions, developersOptions, jobsitesOptions, error } = useUserModalOptions({
    jobsite,
    contractor,
    userRoleType,
    userRoles,
    includeAssignedObjects: true,
  });

  const formInputs = React.useMemo(
    () => getFormInputs({ userRoleType, userRolesOptions, contractorsOptions, jobsitesOptions, developersOptions }),
    [userRoleType, userRolesOptions, contractorsOptions, jobsitesOptions],
  );
  const defaultValues = React.useMemo(
    () => getDefaultValues({ userRolesOptions, contractorsOptions, jobsitesOptions, developersOptions }),
    [userRolesOptions, contractorsOptions, jobsitesOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title={`Add ${userRoleType} User`}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add User"
      actionIcon={UserPlusIcon}
      actionButtonWithSpinner={isSaving}
      error={error}
    />
  );
}
