import { ExecutionResult } from 'graphql';
import { Moment } from 'moment';
import { DeepMap } from 'react-hook-form';
import { ApolloError, MutationFunctionOptions, QueryResult } from '@apollo/client';
import { Icon, SelectOptionElement } from '@odin-labs/components';
import {
  CardPrintJob,
  ContractorWorker,
  GetAllContractorsResponse,
  JobsiteWorker as GqlJobsiteWorker,
  JobsiteWorkerDocument,
  JobsiteWorkerDocumentType,
  MutationUpdateJobsiteWorkerArgs,
  QueryGetAllDocumentsForJobsiteWorkerArgs,
  StateAbbreviation,
  WorkerCard,
  GetJobsiteWorkerQuery,
  WorkerCitizenshipStatus,
  useGetJobsiteWorkerQuery,
} from 'apollo/generated/client-operations';
import { Maybe, ServerFile } from 'types';
import { User } from 'auth';
import { FormDefaultValue } from 'components/form';
import { DocumentKey } from 'containers/worker/utils';
import { ContractorOptionElement } from 'containers/contractor/helpers';
import { DeferredPromise } from 'utils';

export type JobsiteWorker = GetJobsiteWorkerQuery['getJobsiteWorker'];
export type Jobsite = JobsiteWorker['jobsiteContractor']['jobsite'];
export type JobsiteModule = Jobsite['modules'][number];
export type JobsiteOnboardingModule = Extract<JobsiteModule, { __typename?: 'JobsiteOnboardingModule' }>;
export type JobsiteOnboardingStep = JobsiteOnboardingModule['steps'][number];
export type JobsiteOnboardingDocument = JobsiteOnboardingModule['documents'][number];
export type JobsiteOnboardingField = JobsiteOnboardingDocument['fields'][number];

export interface WorkerOnboardingSidebarContainerProps {
  matchedPath: string;
  pathname: string;
}

export interface OnboardingUrlParams {
  jobsiteWorkerId?: string;
  step?: string;
}

export interface GetAllContractorsQueryResponse {
  getAllContractors: GetAllContractorsResponse;
}

export type WorkerDocumentsDataType = {
  [documentKey: string]: File[] | ServerFile[] | string | undefined;
  // stickerNumber: string;
  layout?: undefined;
};

export interface UploadFileResponse {
  uploadSingleFile: ServerFile;
}

export type SiteSafetyTrainingDataType = WorkerDocumentsDataType & {
  stickerNumber: string;
};

export type SignatureDataType = WorkerDocumentsDataType;

export type MedicalConditionsDataType = WorkerDocumentsDataType & {
  medicalNotes?: string;
};

export interface GetJobsiteWorkerDocumentTypesResponse {
  getAllJobsiteWorkerDocumentTypes: Array<JobsiteWorkerDocumentType>;
}
export type EditFormDataType = {
  workerId?: string;
  userAccountId?: string;
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  suffix?: string;
  birthDate?: string;
  ssnLastFour?: string;
  race?: SelectOptionElement;
  gender?: SelectOptionElement;
  primaryLanguage?: SelectOptionElement;
  citizenshipStatus?: SelectOptionElement<WorkerCitizenshipStatus>;
  isVeteran?: SelectOptionElement;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: SelectOptionElement<StateAbbreviation>;
  addressZipCode?: string;
  phoneNumber?: string;
  email?: string;
  jobTitle?: SelectOptionElement;
  emergencyContactName?: string;
  emergencyContactRelationship?: SelectOptionElement;
  emergencyContactPhone?: string;
  trade?: SelectOptionElement;
  unionAffiliation?: SelectOptionElement;
  isSkilled?: boolean;
  isSupervisor?: boolean;
  supervisorName?: string;
  supervisorPhoneNumber?: string;
  contractor?: ContractorOptionElement;
  payrollId?: string;
  baseHourlyRate?: string;
  organizationName?: string;
  cancelButton?: undefined;
  submitButton?: undefined;
  contractorId?: string;
  jobsiteId?: string;
  jobAssignmentStartDate?: string;
  jobAssignmentExpirationDate?: string;
};

export interface EditFormDefaultDataType
  extends Omit<
    EditFormDataType,
    | 'race'
    | 'gender'
    | 'primaryLanguage'
    | 'isVeteran'
    | 'addressState'
    | 'jobTitle'
    | 'emergencyContactRelationship'
    | 'trade'
    | 'unionAffiliation'
  > {
  race?: string;
  gender?: string;
  primaryLanguage?: string;
  isVeteran?: boolean | string;
  addressState?: StateAbbreviation;
  jobTitle?: string;
  emergencyContactRelationship?: string;
  trade?: string;
  unionAffiliation?: string;
}

export interface GetAllContractorsForWorkerResponse {
  getAllContractorsForWorker: Array<ContractorWorker>;
}

export type MatchedWorkerCard = WorkerCard & { matchingWorkerCard: Maybe<WorkerCard> };

export interface JobsiteWorkerOnboardingNavbarProps {
  jobsiteId: string;
  step: string;
  jobsiteWorker: JobsiteWorker;
}

export interface JobsiteWorkerOnboardingActionsProps {
  step: string;
  jobsiteWorker: JobsiteWorker;
}

export interface GetAllDocumentsForJobsiteWorker {
  getAllDocumentsForJobsiteWorker: JobsiteWorkerDocument[];
}

export interface JobsiteWorkerOnboardingFooterNavbarProps {
  jobsiteWorker: JobsiteWorker;
  isFormDirty: boolean;
  error?: string;
  onSave?: () => Promise<void> | Promise<boolean>;
  onOverride?: () => void;
  hideLockSession?: boolean;
  hideSkipOptions?: boolean;
  hideSave?: boolean;
  onResetComplete?: () => void;
  onForceComplete?: (reason: string, expirationDate: Moment, exemptionReason: string) => void;
  showConditionalPassExpiration?: boolean;
  showExemptionReason?: boolean;
  hasNoWorkerCards?: boolean;
}

export interface CardPrintJobTableRowData {
  id?: string;
  startedAt?: string;
  hidPrintJobId?: string;
  statusMessage?: string;
  completedAt?: string;
  cardNumber?: string;
  facilityCode?: string;
  cardPrintJob?: CardPrintJob;
}

export interface WorkerCardTableRowData {
  id?: string;
  cardNumber?: string;
  status?: string;
  bluetoothCardNumber?: string;
  bluetoothStatus?: string;
  isTemporary?: string;
  issuedAt?: string;
  expiredAt?: string;
  workerCard?: WorkerCard;
}

export interface JobsiteWorkerOnboardingSidebarElementProps {
  onClick?: () => void;
  title?: string;
  subtitle?: string;
  skipped?: string;
  completed?: boolean;
  failed?: boolean;
  icon?: Icon;
  active?: boolean;
}

type JobsiteWorkerQueryResult = ReturnType<typeof useGetJobsiteWorkerQuery>;
type AllDocumentsForJobsiteWorkerQueryResult = QueryResult<
  GetAllDocumentsForJobsiteWorker,
  QueryGetAllDocumentsForJobsiteWorkerArgs
>;

export type UseJobsiteWorkerOnboardingDataArgs = {
  jobsiteWorkerId: string;
};

type OnboardingDocumentsJobsiteWorker = Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
  jobsiteContractor?: {
    jobsite?: Pick<JobsiteWorker['jobsiteContractor']['jobsite'], 'jobsiteId'>;
  };
};
export type UseJobsiteWorkerOnboardingDocumentsArgs = {
  jobsiteWorker: OnboardingDocumentsJobsiteWorker;
};

export type JobsiteWorkerOnboardingData = {
  loading: boolean;
  error: ApolloError;
  jobsiteWorker: JobsiteWorker | undefined;
  jobsiteWorkerDocumentTypes: JobsiteWorkerDocumentType[];
  jobsiteWorkerDocuments: JobsiteWorkerDocument[];
  filteredDocumentTypeIds: Record<string, string>;
  workerContractors: ContractorWorker[];
  refetchJobsiteWorker: JobsiteWorkerQueryResult['refetch'];
  refetchJobsiteWorkerDocuments: AllDocumentsForJobsiteWorkerQueryResult['refetch'];
};

export type JobsiteWorkerOnboardingDocuments = Pick<
  JobsiteWorkerOnboardingData,
  | 'loading'
  | 'error'
  | 'jobsiteWorkerDocumentTypes'
  | 'jobsiteWorkerDocuments'
  | 'filteredDocumentTypeIds'
  | 'refetchJobsiteWorkerDocuments'
>;

export type JobsiteWorkerDocumentTypes = Pick<
  JobsiteWorkerOnboardingDocuments,
  'loading' | 'error' | 'jobsiteWorkerDocumentTypes'
>;

export type JwDocument = { key: string; id: string };

export type OnboardingStepProps = Pick<
  JobsiteWorkerOnboardingData,
  'loading' | 'refetchJobsiteWorker' | 'refetchJobsiteWorkerDocuments'
> & {
  jobsiteWorker?: JobsiteWorker;
  jobsiteWorkerId?: string;
  contractorWorkerData?: ContractorWorker;
  defaultFormValues?: FormDefaultValue<WorkerDocumentsDataType>[];
  filteredDocumentTypeIds: Record<string, string>;
  workerContractors: ContractorWorker[];
  documents?: JwDocument[];
};

export enum OnboardingStepName {
  PersonalInfo = 'Personal information',
  WorkDocuments = 'Work documents',
  MedicalInfo = 'Medical',
  OnboardingTraining = 'Onboarding training',
  Signature = 'Signature',
  Badging = 'Badging',
  SiteSpecificOrientation = 'Site Specific Orientation',
}

export enum OnboardingStepKey {
  PersonalInfo = 'personal-information',
  WorkDocuments = 'work-documents',
  MedicalInfo = 'medical-testing',
  OnboardingTraining = 'onboarding-training',
  Signature = 'signature',
  Badging = 'badging',
  SiteSpecificOrientation = 'site-specific-orientation',
}

export type SidebarElement = {
  key: OnboardingStepKey;
} & Pick<JobsiteWorkerOnboardingSidebarElementProps, 'title' | 'icon' | 'skipped' | 'completed'>;

export interface UseMedicalDocumentsUploadArgs {
  jobsiteWorker: Pick<GqlJobsiteWorker, 'jobsiteWorkerId'>;
  documentTypes: readonly DocumentKey[];
  filteredDocumentTypeIds: Record<string, string>;
  user: User;
  documents?: JwDocument[];
  refetchJobsiteWorkerDocuments: AllDocumentsForJobsiteWorkerQueryResult['refetch'];
  onUploadCompleted?: () => void;
  deferredSubmission?: DeferredPromise<boolean>;
}

export type UploadDocumentsArgs<T extends WorkerDocumentsDataType> = {
  data: T;
  dirtyFields: DeepMap<T, true>;
};

export interface MedicalDocumentsUpload {
  uploadDocuments: (args: UploadDocumentsArgs<MedicalConditionsDataType>) => Promise<void>;
  updateJobsiteWorker: (
    options?: MutationFunctionOptions<GqlJobsiteWorker, MutationUpdateJobsiteWorkerArgs>,
  ) => Promise<ExecutionResult<GqlJobsiteWorker>>;
  fetching: boolean;
  setFetching: (value: boolean) => void;
}
