import { TabConfig } from '@odin-labs/components';
import {
  useGetDeveloperContainerDeveloperInfoQuery,
  useGetRolesQuery,
  UserAssignmentType,
  UserRoleKey,
} from 'apollo/generated/client-operations';

import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { DeveloperJobsitesTabContainer } from 'containers/developer/tabs/DeveloperJobsitesTabContainer';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { UsersTab } from 'containers/users/UsersTab';

import { DeveloperTitle } from './components/DeveloperTitle';
import { DeveloperInfoTabContainer } from './tabs/DeveloperInfoTabContainer';
import { DeveloperWorkersTabContainer } from './tabs/DeveloperWorkersTabContainer';
import { DeveloperIcon, DeveloperInfo, DeveloperTabApi, DeveloperTabProps } from './types';

export enum DeveloperTabName {
  ClientInfo = 'clientInfo',
  Jobsites = 'jobsites',
  Workers = 'workers',
  JobsiteUsers = 'jobsiteUsers',
  ClientUsers = 'clientUsers',
}

const getTabsConfig = (developer: DeveloperInfo): TabConfig<DeveloperTabProps>[] => [
  {
    name: DeveloperTabName.ClientInfo,
    text: 'Client Info',
    relativePath: '',
    component: DeveloperInfoTabContainer,
    leftIcon: DeveloperIcon,
  },
  {
    name: DeveloperTabName.Jobsites,
    text: 'Jobsites',
    relativePath: '/jobsites',
    component: DeveloperJobsitesTabContainer,
    badge: developer?.jobsites?.count?.toString(),
  },
  {
    name: DeveloperTabName.Workers,
    text: 'Workers',
    relativePath: '/workers',
    component: DeveloperWorkersTabContainer,
  },
  {
    name: DeveloperTabName.JobsiteUsers,
    text: 'Jobsite Users',
    relativePath: '/jobsite-users',
    component: UsersTab,
  },
  {
    name: DeveloperTabName.ClientUsers,
    text: 'Client Users',
    relativePath: '/client-users',
    component: UsersTab,
  },
];

export function DeveloperContainer(): ReactElement {
  const { clientId } = useParams<{ clientId: string }>();
  const baseRoute = `/client/${clientId}`;

  const { data, loading, error, refetch } = useGetDeveloperContainerDeveloperInfoQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      developerId: clientId,
      includeArchived: false,
    },
  });

  const developer = data?.getDeveloper;
  const jobsites = developer?.jobsites?.edges?.map((e) => e.node);
  const developerJobsiteIds = jobsites?.map((j) => j.jobsiteId);

  const tabsConfig = getTabsConfig(developer);
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig, baseRoute });

  const [tabApi, setTabApi] = React.useState<DeveloperTabApi>(null);

  const { data: rolesData } = useGetRolesQuery({ fetchPolicy: 'network-only' });
  const userRoles = rolesData?.getRoles.filter(({ key }) => key !== UserRoleKey.Worker);

  let userAssignmentType: UserAssignmentType = null;
  switch (currentTab?.name) {
    case DeveloperTabName.JobsiteUsers:
      userAssignmentType = UserAssignmentType.Jobsite;
      break;
    case DeveloperTabName.ClientUsers:
      userAssignmentType = UserAssignmentType.Developer;
      break;
    default:
      break;
  }

  const tabsPageProps: DeveloperTabProps = {
    loading,
    error,
    refetchDeveloperData: refetch,
    onTabApiChange: setTabApi,
    developer,
    jobsites,
    userAssignmentType,
    userRoles,
    initialJobsiteIds: developerJobsiteIds,
    initialDeveloperIds: developer && [developer?.developerId],
  };
  return (
    <Container className="client-container">
      <NewHeader
        title={<DeveloperTitle developer={developer} />}
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          headerActions: ['back'],
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
    </Container>
  );
}
