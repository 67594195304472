import React, { useState, ReactElement } from 'react';
import { useJobsiteCreateFormSubmissionMutation } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { ArrowRightIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { useAvailableJobsiteWorkerOptions, useDependencies, useDynCtx } from 'containers/jobsiteFormSubmission/utils';
import {
  EvalContext,
  getContextSelectOptions,
  getEvalContext,
} from 'containers/jobsiteFormSubmission/tabs/FormSubmissionEdit.forms';
import {
  getDefaultValues,
  getFormInputsHook,
  getJobsiteFormSubmissionCreateInput,
} from './AddFormSubmissionModal.forms';
import { AddFormSubmissionFormData, AddFormSubmissionModalProps } from './types';
import { useJobsiteContractorsOptions, useJobsiteFormsOptions } from './utils';

export function AddFormSubmissionModal(props: AddFormSubmissionModalProps): ReactElement {
  const { form, isOpen, onCancel, onConfirm, onClosed, refetchQueries } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { currentUser: user } = React.useContext(AuthContext);

  const [submitJobsiteForm] = useJobsiteCreateFormSubmissionMutation({ refetchQueries });

  const {
    inputs: inputsExpression,
    defaultValues: defaultValuesExpression,
    dependencies: dependenciesExpressions,
  } = form?.content.modal ?? {};

  const jobsiteFormsOptions = useJobsiteFormsOptions(form?.key);
  const defaultJobsiteId = jobsiteFormsOptions?.length === 1 ? jobsiteFormsOptions[0].jobsiteId : null;

  const contractorsOptions = useJobsiteContractorsOptions({
    jobsiteId: defaultJobsiteId,
    skip:
      !(inputsExpression as string)?.includes('ctx.options.contractors') &&
      !(defaultValuesExpression as string)?.includes('ctx.options.contractors'),
  });

  const { availableJobsiteWorkerOptions } = useAvailableJobsiteWorkerOptions({
    jobsiteFormSubmission: undefined,
    jobsiteId: defaultJobsiteId,
    skip:
      !(inputsExpression as string)?.includes('ctx.options.availableJobsiteWorkers') &&
      !(defaultValuesExpression as string)?.includes('ctx.options.availableJobsiteWorkers'),
  });

  const dependencies = useDependencies(dependenciesExpressions);
  const dynCtx = useDynCtx(form?.content);

  const evalContext: EvalContext<AddFormSubmissionFormData> = React.useMemo(() => {
    return (
      dependencies &&
      getEvalContext({
        dependencies,
        dynCtx,
        user,
        jobsiteFormSubmission: undefined,
        options: getContextSelectOptions({
          jobsiteForms: jobsiteFormsOptions,
          contractors: contractorsOptions,
          availableJobsiteWorkers: availableJobsiteWorkerOptions,
        }),
      })
    );
  }, [dependencies, user, jobsiteFormsOptions, contractorsOptions, availableJobsiteWorkerOptions]);

  const defaultValues = React.useMemo(
    () => getDefaultValues({ defaultValuesExpression, evalContext }),
    [defaultValuesExpression, evalContext],
  );

  const inputs = getFormInputsHook({ form, defaultValues, evalContext });

  const onSubmit: FormOnSubmit<AddFormSubmissionFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const input = getJobsiteFormSubmissionCreateInput({
        user,
        dependencies,
        form,
        data,
        dirtyFields,
      });
      if (input) {
        const result = await submitJobsiteForm({ variables: { input } });
        AlertInstance.alert('tc', 'success', 'Success', `${form.name} created`);
        onConfirm(result.data.jobsiteCreateFormSubmission.jobsiteFormSubmission);
      }
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      afterLeave={onClosed}
      title={`Add ${form?.name}`}
      inputs={inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Continue"
      actionIcon={ArrowRightIcon}
      actionButtonWithSpinner={isSaving}
      loading={!defaultValues}
    />
  );
}
