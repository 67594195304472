import { SelectOptionElement } from '@odin-labs/components';
import { UserAssignInput, UserAssignmentType, UserRoleKey } from 'apollo/generated/client-operations';
import { DeepMap } from 'react-hook-form';
import { getUpdateInputValueFunction } from 'components/form';
import { ensureNonUndefinedFields } from 'utils';
import { EditUserRoleFormData } from './types';
import { getUserRoleType } from './utils';

export { getFormInputsHook } from './EditUserRoleModal.forms';

export const getDefaultValues = ({
  userRolesOptions,
  jobsitesOptions,
  contractorsOptions,
  developersOptions,
}: {
  userRolesOptions: SelectOptionElement<UserRoleKey>[];
  jobsitesOptions: SelectOptionElement[];
  contractorsOptions: SelectOptionElement[];
  developersOptions: SelectOptionElement[];
}): EditUserRoleFormData => ({
  userRoleKey: userRolesOptions?.length === 1 ? userRolesOptions[0] : null,
  contractorId: contractorsOptions?.length === 1 ? contractorsOptions[0] : null,
  jobsiteIds: jobsitesOptions?.length === 1 ? jobsitesOptions : null,
  developerId: developersOptions?.length === 1 ? developersOptions[0] : null,
  title: '',
});

export const getAddInput = (
  memberUserAccountId: string,
  data: EditUserRoleFormData,
  dirtyFields: DeepMap<EditUserRoleFormData, true>,
): UserAssignInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const userAssignmentType = getUserRoleType(data.userRoleKey.value);

  return ensureNonUndefinedFields<UserAssignInput>({
    memberUserAccountId,
    userAssignmentInput: {
      userRoleKey: getUpdateInputValue('userRoleKey', true),
      title: getUpdateInputValue('title'),
      userAssignmentType,
      userAssignmentObjectIds:
        userAssignmentType === UserAssignmentType.Contractor // eslint-disable-line no-nested-ternary
          ? [getUpdateInputValue('contractorId', true)]
          : userAssignmentType === UserAssignmentType.Developer
          ? [getUpdateInputValue('developerId', true)]
          : getUpdateInputValue('jobsiteIds', true),
    },
  });
};
