import React, { ReactElement, useCallback } from 'react';
import cn from 'classnames';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Alert, Modal } from '@odin-labs/components';
import { ServerFile, TransformableFile } from 'types';
import { dataURLtoBlob, useBoolean, useUpdatableState } from 'utils';
import { CheckIcon, FileSignatureIcon, SignatureIcon } from 'components/icons';
import { setRefFactory } from 'components/utils';
import { convertHeic } from 'components/filePreview';
import { DropzoneFile } from './DropzoneFile';
import { DropzoneProps, InputFile } from './types';
import { extractPdfFirstPage, PdfFile } from './utils';
import { classes } from './NewDropzone.style';

export const Dropzone = React.forwardRef(
  (props: DropzoneProps, ref: React.ForwardedRef<HTMLInputElement>): ReactElement => {
    const {
      name,
      label,
      error,
      accept,
      multiple = false,
      maxFiles = 2,
      uploadEntirePdf,
      value,
      onChange,
      sourceType = 'file',
      disabled,
      ...rest
    } = props;

    const [innerValue, setInnerValue] = useUpdatableState<InputFile[]>(value);

    const { value: isStylusModalOpen, setTrue: openStylusModal, setFalse: closeStylusModal } = useBoolean(false);
    const {
      value: isSignatureTypeModalOpen,
      setTrue: openSignatureTypeModal,
      setFalse: closeSignatureTypeModal,
    } = useBoolean(false);
    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const signatureRef = React.useRef<ReactSignatureCanvas>();

    const shouldExtractFirstPdfPage = (file: File): boolean => file.type.includes('pdf') && !uploadEntirePdf;
    const shouldTransformHeicFile = (file: File): boolean => file.type.includes('heic') || file.type.includes('heif');

    const onDrop = useCallback<DropzoneOptions['onDrop']>(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (acceptedFiles: File[], fileRejections: FileRejection[], _event: DropEvent) => {
        // if the user selected more than the allowed number of files (maxFiles)
        // then `acceptedFiles` will be empty and `fileRejections` will contain the selected files
        const droppedFiles =
          !multiple || acceptedFiles.length
            ? acceptedFiles
            : fileRejections
                .filter((fr) => fr.errors.some((err) => err.code === 'too-many-files'))
                .slice(-maxFiles) // get the latest files from the array
                .map(({ file }) => file);

        const newInnerValue = droppedFiles.map((file: File): TransformableFile => {
          return shouldExtractFirstPdfPage(file) || shouldTransformHeicFile(file)
            ? Object.assign(file, { isTransformationInProgress: true })
            : file;
        });
        setInnerValue(newInnerValue);

        const newFiles = await Promise.all(
          droppedFiles.map(async (file: File): Promise<File> => {
            if (shouldExtractFirstPdfPage(file)) {
              return extractPdfFirstPage(file);
            }
            if (shouldTransformHeicFile(file)) {
              return convertHeic(file);
            }
            return file;
          }),
        );

        if (multiple) {
          // overwrite the existing files if the total selected number of files exceeds `maxFiles`
          const existingFiles = value?.slice(0, Math.max(0, maxFiles - newFiles.length)) ?? [];
          onChange?.([...existingFiles, ...newFiles]);
        } else {
          onChange?.(newFiles);
        }
      },
      [value, onChange],
    );

    const onRemove = (file: InputFile): void => {
      const newValue = value.filter((item) => file !== item);
      onChange?.(newValue.length ? newValue : null);
    };

    const { getRootProps, getInputProps, inputRef } = useDropzone({
      onDrop,
      accept,
      multiple,
      maxFiles,
      disabled,
    });
    const hasPartialPdf = !uploadEntirePdf && innerValue?.some((file) => (file as PdfFile)?.isMultiPagePdf);
    const inputProps = getInputProps();

    const setRef = setRefFactory<HTMLInputElement>({ innerRef: inputRef, outerRef: ref });

    const { onClick, ...rootProps } = getRootProps();

    const onDropzoneAreaClick =
      sourceType !== 'file'
        ? (): void => {
            switch (sourceType) {
              case 'stylus':
                openStylusModal();
                break;
              case 'fileAndStylus':
                openSignatureTypeModal();
                break;
              default:
                break;
            }
          }
        : onClick;

    const onConfirmStylusSignature = (): void => {
      setIsFetching(true);
      const dataUrl = signatureRef.current.toDataURL();
      const blob = dataURLtoBlob(dataUrl);
      const file = new File([blob], 'signature.png', {
        type: 'image/png',
      });
      onChange?.([file]);
    };

    return (
      <div className={classes.dropzone}>
        {hasPartialPdf && (
          <Alert text="This file is a multi-page PDF: only the first page (displayed below) will be saved." />
        )}

        <div {...rootProps} onClick={onDropzoneAreaClick} className={classes.dropzoneArea({ disabled })}>
          <input {...rest} id={name} {...inputProps} ref={setRef} />
          <div className="p-4">
            {!innerValue?.length && <div className={cn(classes.dropzoneLabel, 'text-center mt-2 mb-0')}>{label}</div>}
            {!innerValue?.length && (
              <p className="text-center mt-2 mb-2 odin-text-gray-600">Drag and drop or click to upload.</p>
            )}
            {!!innerValue?.length && (
              <div className={classes.dropzoneFiles}>
                {innerValue.map((file: InputFile, index: number): ReactElement => {
                  const fileKey = (file as ServerFile)?.fileId ?? (file as File)?.name ?? '';
                  return (
                    <DropzoneFile
                      file={file}
                      key={`${fileKey}-${index}`} // eslint-disable-line react/no-array-index-key
                      onRemove={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                        event.stopPropagation();
                        onRemove(file);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {error ? <div className={cn('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div> : null}

        <Modal
          open={isSignatureTypeModalOpen}
          size="sm"
          title="Upload signature or sign with stylus"
          titleAlignment="center"
          setOpen={closeSignatureTypeModal}
          onCancel={(): void => inputRef.current?.click()}
          onAction={(): void => {
            openStylusModal();
            closeSignatureTypeModal();
          }}
          cancelText="Upload photo"
          cancelIcon={FileSignatureIcon}
          actionText="Sign with stylus"
          actionIcon={SignatureIcon}
        />
        <Modal
          open={isStylusModalOpen}
          size="sm"
          title="Sign your signature below."
          setOpen={closeStylusModal}
          onAction={(): void => {
            onConfirmStylusSignature();
            closeStylusModal();
            setIsFetching(false);
          }}
          cancelText="Cancel"
          actionText="Save"
          actionIcon={CheckIcon}
          actionButtonEnabled={!isFetching}
        >
          <div className="odin-border odin-border-gray-400 odin-border-dashed odin-rounded-sm">
            <ReactSignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            />
          </div>
        </Modal>
      </div>
    );
  },
);
