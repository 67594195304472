import cn from 'classnames';

export const classes = {
  workerPersonalInfo: cn('odin-flex', 'odin-p-5', 'odin-bg-odin-white', 'odin-shadow-odin-normal', 'odin-rounded-md'),
  editButton: cn('odin-px-3.5', 'odin-py-3', 'odin-rounded-md', 'odin-text-odin-primary', 'hover:odin-bg-gray-200'),
  editButtonIcon: cn('odin-font-normal odin-text-lg'),
  workerPersonalInfoData: cn(
    'odin-w-full',
    'odin-grid',
    'odin-gap-y-3',
    'xl:odin-grid-cols-grid-field-value-custom-1',
    'lg:odin-grid-cols-grid-field-value-2',
    'odin-grid-cols-grid-field-value-1',
  ),
  addressLabel: cn(
    'lg:odin-row-start-3 lg:odin-col-start-1',
    'xl:odin-row-start-1 xl:odin-col-start-3',
    'odin-row-span-2',
  ),
  addressValue: cn(
    'lg:odin-row-start-3 lg:odin-col-start-2',
    'xl:odin-row-start-1 xl:odin-col-start-4',
    'odin-row-span-2',
  ),
  unionAffiliationLabel: cn('lg:odin-row-start-2 lg:odin-col-start-3', 'xl:odin-row-start-1 xl:odin-col-start-5'),
  unionAffiliationValue: cn('lg:odin-row-start-2 lg:odin-col-start-4', 'xl:odin-row-start-1 xl:odin-col-start-6'),
  last4ssnLabel: cn('lg:odin-row-start-2 lg:odin-col-start-1', 'xl:odin-row-start-2 xl:odin-col-start-1'),
  last4ssnValue: cn(
    'lg:odin-row-start-2 lg:odin-col-start-2',
    'xl:odin-row-start-2 xl:odin-col-start-2',
    'data-hj-suppress',
  ),
  raceLabel: cn('lg:odin-row-start-3 lg:odin-col-start-3', 'xl:odin-row-start-2 xl:odin-col-start-5'),
  raceValue: cn('lg:odin-row-start-3 lg:odin-col-start-4', 'xl:odin-row-start-2 xl:odin-col-start-6'),
  primaryLanguageLabel: cn('lg:odin-row-start-1 lg:odin-col-start-3', 'xl:odin-row-start-3 xl:odin-col-start-1'),
  primaryLanguageValue: cn('lg:odin-row-start-1 lg:odin-col-start-4', 'xl:odin-row-start-3 xl:odin-col-start-2'),
  phoneLabel: cn('lg:odin-row-start-5 lg:odin-col-start-1', 'xl:odin-row-start-3 xl:odin-col-start-3'),
  phoneValue: cn('lg:odin-row-start-5 lg:odin-col-start-2', 'xl:odin-row-start-3 xl:odin-col-start-4'),
  genderLabel: cn('lg:odin-row-start-4 lg:odin-col-start-3', 'xl:odin-row-start-3 xl:odin-col-start-5'),
  genderValue: cn('lg:odin-row-start-4 lg:odin-col-start-4', 'xl:odin-row-start-3 xl:odin-col-start-6'),
  emailLabel: cn(
    'lg:odin-row-start-6 lg:odin-col-start-1',
    'xl:odin-row-start-4 xl:odin-col-start-3',
    'xl:odin-col-start-3',
  ),
  emailValue: cn('lg:odin-row-start-6 lg:odin-col-start-2', 'xl:odin-row-start-4 xl:odin-col-start-4'),
  veteranStatusLabel: cn('lg:odin-row-start-5 lg:odin-col-start-3', 'xl:odin-row-start-4 xl:odin-col-start-5'),
  veteranStatusValue: cn('lg:odin-row-start-5 lg:odin-col-start-4', 'xl:odin-row-start-4 xl:odin-col-start-6'),
};
