import React from 'react';
import { FormDialog } from 'dialog';
import { getTempId } from 'utils';
import { PlusIcon } from 'components/icons';
import { EditSiteAreaFormData } from 'containers/jobsiteConfiguration/types';
import { getDefaultValues, getFormInputs, getAsNumber } from './AddSiteAreaModal.forms';
import { AddSiteAreaModalProps } from './types';

export function AddSiteAreaModal(props: AddSiteAreaModalProps): React.ReactElement {
  const { isOpen, onCancel, onConfirm } = props;

  const onSubmit = (data: EditSiteAreaFormData): void => {
    const { radius, latitude, longitude, ...restData } = data;
    onConfirm?.({
      id: getTempId(),
      radius: getAsNumber(radius),
      latitude: getAsNumber(latitude),
      longitude: getAsNumber(longitude),
      ...restData,
    });
  };

  const formInputs = getFormInputs();
  const defaultValues = React.useMemo(() => getDefaultValues(), []);

  return (
    <FormDialog
      id="addSiteArea"
      open={isOpen}
      setOpen={onCancel}
      title="Add Area"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add Area"
      actionIcon={PlusIcon}
    />
  );
}
