import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, TableContainer } from '@odin-labs/components';
import { LoadingError } from 'components/loadingError';
import { paginationSizePerPage as limit } from 'utils/constants';
import { useSearchWorkersQuery } from 'apollo/generated/client-operations';
import { useBoolean } from 'utils';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { UserPlusIcon } from 'components/icons';
import { NewHeader } from 'components/header/NewHeader';
import { Container } from 'components/container';
import { AddWorkerModal } from 'containers/worker/modals';
import { NewHeaderActionsProps } from 'components/header/types';
import { WorkerSearchResult } from './types';
import { getColumns, WorkerSearchResultColumn } from './helpers/tables';

export function SearchWorkersContainer(): ReactElement {
  const history = useHistory();
  const { value: isAddWorkerModalOpen, setTrue: openAddWorkerModal, setFalse: closeAddWorkerModal } = useBoolean(false);

  const { page, query, updateUrl } = usePageQueryParams();
  const offset = page * limit;

  const { data, error, loading, refetch } = useSearchWorkersQuery({
    variables: {
      searchString: query,
      limit,
      offset,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const workers = data?.searchWorkers.results;
  const workersCount = data?.searchWorkers?.count;
  const pageCount = Math.ceil(workersCount / limit);

  const columns = React.useMemo<WorkerSearchResultColumn[]>(() => getColumns(), []);

  const headerActionsProps = React.useMemo(
    (): NewHeaderActionsProps => ({
      menuItems: [
        {
          onClick: openAddWorkerModal,
          text: 'Add worker',
          icon: UserPlusIcon,
        },
      ],
      onReloadPressed: refetch,
    }),
    [openAddWorkerModal, refetch],
  );

  if (error) {
    return <LoadingError error={error} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  const onRowClickHandler = ({ data: workerSearchResult }: { data: WorkerSearchResult }): void => {
    history.push(`/worker/${workerSearchResult.worker.workerId}`);
  };

  return (
    <Container className="search-workers-container">
      <NewHeader title="Workers" titleInfo={workersCount} actionsProps={headerActionsProps} />
      <TableContainer>
        <Table
          loading={loading}
          columns={columns}
          data={workers}
          initialState={{ pageIndex: page, pageSize: limit }}
          pageCount={pageCount}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
          noResultsText="No workers to show"
        />
      </TableContainer>
      <AddWorkerModal isOpen={isAddWorkerModalOpen} closeModal={closeAddWorkerModal} />
    </Container>
  );
}
