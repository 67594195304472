import React, { useState, ReactElement } from 'react';
import { FormOnSubmit, ModalForm } from 'components/form';
import {
  GetJobsitesContainerCurrentSessionDocument,
  useGetDevelopersQuery,
  useJobsiteCreateMutation,
} from 'apollo/generated/client-operations';
import { PlusIcon } from 'components/icons';
import { AlertInstance } from 'components/alertNotification';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getDevelopersOptions } from 'containers/jobsites/helpers/utils';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getAsNumber, getDefaultValues, getFormInputs } from './AddJobsiteModal.forms';
import { EditJobsiteFormData, AddJobsiteModalProps } from './types';

export function AddJobsiteModal(props: AddJobsiteModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm } = props;

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const { data: developersData } = useGetDevelopersQuery({ fetchPolicy: 'no-cache', skip: !isOpen });

  const [jobsiteCreate] = useJobsiteCreateMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetJobsitesContainerCurrentSessionDocument],
  });

  const onSubmit: FormOnSubmit<EditJobsiteFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setFetching(true);

    try {
      const {
        name,
        developerId,
        addressLine1,
        addressLine2,
        addressCity,
        addressState,
        addressZipCode,
        timeZone,
        latitude,
        longitude,
        startDate,
        endDate,
      } = data;

      const input = nullifyEmptyFields({
        name,
        developerId: developerId?.value,
        addressLine1,
        addressLine2,
        addressCity,
        addressState: addressState?.value,
        addressZipCode,
        timeZone: timeZone?.value,
        latitude: getAsNumber(latitude),
        longitude: getAsNumber(longitude),
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
      });
      const result = await jobsiteCreate({ variables: { input } });

      useAvailableJobsites.resetCacheOnNextCall();
      onConfirm(result?.data?.jobsiteCreate.jobsite);
      AlertInstance.alert('tc', 'success', 'Success', 'Contractor successfully created');
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setFetching(false);
      }
    }
  };

  const developersOptions = React.useMemo(() => {
    const developers = developersData?.getCurrentSession.user.developers.edges.map(({ node }) => node);
    return getDevelopersOptions(developers);
  }, [developersData]);
  const formInputs = React.useMemo(() => getFormInputs({ developersOptions }), [developersOptions]);
  const defaultValues = React.useMemo(() => getDefaultValues(), []);

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Jobsite"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Add Jobsite"
      actionIcon={PlusIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
