import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import {
  SelfOnboardingStepKey,
  JobsiteModule,
  SelfOnboardingNavigation,
  SelfOnboardingLocationState,
  SelfOnboardingState,
  SelfOnboardingLocationSearch,
  GoToStepOptions,
} from 'containers/selfOnboarding/types';
import { defaultStep, getBaseRoute, resolveSelfOnboardingStep } from 'containers/selfOnboarding/utils';
import { DocumentKey } from 'containers/worker/utils';
import { isStringEnumValue } from 'utils';
import { useCompleteSelfOnBoardingMutation, useUpdateWorkerMutation } from 'apollo/generated/client-operations';
import { getVisibleSequentialSteps } from './utils';
import { lastStep, getNoGoBackSteps, noOrderInfoSteps, whiteListedSteps } from './useSelfOnboardingNavigation.config';

type UseSelfOnboardingNavigationArgs = {
  selfOnboardingState: SelfOnboardingState;
  jobsiteModules: JobsiteModule[];
};

export function useSelfOnboardingNavigation(args: UseSelfOnboardingNavigationArgs): SelfOnboardingNavigation {
  const { selfOnboardingState, jobsiteModules } = args;
  const { worker, contractorId, shouldSelectContractor, jobsiteWorker } = selfOnboardingState ?? {};
  const { workerId, selfOnboardingCurrentStep, isAssignedToMultipleJobsites } = worker ?? {};
  const { jobsiteWorkerId } = jobsiteWorker ?? {};

  const history = useHistory();
  const location = useLocation<SelfOnboardingLocationState>();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isReview, setIsReview] = React.useState(false);
  const [updateWorker] = useUpdateWorkerMutation();
  const [completeSelfOnBoarding] = useCompleteSelfOnBoardingMutation();

  const locationState = location.state ?? {};
  const { jobsiteInvitationId, step: urlStep } = useParams<{ jobsiteInvitationId: string; step: string }>();
  const baseRoute = getBaseRoute(jobsiteInvitationId);

  const locationSearchRef = React.useRef(location.search);
  const updateQueryParams = (queryParams?: SelfOnboardingLocationSearch): void => {
    locationSearchRef.current = `?${queryString.stringify(queryParams, { skipEmptyString: true, skipNull: true })}`;
  };

  const goToStep = React.useCallback(
    (step: SelfOnboardingStepKey, options?: GoToStepOptions) => {
      const { tab, state, shouldReplace } = options ?? {};
      const pathname = [baseRoute, step === defaultStep ? '' : step, tab].filter(Boolean).join('/');
      const goTo = shouldReplace ? history.replace : history.push;
      goTo({ pathname, state, search: locationSearchRef.current });
    },
    [history, baseRoute],
  );

  const visibleSequentialSteps = React.useMemo(() => {
    return getVisibleSequentialSteps(jobsiteModules, selfOnboardingState);
  }, [jobsiteModules, isAssignedToMultipleJobsites, shouldSelectContractor]);

  const getStepIndex: SelfOnboardingNavigation['getStepIndex'] = React.useCallback(
    (step) => visibleSequentialSteps.findIndex((s) => s === step),
    [visibleSequentialSteps],
  );

  const currentStep = resolveSelfOnboardingStep(urlStep);

  // This effect handles the situations when `selfOnboardingCurrentStep` exists and
  // the user has to be redirected either to `selfOnboardingCurrentStep` or `defaultStep`
  React.useEffect(() => {
    if (isStringEnumValue(SelfOnboardingStepKey, selfOnboardingCurrentStep)) {
      if (selfOnboardingCurrentStep === lastStep) {
        setIsReview(true);
      }

      const currentStepIndex = getStepIndex(currentStep);
      const selfOnboardingCurrentStepIndex = getStepIndex(selfOnboardingCurrentStep);
      const isSelfOnboardingClosed = selfOnboardingCurrentStep === SelfOnboardingStepKey.Closed;

      if (selfOnboardingCurrentStepIndex >= 0 || isSelfOnboardingClosed) {
        const shouldGoToPersistedCurrentStep =
          currentStep === defaultStep ||
          isSelfOnboardingClosed ||
          currentStepIndex === -1 ||
          selfOnboardingCurrentStepIndex < currentStepIndex;

        if (shouldGoToPersistedCurrentStep) {
          goToStep(selfOnboardingCurrentStep);
        }
        setIsInitialized(true);
      } else if (currentStep !== defaultStep) {
        goToStep(defaultStep);
        setIsInitialized(true);
      }
    }
  }, [selfOnboardingCurrentStep]);

  const isCurrentStepWhiteListed = (): boolean => {
    return whiteListedSteps.some((step) => {
      return typeof step === 'string'
        ? step === currentStep
        : step.key === currentStep && step.isWhiteListed(selfOnboardingState);
    });
  };

  // This effect handles the situations when the user has to be redirected to ContractorSelection step or `defaultStep`
  React.useEffect(() => {
    if (currentStep !== defaultStep) {
      if (
        shouldSelectContractor &&
        !contractorId &&
        (currentStep !== SelfOnboardingStepKey.ContractorSelection || locationSearchRef.current)
      ) {
        updateQueryParams();
        goToStep(SelfOnboardingStepKey.ContractorSelection);
      }
      // if current step is not defaultStep and there is no current worker then go to defaultStep
      else if (
        worker === null && // session query completed with no current worker
        !isCurrentStepWhiteListed()
      ) {
        goToStep(defaultStep);
      }
    }
    if (
      worker !== undefined && // session query completed
      (!isStringEnumValue(SelfOnboardingStepKey, selfOnboardingCurrentStep) ||
        currentStep === selfOnboardingCurrentStep)
    ) {
      setIsInitialized(true);
    }
  }, [currentStep, worker]);

  const goToLastStep: SelfOnboardingNavigation['goToLastStep'] = React.useCallback(
    async (tab) => {
      goToStep(lastStep, { tab });
      if (!isReview) {
        setIsReview(true);
        if (workerId) {
          await updateWorker({ variables: { workerId, workerInput: { selfOnboardingCurrentStep: lastStep } } });
          await completeSelfOnBoarding({ variables: { jobsiteWorkerId } });
        }
      }
    },
    [goToStep, isReview, workerId],
  );

  const getNextStep: SelfOnboardingNavigation['getNextStep'] = React.useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);
    return visibleSequentialSteps[currentStepIndex + 1];
  }, [visibleSequentialSteps, currentStep, getStepIndex]);

  const goToNextStep = React.useCallback(async () => {
    const currentStepIndex = getStepIndex(currentStep);
    const nextStep = isReview ? null : visibleSequentialSteps[currentStepIndex + 1];

    goToStep(
      nextStep ?? lastStep,
      isReview && isStringEnumValue(DocumentKey, currentStep) ? { tab: 'documents' } : null,
    );

    if (nextStep) {
      if (nextStep === lastStep) {
        setIsReview(true);
      }
      if (workerId) {
        await updateWorker({ variables: { workerId, workerInput: { selfOnboardingCurrentStep: nextStep } } });
        if (nextStep === lastStep) {
          await completeSelfOnBoarding({ variables: { jobsiteWorkerId } });
        }
      }
    }
  }, [worker, isReview, currentStep, getStepIndex, goToStep, visibleSequentialSteps]);

  const goToPreviousStep = React.useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);
    const prevStep =
      currentStep === SelfOnboardingStepKey.ConfirmMobileDevice
        ? SelfOnboardingStepKey.VerifyMobileDevice
        : visibleSequentialSteps[currentStepIndex - 1];
    goToStep(prevStep ?? SelfOnboardingStepKey.BasicInfo);
  }, [currentStep, getStepIndex, goToStep, visibleSequentialSteps]);

  const getStepNumberInfo = (): { stepsCount: number; currentStep: number } => {
    const orderedSteps = visibleSequentialSteps.filter((step) => !noOrderInfoSteps.includes(step));
    const currentStepIndex = orderedSteps.findIndex((step) => step === currentStep);
    if (currentStepIndex >= 0) {
      return {
        stepsCount: orderedSteps.length,
        currentStep: currentStepIndex + 1,
      };
    }
    return null;
  };

  const noGoBackSteps = React.useMemo(() => {
    return getNoGoBackSteps(selfOnboardingState);
  }, [shouldSelectContractor]);

  const canGoToPreviousStep: SelfOnboardingNavigation['canGoToPreviousStep'] = React.useCallback(
    (step) => !noGoBackSteps.includes(step),
    [noGoBackSteps],
  );

  return React.useMemo<SelfOnboardingNavigation>(
    () => ({
      loading: !isInitialized,
      currentStep,
      state: { ...locationState, isReview },
      goToStep,
      goToNextStep,
      canGoToPreviousStep,
      goToPreviousStep,
      goToLastStep,
      getStepNumberInfo,
      getStepIndex,
      getNextStep,
      updateQueryParams,
    }),
    [
      isInitialized,
      currentStep,
      locationState,
      isReview,
      goToStep,
      goToNextStep,
      canGoToPreviousStep,
      goToPreviousStep,
      goToLastStep,
      getStepNumberInfo,
      getStepIndex,
      getNextStep,
    ],
  );
}
