import {
  GetDevelopersContainerCurrentSessionDocument,
  useDeveloperCreateMutation,
} from 'apollo/generated/client-operations';
import { PlusIcon } from 'components/icons';
import { AlertInstance } from 'components/alertNotification';
import { FormOnSubmit, ModalForm } from 'components/form';
import React, { ReactElement, useState } from 'react';
import { isEmpty, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getAddInput } from './AddDeveloperModal.forms';
import { getDefaultValues, getFormInputs } from './EditDeveloperModal.forms';
import { AddDeveloperModalProps, EditDeveloperFormData } from './types';

export function AddDeveloperModal(props: AddDeveloperModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, existingOrganization } = props;
  const { organizationId } = existingOrganization ?? {};

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const [developerCreate] = useDeveloperCreateMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetDevelopersContainerCurrentSessionDocument],
  });

  const onSubmit: FormOnSubmit<EditDeveloperFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isFetching) return;
    setFetching(true);
    try {
      if (isEmpty(dirtyFields) && !existingOrganization) return;
      const input = getAddInput(organizationId, data, dirtyFields);
      const { data: createData } = await developerCreate({ variables: { input } });

      onConfirm(createData?.developerCreate?.developer);
      AlertInstance.alert('tc', 'success', 'Success', 'Client successfully created');
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setFetching(false);
    }
  };

  const formInputs = React.useMemo(() => getFormInputs(!!existingOrganization), [props]);
  const defaultValues = React.useMemo(
    () => getDefaultValues({ organization: existingOrganization }),
    [existingOrganization],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Client"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Add Client"
      actionIcon={PlusIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
