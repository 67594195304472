import React from 'react';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { UpdateWorkerMutationVariables } from 'apollo/generated/client-operations';
import {
  FormInputTypes,
  TypedFormInputs,
  getUpdateInputValueFunction,
  GridColSpan,
  UseInputs,
  UseFormMethods,
} from 'components/form';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { ensureNonUndefinedFields } from 'utils';
import {
  toFancySelectOptions,
  statesOptions,
  genderOptions as genderOptionsValues,
  jobTitleOptions as jobTitleOptionsValues,
  primaryLanguageOptions as primaryLanguageOptionsValues,
  raceOptions as raceOptionsValues,
  tradeOptions as tradeOptionsValues,
  unionAffiliationOptions as unionAffiliationOptionsValues,
  veteranOptions as veteranOptionsValues,
} from 'utils/constants';
import { zipCodeValidation } from 'utils/validation';
import { languageOptions } from 'containers/selfOnboarding/utils';
import { SelfOnboardingProfileFormData, SelfOnboardingState } from 'containers/selfOnboarding/types';
import { citizenshipStatusOptions } from 'containers/worker/helpers/utils';
import { MapMarkerAltIcon } from 'components/icons';

export { statesOptions };
export const unionAffiliationOptions = toFancySelectOptions(unionAffiliationOptionsValues);
export const jobTitleOptions = toFancySelectOptions(jobTitleOptionsValues);
export const tradeOptions = toFancySelectOptions(tradeOptionsValues);
export const primaryLanguageOptions = toFancySelectOptions(primaryLanguageOptionsValues);
export const genderOptions = toFancySelectOptions(genderOptionsValues);
export const raceOptions = toFancySelectOptions(raceOptionsValues);
export const veteranOptions = toFancySelectOptions(veteranOptionsValues);

const getFormInputs = (): TypedFormInputs<SelfOnboardingProfileFormData> => {
  return {
    addressLine1: {
      element: FormInputTypes.NewPlacesAutocomplete,
      label: 'Address',
      elementProps: {
        icon: MapMarkerAltIcon,
        onCommit: fillAddressDetails,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    addressLine2: {
      element: FormInputTypes.OdinField,
      label: 'Address line 2',
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    addressCity: {
      element: FormInputTypes.OdinField,
      label: 'City',
      layout: ({ visibleFields }): string[] => [
        GridColSpan.SpanFull,
        visibleFields.includes('addressZipCode') ? GridColSpan.SmSpan4 : GridColSpan.SmSpan6,
      ],
    },
    addressState: {
      element: FormInputTypes.OdinSelect,
      label: 'State',
      elementProps: {
        placeholder: '',
        options: statesOptions,
      },
      layout: ({ visibleFields }): string[] => [
        GridColSpan.SpanFull,
        visibleFields.includes('addressZipCode') ? GridColSpan.SmSpan4 : GridColSpan.SmSpan6,
      ],
    },
    addressZipCode: {
      element: FormInputTypes.OdinField,
      label: 'Zip code',
      elementProps: {
        fieldType: 'zipcode',
      },
      validation: { pattern: zipCodeValidation },
      layout: ({ visibleFields }): string[] => [
        GridColSpan.SpanFull,
        visibleFields.includes('addressState') ? GridColSpan.SmSpan4 : GridColSpan.SmSpan6,
      ],
    },
    primaryLanguage: {
      element: FormInputTypes.OdinSelect,
      label: 'Primary language',
      elementProps: {
        options: primaryLanguageOptions,
      },
      validation: { required: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    citizenshipStatus: {
      element: FormInputTypes.OdinSelect,
      label: 'Citizenship status',
      elementProps: {
        options: citizenshipStatusOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    race: {
      element: FormInputTypes.OdinSelect,
      label: 'Race',
      elementProps: {
        options: raceOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    gender: {
      element: FormInputTypes.OdinSelect,
      label: 'Gender',
      elementProps: {
        options: genderOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    isVeteran: {
      element: FormInputTypes.OdinSelect,
      label: 'Veteran status',
      elementProps: {
        options: veteranOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    trade: {
      element: FormInputTypes.OdinSelect,
      label: 'Trade',
      elementProps: {
        options: tradeOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    jobTitle: {
      element: FormInputTypes.OdinSelect,
      label: 'Job Title',
      elementProps: {
        options: jobTitleOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    unionAffiliation: {
      element: FormInputTypes.OdinSelect,
      label: 'Union Affiliation',
      elementProps: {
        isCreateable: false,
        options: unionAffiliationOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};

const getPrimaryLanguage = (language: string): SelectOptionElement => {
  const languageName = languageOptions.find((opt) => opt.value === language).label ?? 'English';
  return primaryLanguageOptions.find((opt) => languageName.startsWith(opt.value)) ?? null;
};

export const getFormInputsHook =
  (args: { language: string }): UseInputs<SelfOnboardingProfileFormData> =>
  ({ setValue }: UseFormMethods<SelfOnboardingProfileFormData>): TypedFormInputs<SelfOnboardingProfileFormData> => {
    const { language } = args;

    useDidUpdateEffect(() => {
      const primaryLanguage = getPrimaryLanguage(language);
      setValue('primaryLanguage', primaryLanguage, { shouldDirty: true });
    }, [language]);

    return React.useMemo(() => getFormInputs(), []);
  };

export const getDefaultValues = (
  profile: SelfOnboardingProfileFormData,
  language: string,
): SelfOnboardingProfileFormData => {
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    primaryLanguage,
    citizenshipStatus,
    race,
    gender,
    isVeteran,
    trade,
    jobTitle,
    unionAffiliation,
  } = profile ?? {};

  return {
    addressLine1: addressLine1 ?? '',
    addressLine2: addressLine2 ?? '',
    addressCity: addressCity ?? '',
    addressState: addressState ?? null,
    addressZipCode: addressZipCode ?? '',
    primaryLanguage: primaryLanguage ?? getPrimaryLanguage(language),
    citizenshipStatus: citizenshipStatus ?? null,
    race: race ?? null,
    gender: gender ?? null,
    isVeteran: isVeteran ?? null,
    trade: trade ?? null,
    jobTitle: jobTitle ?? null,
    unionAffiliation: unionAffiliation ?? null,
  };
};

export type SelfOnboardingProfileUpdateInput = Pick<UpdateWorkerMutationVariables, 'workerId'> & {
  workerInput: Pick<
    Required<UpdateWorkerMutationVariables['workerInput']>,
    | 'addressLine1'
    | 'addressLine2'
    | 'addressCity'
    | 'addressState'
    | 'addressZipCode'
    | 'primaryLanguage'
    | 'citizenshipStatus'
    | 'race'
    | 'gender'
    | 'isVeteran'
    | 'trade'
    | 'jobTitle'
    | 'unionAffiliation'
  >;
};

export const getUpdateInput = (
  worker: SelfOnboardingState['worker'],
  data: SelfOnboardingProfileFormData,
  dirtyFields: DeepMap<SelfOnboardingProfileFormData, true>,
): SelfOnboardingProfileUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const isVeteranInputValue = dirtyFields.isVeteran ? data.isVeteran?.value === 'Veteran' : undefined;

  return ensureNonUndefinedFields<SelfOnboardingProfileUpdateInput>({
    workerId: worker.workerId,
    workerInput: {
      addressLine1: getUpdateInputValue('addressLine1'),
      addressLine2: getUpdateInputValue('addressLine2'),
      addressCity: getUpdateInputValue('addressCity'),
      addressState: getUpdateInputValue('addressState'),
      addressZipCode: getUpdateInputValue('addressZipCode'),
      primaryLanguage: getUpdateInputValue('primaryLanguage'),
      citizenshipStatus: getUpdateInputValue('citizenshipStatus'),
      race: getUpdateInputValue('race'),
      gender: getUpdateInputValue('gender'),
      isVeteran: isVeteranInputValue,
      trade: getUpdateInputValue('trade'),
      jobTitle: getUpdateInputValue('jobTitle'),
      unionAffiliation: getUpdateInputValue('unionAffiliation'),
    },
  });
};
