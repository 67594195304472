import React, { ReactElement } from 'react';
import { NewDropdownButton } from '@odin-labs/components';
import type { NewMenuItemProps } from '@odin-labs/components';
import { ensureNonEmptyItems, useBoolean } from 'utils';
import { CommentAltPlusIcon, FilePlusIcon, IdBadgeIcon, ToolboxIcon, UsersMedicalIcon } from 'components/icons';
import { AssignWorkerModal } from 'containers/worker/modals';
import { WorkerHeaderAddDropdownProps } from './types';

export function WorkerHeaderAddDropdown({ worker, workerData }: WorkerHeaderAddDropdownProps): ReactElement {
  const { jobsiteWorkers } = workerData ?? {};
  const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false);

  const onAddDocumentClick = (): void => openModal();
  const onAddBadgeClick = (): void => undefined;
  const onAddWorkerToJobsiteClick = (): void => undefined;
  const onAddCommentClick = (): void => undefined;
  const onAddEmergencyContactClick = (): void => undefined;

  const menuItems = ensureNonEmptyItems<NewMenuItemProps>([
    {
      onClick: onAddDocumentClick,
      text: 'Document',
      icon: FilePlusIcon,
      theme: 'secondary',
    },
    {
      onClick: onAddBadgeClick,
      text: 'Badge',
      icon: IdBadgeIcon,
      theme: 'secondary',
    },
    !worker?.isArchived && {
      onClick: onAddWorkerToJobsiteClick,
      text: 'Worker to Jobsite',
      icon: ToolboxIcon,
      theme: 'secondary',
    },
    {
      onClick: onAddCommentClick,
      text: 'Comment',
      icon: CommentAltPlusIcon,
      theme: 'secondary',
    },
    {
      onClick: onAddEmergencyContactClick,
      text: 'Emergency Contact',
      icon: UsersMedicalIcon,
      theme: 'secondary',
    },
  ]);

  return (
    <div className="odin-ml-5">
      <NewDropdownButton text="Add" menuItems={menuItems} icon={FilePlusIcon} />
      <AssignWorkerModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        selectedWorker={worker}
        jobsiteWorkers={jobsiteWorkers}
      />
    </div>
  );
}
