import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { TrashIcon } from 'components/icons';
import { DeleteFormSubmissionDocumentModalProps } from './types';

export function DeleteFormSubmissionDocumentModal(props: DeleteFormSubmissionDocumentModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, document } = props;

  const onAction = (): void => {
    onConfirm?.(document);
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      subtitle="Are you sure you want to delete this attachment?"
      titleAlignment="center"
      onAction={onAction}
      actionText="Delete Attachment"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
    />
  );
}
