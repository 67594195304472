import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, NewMenuItemProps, Table, TableContainer, TableProps } from '@odin-labs/components';
import { StateAbbreviation, useGetJobsitesContainerCurrentSessionQuery } from 'apollo/generated/client-operations';
import { ensureNonEmptyItems, useBoolean } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { PlusIcon } from 'components/icons';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';
import { Container } from 'components/container';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { AddJobsiteModal } from './modals/AddJobsiteModal';
import { getColumns, getFilterItems, JobsiteColumn } from './helpers/tables';
import { Jobsite, JobsitesFilters } from './types';

const jobsitesProfileUrl = '/jobsite/';

export function JobsitesContainer(): ReactElement {
  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);
  const {
    value: isAddJobsiteModalOpen,
    setTrue: openAddJobsiteModal,
    setFalse: closeAddJobsiteModal,
  } = useBoolean(false);

  const { page, developerIds, cities, states, search, updateUrl, loading: isUrlLoading } = usePageQueryParams();
  const offset = page * limit;

  const statesValues = states?.length ? Object.values(StateAbbreviation).filter((s) => states.includes(s)) : null;

  const { data, loading, error, refetch } = useGetJobsitesContainerCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    skip: isUrlLoading,
    notifyOnNetworkStatusChange: true,
    variables: {
      userJobsitesInput: {
        includeAllJobsites: true,
        developerIds,
        cities,
        states: statesValues,
        search,
        paginationInput: { limit, offset },
      },
    },
  });

  const userData = data?.getCurrentSession.user;
  const filtersOptions = userData?.jobsites.filtersOptions;
  const jobsites = userData?.jobsites.edges.map(({ node }) => node);
  const jobsitesCount = userData?.jobsites.count;
  const pageCount = Math.ceil(jobsitesCount / limit);

  const columns = React.useMemo<JobsiteColumn[]>(() => getColumns(), []);
  const filterItems = React.useMemo(
    () => getFilterItems({ filtersOptions, developerIds, cities, states, search }),
    [jobsites, developerIds, search],
  );

  if (error) {
    return <LoadingError error={error} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<JobsitesFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler: TableProps<Jobsite>['onRowClick'] = ({ data: jobsite, event }): void => {
    const { metaKey, ctrlKey } = (event as React.KeyboardEvent) ?? {};
    if (metaKey || ctrlKey) {
      window.open(`${jobsitesProfileUrl}${jobsite.jobsiteId}`, '_blank');
    } else {
      history.push(`${jobsitesProfileUrl}${jobsite.jobsiteId}`);
    }
  };

  const menuItems = ensureNonEmptyItems<NewMenuItemProps>([
    user.isAllowed(to.addJobsites) && {
      text: 'Add Jobsite',
      icon: PlusIcon,
      onClick: openAddJobsiteModal,
    },
  ]);

  return (
    <Container className="jobsites-container">
      <NewHeader title="Jobsites" titleInfo={jobsitesCount} actionsProps={{ menuItems, onReloadPressed: refetch }} />
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={jobsites}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
      <AddJobsiteModal
        isOpen={isAddJobsiteModalOpen}
        onCancel={closeAddJobsiteModal}
        onConfirm={closeAddJobsiteModal}
      />
    </Container>
  );
}
