import cn from 'classnames';
import { getCurrentDomain } from 'utils';

const environments = ['prod', 'demo', 'stg', 'qa', 'dev', 'local'] as const;
type Env = typeof environments[number];

export const getEnv = (): Env => {
  const domain = getCurrentDomain();
  return environments.find((env) => domain.includes(`.${env}.`)) ?? 'prod';
};

const getItemClassesFn =
  (
    colorClass: string = cn('odin-text-white'),
    bgColor: string = cn('odin-bg-odin-two-tone'),
    bgHoverClass: string = cn('hover:odin-bg-odin-two-tone'),
  ) =>
  (isActive: boolean): string =>
    cn(
      'odin-group odin-flex odin-items-center odin-rounded-md',
      colorClass,
      isActive
        ? bgColor
        : [
            'odin-opacity-60',
            'hover:odin-opacity-100',
            bgHoverClass,
            'hover:odin-transition-all hover:odin-duration-300',
          ],
    );

export const getClasses = (): { background: string; item: (isActive: boolean) => string } => {
  const env = getEnv();

  switch (env) {
    case 'demo':
      return {
        background: cn('odin-bg-odin-env-demo'),
        item: getItemClassesFn(),
      };
    case 'stg':
      return {
        background: cn('odin-bg-odin-env-stg'),
        item: getItemClassesFn(),
      };
    case 'qa':
      return {
        background: cn('odin-bg-odin-env-qa'),
        item: getItemClassesFn(),
      };
    case 'dev':
      return {
        background: cn('odin-bg-odin-env-dev'),
        item: getItemClassesFn(),
      };
    case 'local':
      return {
        background: cn('odin-bg-odin-env-local'),
        item: getItemClassesFn(),
      };
    default:
      return {
        background: cn('odin-bg-odin-env-prod'),
        item: getItemClassesFn(),
      };
  }
};
