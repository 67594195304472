import React from 'react';
import cn from 'classnames';
import { BadgeColor, getFaIcon, Icon } from '@odin-labs/components';
import { JobsiteAccessEvent, JobsiteAccessEventStatus } from 'apollo/generated/client-operations';
import {
  faArrowToLeft,
  faArrowToRight,
  fasMoonStars,
  DoorOpenIcon,
  MagicIcon,
  MobileIcon,
  QrcodeIcon,
} from 'components/icons';

export const statusText: Record<JobsiteAccessEventStatus, string> = {
  Admit: 'Admitted',
  Reject: 'Rejected',
  archived: 'Archived',
};
export const statusColor: Record<JobsiteAccessEventStatus, BadgeColor> = {
  Admit: 'green',
  Reject: 'red',
  archived: 'gray',
};

type Direction = 'inbound' | 'outbound';
const directionText: Record<Direction, string> = {
  inbound: 'Inbound',
  outbound: 'Outbound',
};

const directionIcon: Record<string, Icon> = {
  inbound: getFaIcon({ icon: faArrowToRight, className: 'odin-text-odin-primary' }),
  outbound: getFaIcon({ icon: faArrowToLeft, className: 'odin-text-gray-600' }),
};

const methodIcon: Record<string, Icon> = {
  odin_mobile_checkin: MobileIcon,
  ccure_swipe_event: DoorOpenIcon,
  qrcode: QrcodeIcon,
  magic: MagicIcon,
};

const classes = {
  defaultText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  jobsite: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-medium'),
  date: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  supportingText: cn('odin-text-gray-600 odin-text-xs odin-leading-4 odin-font-normal'),
  time: cn('odin-text-gray-600 odin-text-sm odin-leading-5 odin-font-normal'),
  directionText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal odin-ml-1.5'),
  methodText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal odin-ml-1.5'),
};

export type DateTimeCellData = {
  date: Date;
  timeZoneName?: string;
  isOvernight?: boolean;
};

export function JobsiteCell({ value }: { value: string }): React.ReactElement {
  return <div className={classes.jobsite}>{value}</div>;
}

const OvernightIcon = getFaIcon({ icon: fasMoonStars, title: 'Overnight session' });
export function DateTimeCell({ value }: { value: DateTimeCellData }): React.ReactElement {
  return (
    <div className="odin-flex odin-items-baseline odin-gap-x-1 md:odin-block">
      <div className={classes.date}>
        {value?.date?.toLocaleDateString()}
        {value?.isOvernight && <OvernightIcon className="odin-ml-3 odin-text-odin-primary" />}
      </div>
      <div className={classes.supportingText}>
        {value?.date?.toLocaleTimeString()} {value?.timeZoneName}
      </div>
    </div>
  );
}
export function DirectionCell({ value }: { value: Direction }): React.ReactElement {
  const DirectionIcon = directionIcon[value];
  return (
    <div>
      <DirectionIcon className="odin-text-base odin-font-light" />
      <span className={classes.directionText}>{directionText[value]}</span>
    </div>
  );
}

export function MethodCell({ value }: { value: JobsiteAccessEvent }): React.ReactElement {
  const MethodIcon = methodIcon[value.eventType];
  return (
    <div>
      <MethodIcon className="odin-text-odin-primary odin-text-base odin-font-light" />
      <span className={classes.directionText}>{value.gateway}</span>
    </div>
  );
}

export function JobsiteAccessDefaultCell({ value }: { value: string }): React.ReactElement {
  return <span className={classes.defaultText}>{value}</span>;
}
