import { getFaIcon, SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { MapMarkerAltIcon } from 'components/icons';
import { zipCodeValidation } from 'utils/validation';
import { statesOptions, timeZonesOptions } from 'utils/constants';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { EditJobsiteFormData } from './types';

export const getAsNumber = (value: string | number): number => {
  if (!value) return null;
  if (typeof value === 'number') return value;
  return Number(value.replace('\u00B0', ''));
};

export const getFormInputs = ({
  developersOptions,
}: {
  developersOptions: SelectOptionElement[];
}): TypedFormInputs<EditJobsiteFormData> => ({
  name: {
    element: FormInputTypes.OdinField,
    label: 'Name',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  developerId: {
    element: FormInputTypes.OdinSelect,
    label: 'Client',
    elementProps: {
      options: developersOptions,
      icon: getFaIcon({ icon: ['fal', 'building-columns'] }),
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine1: {
    element: FormInputTypes.NewPlacesAutocomplete,
    label: 'Address',
    elementProps: {
      placeholder: 'Address',
      icon: MapMarkerAltIcon,
      onCommit: fillAddressDetails,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine2: {
    element: FormInputTypes.OdinField,
    label: 'Address, Line 2',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressCity: {
    element: FormInputTypes.OdinField,
    label: 'City',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressState: {
    element: FormInputTypes.OdinSelect,
    label: 'State',
    elementProps: {
      options: statesOptions,
    },
    validation: { required: true },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  addressZipCode: {
    element: FormInputTypes.OdinField,
    label: 'ZIP Code',
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
    elementProps: {
      fieldType: 'zipcode',
    },
    validation: {
      required: true,
      pattern: zipCodeValidation,
    },
  },
  timeZone: {
    element: FormInputTypes.OdinSelect,
    label: 'Timezone',
    elementProps: {
      options: timeZonesOptions,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  latitude: {
    element: FormInputTypes.OdinField,
    label: 'Latitude',
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: true },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  longitude: {
    element: FormInputTypes.OdinField,
    label: 'Longitude',
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: true },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  startDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'Start Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  endDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'End Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
});
