import React, { ReactElement } from 'react';
import { Modal } from '@odin-labs/components';
import { TrashIcon } from 'components/icons';
import { DeleteAnnouncementModalProps } from 'containers/announcements/types';

export function DeleteAnnouncementModal(props: DeleteAnnouncementModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, announcement } = props;

  const onAction = (): void => {
    onConfirm?.(announcement);
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title={`Delete announcement with subject: ${announcement?.subject}?`}
      titleAlignment="center"
      textAlignment="center"
      onAction={onAction}
      actionText="Delete announcement"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
    />
  );
}
