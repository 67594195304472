import React, { useState, ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { PlusIcon } from 'components/icons';
import { ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import {
  GetContractorsContainerCurrentSessionDocument,
  useContractorCreateMutation,
} from 'apollo/generated/client-operations';
import { getDefaultValues, getFormInputs } from './AddContractorModal.forms';
import { EditContractorFormData, AddContractorModalProps } from './types';

export function AddContractorModal(props: AddContractorModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, existingOrganization } = props;
  const { organizationId: existingOrganizationId } = existingOrganization ?? {};

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const [contractorCreate] = useContractorCreateMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      onConfirm(data?.contractorCreate.contractor);
      AlertInstance.alert('tc', 'success', 'Success', 'Contractor successfully created');
      if (isMounted()) {
        setFetching(false);
      }
    },
    onError: (responseError: ApolloError) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    refetchQueries: [GetContractorsContainerCurrentSessionDocument],
  });

  const onSubmit = (data: EditContractorFormData): void => {
    if (isFetching) {
      return;
    }

    setFetching(true);

    const {
      // organization
      name,
      legalName,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZipCode,

      // billing contact
      contactFirstName,
      contactLastName,
      contactEmail,
      contactPhoneNumber,

      websiteUrl,
      description,
    } = nullifyEmptyFields(data);

    contractorCreate({
      variables: {
        input: {
          organizationId: existingOrganizationId ?? undefined,
          organizationInput: existingOrganization
            ? undefined
            : {
                name,
                legalName,
                addressLine1,
                addressLine2,
                addressCity,
                addressState: addressState?.value,
                addressZipCode,
              },
          billingContact: {
            firstName: contactFirstName,
            lastName: contactLastName,
            email: contactEmail,
            phoneNumber: contactPhoneNumber,
          },
          websiteUrl,
          description,
        },
      },
    });
  };

  const formInputs = React.useMemo(() => getFormInputs(props), [props]);
  const defaultValues = React.useMemo(() => getDefaultValues(props), [props]);

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Contractor"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Add Contractor"
      actionIcon={PlusIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
