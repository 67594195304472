import React, { ReactElement } from 'react';
import { ArrowRightIcon } from 'components/icons';
import { SequencedModalForm } from 'components/form/SequencedModalForm';
import { CreateAnnouncementModalContainerProps, DraftAnnouncementFormData } from 'containers/announcements/types';
import { getDefaultValues, getFormInputs } from './DraftAnnouncementModalContainer.forms';

export function DraftAnnouncementModalContainer({
  announcement,
  updateAnnouncement,
  onBack,
  onCancel,
  onAction,
}: CreateAnnouncementModalContainerProps): ReactElement {
  const onSubmit = (data: DraftAnnouncementFormData): void => {
    updateAnnouncement(data);
    onAction();
  };

  const formInputs = React.useMemo(() => getFormInputs(announcement), [announcement]);
  const defaultValues = getDefaultValues(announcement);

  return (
    <SequencedModalForm
      open
      setOpen={onCancel}
      onBack={onBack}
      title="Draft Announcement"
      actionText="Continue"
      showBackButton
      actionIcon={ArrowRightIcon}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
    />
  );
}
