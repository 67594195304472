import React from 'react';
import cn from 'classnames';
import { Badge } from '@odin-labs/components';
import { getDateTimeMoment } from 'utils/dates';
import { BriefcaseIcon, WrenchIcon } from 'components/icons';
import {
  FormSubmissionTitleDefinition,
  FormSubmissionTitleItemProps,
  JobsiteFormSubmission,
} from 'containers/jobsiteFormSubmission/types';

export const classes = {
  titleSkeleton: cn(
    'odin-text-transparent odin-text-2xl',
    'odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
  ),
};

function FormSubmissionTitleItem({ icon: Icon, text }: FormSubmissionTitleItemProps): React.ReactElement {
  if (!text) return null;
  return (
    <span className="odin-text-sm">
      <Icon className="odin-text-base odin-text-odin-primary odin-mr-2" />
      {text}
    </span>
  );
}

export function FormSubmissionTitle({
  jobsiteFormSubmission,
  titleConfig,
}: {
  jobsiteFormSubmission: JobsiteFormSubmission;
  titleConfig: FormSubmissionTitleDefinition;
}): React.ReactElement {
  const { text, badges, items } = titleConfig;
  const { jobsiteForm, startAt, timeZone } = jobsiteFormSubmission ?? {};
  const { jobsite } = jobsiteForm ?? {};
  const { contractor } = jobsiteFormSubmission?.jobsiteContractors?.edges[0]?.node.jobsiteContractor ?? {};
  const jobsiteName = jobsite?.name;
  const contractorName = contractor?.organization.name;

  const headerText = text ?? getDateTimeMoment({ date: startAt, timeZone, isUTC: true })?.format('MMM DD, YYYY');

  return (
    <div className="odin-flex odin-gap-x-8">
      <div className="odin-flex odin-flex-col odin-justify-around odin-gap-y-2">
        <div className="odin-flex odin-items-center odin-gap-x-5">
          <h1 className="odin-text-xl odin-font-bold odin-text-odin-black odin-mb-0 sm:odin-text-2xl sm:odin-truncate">
            {headerText ?? <span className={classes.titleSkeleton}>Loading Title</span>}
          </h1>
          {!!badges?.length && (
            <div className="odin-space-x-2">
              {badges.map((badgeProps) => (
                <Badge {...badgeProps} />
              ))}
            </div>
          )}
        </div>
        <div className="odin-flex odin-gap-x-4">
          {items ? (
            items.map((item) => item.text && <FormSubmissionTitleItem key={item.text} {...item} />)
          ) : (
            <>
              <FormSubmissionTitleItem text={jobsiteName} icon={BriefcaseIcon} />
              <FormSubmissionTitleItem text={contractorName} icon={WrenchIcon} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
