import React from 'react';
import cn from 'classnames';
import { NewButton, NewButtonProps } from '@odin-labs/components';
import { Copy } from 'containers/facialRecognition/helpers/languages';
import { Localize } from 'containers/facialRecognition/types';
import { ArrowRightIcon, ForwardIcon } from 'components/icons';

export type StepActionsProps = {
  actions?: 'all' | 'continue' | 'skip';
  continueActionCopy?: Copy;
  continueActionIcon?: NewButtonProps['icon'];
  continueActionEnabled?: boolean;
  continueActionWithSpinner?: boolean;
  skipActionCopy?: Copy;
  skipActionIcon?: NewButtonProps['icon'];
  onContinue?: NewButtonProps['onClick'];
  onSkip?: NewButtonProps['onClick'];
  isReview?: boolean;
  localize: Localize;
};

export const classes = {
  container: cn('odin-my-6 odin-grid odin-grid-cols-12 odin-gap-6'),
  button: (isHidden: boolean, extraClasses: string): string =>
    cn(extraClasses, 'odin-justify-center odin-col-span-full sm:odin-col-span-6', isHidden && 'odin-hidden'),
  skipButton: 'odin-order-2 sm:odin-order-1',
  continueButton: 'sm:odin-col-start-7 odin-order-1 sm:odin-order-2',
};

export function StepActions(props: StepActionsProps): React.ReactElement {
  const {
    isReview,
    localize,

    actions = 'all',
    onContinue,
    continueActionCopy = isReview ? Copy.save : Copy.continue,
    continueActionIcon = isReview ? null : ArrowRightIcon,
    continueActionEnabled = true,
    continueActionWithSpinner = false,

    onSkip,
    skipActionCopy = isReview ? Copy.cancel : Copy.skip_for_now,
    skipActionIcon = isReview ? null : ForwardIcon,
  } = props;

  const hideContinueAction = actions === 'skip';
  const hideSkipAction = actions === 'continue';

  const continueActionText = localize(continueActionCopy);
  const skipActionText = localize(skipActionCopy);

  return (
    <div className={classes.container}>
      <NewButton
        onClick={onSkip}
        theme="white"
        size="lg"
        text={skipActionText}
        icon={skipActionIcon}
        className={classes.button(hideSkipAction, classes.skipButton)}
      />
      <NewButton
        onClick={onContinue}
        type="submit"
        size="lg"
        text={continueActionText}
        icon={continueActionIcon}
        className={classes.button(hideContinueAction, classes.continueButton)}
        disabled={!continueActionEnabled}
        withSpinner={continueActionWithSpinner}
      />
    </div>
  );
}
