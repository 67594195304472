import React, { MouseEventHandler } from 'react';
import { Icon } from '@odin-labs/components';
import { classes } from './WorkerHeaderButton.style';

type WorkerHeaderButtonProps = React.PropsWithChildren<{
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: Icon;
}>;

export function WorkerHeaderButton({
  children,
  className,
  icon: IconComponent,
  onClick,
}: WorkerHeaderButtonProps): React.ReactElement {
  return (
    <button className={classes.headerActionItemButton(className)} onClick={onClick}>
      {IconComponent && <IconComponent className="odin-font-normal odin-text-sm" />}
      {children}
    </button>
  );
}
