import React from 'react';
import cn from 'classnames';
import { EditType, MergeUnionType } from 'types';
import { Avatar, AvatarSkeleton, Field, OdinSelect } from '@odin-labs/components';
import { getInitialsForUser, getUserFullName } from 'utils';
import { ClockIcon } from 'components/icons';
import { EditableEnhancedWorker, FormSubmissionEditableWorkersProps } from './types';

export type FormSubmissionEditableWorkerProps =
  | {
      jfsWorker: EditableEnhancedWorker;
      updateValue: (jfsWorker: EditableEnhancedWorker, editType: EditType) => void;
      dropDownOptions?: FormSubmissionEditableWorkersProps['dropDownOptions'];
    }
  | { loading: true };

const classes = {
  clockIcon: cn(
    'odin-text-xs sm:odin-text-base',
    'odin-absolute odin-ml-12 sm:odin-ml-15 odin-bottom-0',
    'odin-bg-odin-success odin-text-white odin-rounded-full odin-p-1',
  ),
};

export function FormSubmissionEditableWorker(props: FormSubmissionEditableWorkerProps): React.ReactElement {
  const { loading, jfsWorker, updateValue, dropDownOptions } =
    props as MergeUnionType<FormSubmissionEditableWorkerProps>;
  const { costCodeOptions = [], typeOptions = [], levelOptions = [] } = dropDownOptions ?? {};
  const { jobsiteWorker, extraData } = jfsWorker ?? {};
  const { isOnSite, contractorWorker } = jobsiteWorker ?? {};
  const { worker } = contractorWorker ?? {};
  const { workerId, user, profilePicture } = worker ?? {};
  const { downloadUrl: imageUrl } = profilePicture ?? {};
  const { workUnits, costCode, type, level, notes } = extraData ?? {};
  const userFullName = getUserFullName(user);

  const updateState = React.useCallback(
    (extraDataValue: Partial<typeof extraData>): void =>
      updateValue(
        {
          changeType: jfsWorker.id ? 'updated' : 'created',
          ...jfsWorker,
          extraData: { ...jfsWorker.extraData, ...extraDataValue },
        },
        'update',
      ),
    [updateValue, jfsWorker],
  );

  return (
    <div
      className={`odin-gap-3 odin-grid odin-grid-cols-5 odin-p-5
      odin-border odin-border-gray-200 odin-shadow-sm odin-rounded-lg`}
    >
      <div className="odin-flex odin-justify-center odin-items-center odin-row-span-5 sm:odin-row-span-3">
        {loading ? (
          <div className="odin-ml-20 md:odin-ml-0">
            <AvatarSkeleton size="3xl" />
          </div>
        ) : (
          <label htmlFor={`workUnits-${workerId}`}>
            <div className="odin-relative odin-flex odin-justify-center odin-h-full">
              <Avatar
                className="!odin-w-12 !odin-h-12 !odin-text-lg sm:!odin-w-20 sm:!odin-h-20 sm:!odin-text-3xl"
                size="3xl"
                src={imageUrl}
                placeholder={getInitialsForUser(user)}
                objectFit="cover"
              />
              {isOnSite && <ClockIcon className={classes.clockIcon} />}
            </div>
            <div className="odin-text-base odin-font-medium odin-text-gray-900 odin-mt-3">{userFullName}</div>
          </label>
        )}
      </div>
      <div className="odin-col-span-4 sm:odin-col-span-2 odin-pl-4 sm:odin-pl-0">
        <Field
          loading={loading}
          name={`workUnits-${workerId}`}
          label="Work Units"
          value={workUnits?.toString() ?? ''}
          type="number"
          onChange={(changedValue): void => updateState({ workUnits: Number(changedValue) || null })}
        />
      </div>
      <div className="odin-col-span-4 sm:odin-col-span-2 odin-pl-4 sm:odin-pl-0">
        <OdinSelect
          loading={loading}
          name={`costCode-${workerId}`}
          label="Cost Code"
          options={costCodeOptions}
          value={costCodeOptions.find((item) => item.value === costCode) ?? null}
          onChange={(changedItem): void => updateState({ costCode: changedItem.value })}
        />
      </div>
      <div className="odin-col-span-4 sm:odin-col-span-2 odin-pl-4 sm:odin-pl-0">
        <OdinSelect
          loading={loading}
          name={`type-${workerId}`}
          label="Type"
          options={typeOptions}
          value={typeOptions.find((item) => item.value === type) ?? null}
          onChange={(changedItem): void => updateState({ type: changedItem.value })}
        />
      </div>
      <div className="odin-col-span-4 sm:odin-col-span-2 odin-pl-4 sm:odin-pl-0">
        <OdinSelect
          loading={loading}
          name={`level-${workerId}`}
          label="Level"
          options={levelOptions}
          value={levelOptions.find((item) => item.value === level) ?? null}
          onChange={(changedItem): void => updateState({ level: changedItem.value })}
        />
      </div>
      <div className="odin-col-span-4 odin-pl-4 sm:odin-pl-0">
        <Field
          loading={loading}
          name={`notes-${workerId}`}
          label="Notes"
          value={notes ?? ''}
          onChange={(changedValue): void => updateState({ notes: changedValue })}
        />
      </div>
    </div>
  );
}
