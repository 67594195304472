import React, { ReactElement } from 'react';
import { AuthContext } from 'auth';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { WorkerTabProps } from 'containers/worker/types';
import { FormSubmissionsWidget } from 'containers/forms/formSubmissionsWidget';
import { extraColumns } from './WorkerFormSubmissions.tables';

export function WorkerFormSubmissions(props: WorkerTabProps): ReactElement {
  const { worker, isLocked, onTabApiChange } = props;
  const { workerId } = worker ?? {};

  const { currentUser: user } = React.useContext(AuthContext);
  const isFeatureLocked = !user.hasPaidAccess;

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  if (isFeatureLocked) {
    return <LockedFeatureAlert />;
  }

  return (
    <FormSubmissionsWidget
      onTabApiChange={onTabApiChange}
      workerId={workerId}
      extraColumns={extraColumns}
      filters={['dateRange', 'jobsiteIds', 'formIds', 'hasAttachments']}
      navigateInNewTab
    />
  );
}
