import React from 'react';
import cn from 'classnames';
import { getFaIcon, Label, Table, TableContainer } from '@odin-labs/components';
import { useGetJobsiteAccessSessionsQuery } from 'apollo/generated/client-operations';
import { faUserHardHat } from 'components/icons';
import { useUpdateQueryStatus } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/helpers/useUpdateQueryStatus';
import { getJobsiteIncidentAccessEventsInterval } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/helpers/utils';
import { ReportSectionTitle } from 'containers/jobsiteFormSubmission/print/jobsiteSafety/components/reportSectionTitle';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { momentFormatter } from 'utils/dates';
import { getColumns, JobsiteWorkerSessionColumn } from './helpers/tables';

const UserHardHatIcon = getFaIcon({
  icon: faUserHardHat,
  className: cn('odin-text-odin-primary odin-mr-1.5'),
});

export type JobsiteReportWorkerSessionsProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
};

export function JobsiteReportWorkerSessions(props: JobsiteReportWorkerSessionsProps): React.ReactElement {
  const { jobsiteFormSubmission } = props;

  const { startMoment, endMoment } = getJobsiteIncidentAccessEventsInterval(jobsiteFormSubmission.startAt);

  const { jobsiteId } = jobsiteFormSubmission.jobsiteForm.jobsite;
  const jobsiteIds = [jobsiteId];
  // const jobsiteIds = jobsiteFormSubmission.jobsiteWorkers.edges.map(
  //   ({ node }) => node.jobsiteWorker.jobsiteContractor.jobsite.jobsiteId,
  // );
  const workerIds = jobsiteFormSubmission.jobsiteWorkers.edges.map(
    ({ node }) => node.jobsiteWorker.contractorWorker?.worker.workerId,
  );

  const { data, error, loading } = useGetJobsiteAccessSessionsQuery({
    variables: {
      userJobsiteAccessSessionsInput: {
        jobsiteIds,
        workerIds,
        startDate: startMoment?.toDate(),
        endDate: endMoment?.toDate(),
        paginationInput: {
          limit: 100,
          offset: 0,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !workerIds.length || !jobsiteId || !jobsiteFormSubmission.startAt,
  });

  useUpdateQueryStatus('jobsiteReportWorkerSessions', { loading, error });

  const workerSessions = data?.getCurrentSession.user.jobsiteAccessSessions.edges.map(({ node }) => node);

  const columns = React.useMemo<JobsiteWorkerSessionColumn[]>(() => getColumns(), []);
  const workerSessionsCount = workerSessions?.length ?? 0;
  const datesText = [startMoment.format(momentFormatter), endMoment.format(momentFormatter)].join(' - ');

  return (
    <TableContainer>
      <ReportSectionTitle title="Sessions" count={workerSessionsCount} dates={datesText}>
        <Label size="lg" text={`${workerIds.length} Workers`} icon={UserHardHatIcon} />
      </ReportSectionTitle>
      <Table loading={loading} columns={columns} data={workerSessions} remote disableGlobalFilter disableSortBy />
    </TableContainer>
  );
}
