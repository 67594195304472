import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { JobsiteWorkerOnboardingSidebarElementProps } from 'containers/workerOnboarding/types';
import * as styles from 'containers/workerOnboarding/scss/worker-onboarding.scss';
import { Icon } from '@odin-labs/components';
import { CheckIcon, ExclamationTriangleIcon, PencilIcon } from 'components/icons';

const cx = classNames.bind(styles);

export function JobsiteWorkerOnboardingSidebarElement({
  title,
  subtitle,
  icon: SidebarElementIcon,
  completed,
  skipped,
  failed,
  active,
  onClick,
}: JobsiteWorkerOnboardingSidebarElementProps): ReactElement {
  const getStatusText = (): string => {
    if (failed) {
      return 'Did not complete';
    }
    if (skipped) {
      return `Skipped - ${skipped}`;
    }
    if (completed) {
      return 'Completed';
    }
    if (active) {
      return 'In progress';
    }
    return 'Not started';
  };

  const getStatusColor = (): string => {
    if (failed) {
      return 'jobsite-worker-orientation-sidebar-element__icon--status-banned';
    }
    if (skipped) {
      return 'jobsite-worker-orientation-sidebar-element__icon--status-warning';
    }
    if (completed) {
      return 'jobsite-worker-orientation-sidebar-element__icon--status-success';
    }
    if (active) {
      return 'jobsite-worker-orientation-sidebar-element__icon--active';
    }
    return '';
  };

  const getStatusIcon = (): Icon => {
    if (failed || skipped) {
      return ExclamationTriangleIcon;
    }
    if (completed) {
      return CheckIcon;
    }
    if (active) {
      return PencilIcon;
    }
    return ExclamationTriangleIcon;
  };

  const StatusIcon = getStatusIcon();

  return (
    <NavItem
      className={cx('nav-link', 'p-0', 'border-bottom', 'jobsite-worker-orientation-sidebar-element', {
        'jobsite-worker-orientation-sidebar-element--active': active,
      })}
      onClick={onClick}
    >
      <NavLink className="mb-2 mt-1 flex-column align-items-start cursor-pointer">
        <h4 className="odin-space-x-1.5">
          <SidebarElementIcon /> <span>{title}</span>
        </h4>
        {subtitle ? <h5>{subtitle}</h5> : null}
        <div className={cx(getStatusColor(), 'odin-flex odin-items-center odin-gap-x-2')}>
          <StatusIcon /> {getStatusText()}
        </div>
      </NavLink>
    </NavItem>
  );
}
