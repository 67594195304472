import React, { ReactElement, useState } from 'react';
import {
  AppErrorCode,
  ManuallyEnterCardNumberForPrintJobMutationVariables,
  useManuallyEnterCardNumberForPrintJobMutation,
  CardPrintJob,
} from 'apollo/generated/client-operations';
import { useIsMounted } from 'utils';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { ArrowRightIcon } from 'components/icons';
import { AlertInstance } from 'components/alertNotification';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { CreateBadgeInfo } from 'containers/worker/types';
import { useProximityCardFormatOptions } from 'containers/worker/helpers/useProximityCardFormatOptions';
import { getFormInputs, getFormDefaultValues } from './EnterPrintJobCardNumberModalContent.forms';
import { ReassignBadgeState, WorkerCardAlreadyExistsErrorExtensions } from './types';

const instructions =
  'The card printer was unable to read your badge number. ' +
  'The number can typically be found in the bottom right of the badge';

export interface EnterPrintJobCardNumberModalContentProps {
  setOpen: () => Promise<void> | void;
  onAction: (reassignBadgeState?: ReassignBadgeState) => void;
  jobsiteId: string;
  cardPrintJob: CardPrintJob;
  refetchCardPrintJob: () => Promise<unknown>;
}

export function EnterPrintJobCardNumberModalContent(props: EnterPrintJobCardNumberModalContentProps): ReactElement {
  const { setOpen, onAction, cardPrintJob, refetchCardPrintJob, jobsiteId } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [manuallyEnterCardNumberForPrintJob] = useManuallyEnterCardNumberForPrintJobMutation();

  const onSubmit: FormOnSubmit<CreateBadgeInfo> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isFetching) return;
    setIsFetching(true);

    const getVariables = (options?: {
      forceCreate?: boolean;
    }): ManuallyEnterCardNumberForPrintJobMutationVariables => ({
      forceCreate: options?.forceCreate,
      cardPrintJobId: cardPrintJob.cardPrintJobId,
      cardNumber: data.proximityCardNumber?.replace(/^0+/g, ''),
      workerCardFormatId: data.proximityCardFormatId.value,
    });

    try {
      await manuallyEnterCardNumberForPrintJob({ variables: getVariables() });
      await refetchCardPrintJob();
      onAction();
    } catch (error) {
      event.preventDefault();

      const workerCardAlreadyExistsError = getGraphQLErrorByCode<WorkerCardAlreadyExistsErrorExtensions>(
        error,
        AppErrorCode.WorkerCardAlreadyExists,
      );
      if (workerCardAlreadyExistsError) {
        const { scope, workerCardInfo } = workerCardAlreadyExistsError.extensions ?? {};

        if (scope === 'onDifferentWorker') {
          onAction({
            workerCardInfo,
            reassignBadgeAction: async (): Promise<void> => {
              await manuallyEnterCardNumberForPrintJob({ variables: getVariables({ forceCreate: true }) });
              await refetchCardPrintJob();
            },
          });
        } else {
          formApi.setError('proximityCardNumber', {
            message: workerCardAlreadyExistsError.message,
            shouldFocus: true,
          });
        }
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const {
    proximityCardFormatOptions,
    defaultProximityCardFormatOption,
    error: getJobsiteCardFormatsError,
  } = useProximityCardFormatOptions(cardPrintJob.jobsite?.jobsiteId ?? jobsiteId);

  const formInputs = getFormInputs(proximityCardFormatOptions);
  const defaultValues = getFormDefaultValues(defaultProximityCardFormatOption);

  return (
    <ModalFormContent
      setOpen={setOpen}
      renderAbove={<p>{instructions}</p>}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Continue"
      actionIcon={ArrowRightIcon}
      actionButtonWithSpinner={isFetching}
      error={getJobsiteCardFormatsError}
    />
  );
}
