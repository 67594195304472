import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { localizeFactory } from 'utils/localization';
import { PdfImageData } from 'utils/pdf';
import { faArrowRight } from 'components/icons';
import { FontAwesomeIconPdf } from 'components/pdf';
import { Languages, Copy } from 'containers/contractor/helpers/languages';
import { styles } from './PreOnboardingInstructionsPdf.style';

export type PreOnboardingInstructionsPdfProps = {
  jobsiteName: string;
  contractorName: string;
  qrCodeImage: PdfImageData;
  logoImage: PdfImageData;
};

const languages: Array<keyof typeof Languages> = ['en', 'es'];
const localize = localizeFactory();

export function PreOnboardingInstructionsPdf(props: PreOnboardingInstructionsPdfProps): React.ReactElement {
  const { jobsiteName, contractorName, qrCodeImage, logoImage } = props;
  return (
    <Document>
      {languages.map((locale) => (
        <Page key={locale} size="LETTER" style={styles.page}>
          <View style={styles.header}>
            <View>{logoImage && <Image src={logoImage.data} style={styles.logo} />}</View>
            <View style={styles.headerRight}>
              <Text style={styles.jobsite}>{jobsiteName}</Text>
              <Text style={styles.contractor}>{contractorName}</Text>
            </View>
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>{localize(Copy.onboarding_instructions_title, { locale })}</Text>
            <Text style={styles.subtitle}>{localize(Copy.onboarding_instructions_subtitle, { locale })}</Text>
            <View style={styles.stepContainer}>
              <View style={styles.stepNumberHolder}>
                <Text style={styles.stepNumber}>1</Text>
              </View>
              <Text style={styles.stepText}>{localize(Copy.onboarding_instructions_step_1_text, { locale })}</Text>
            </View>
            <View style={styles.stepContainer}>
              <View style={styles.stepNumberHolder}>
                <Text style={styles.stepNumber}>2</Text>
              </View>
              <Text style={styles.stepText}>{localize(Copy.onboarding_instructions_step_2_text, { locale })}</Text>
            </View>
            <View style={styles.stepContainer}>
              <View style={styles.stepNumberHolder}>
                <Text style={styles.stepNumber}>3</Text>
              </View>
              <Text style={styles.stepText}>{localize(Copy.onboarding_instructions_step_3_text, { locale })}</Text>
            </View>
          </View>
          <View style={styles.footer}>
            <View style={styles.scanToBegin}>
              <Text>{localize(Copy.onboarding_instructions_scan_to_begin, { locale })}</Text>
              <FontAwesomeIconPdf faIcon={faArrowRight} style={styles.scanToBeginArrow} />
            </View>
            {qrCodeImage && <Image src={qrCodeImage.data} style={styles.qrCode} />}
          </View>
        </Page>
      ))}
    </Document>
  );
}
