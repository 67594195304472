import React from 'react';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router';
import { NewButton } from '@odin-labs/components';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { Logo } from 'components/logo';
import { getCurrentDomain } from 'utils';
import { getDateTimeMoment } from 'utils/dates';
import { ArrowLeftIcon } from 'components/icons';

const classes = {
  container: 'odin-flex odin-items-center odin-justify-between',
  leftContainer: 'odin-space-y-2',
  rightContainer: 'odin-space-x-7',
  backButton: 'print:odin-hidden odin--ml-1',
  text: 'odin-mb-0',
};

export type FormSubmissionReportTitleProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
};

export function FormSubmissionReportTitle(props: FormSubmissionReportTitleProps): React.ReactElement {
  const { jobsiteFormSubmission } = props;
  const { startAt, timeZone, jobsiteForm } = jobsiteFormSubmission ?? {};
  const { key: formKey } = jobsiteForm?.form ?? {};

  const qrUrl = `${getCurrentDomain()}/${formKey}/${jobsiteFormSubmission?.id}/print`;

  const reportMoment = getDateTimeMoment({ date: startAt, timeZone, isUTC: true });
  const reportDateText = reportMoment.format('MMM DD, YYYY');

  const history = useHistory();

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <NewButton
          className={classes.backButton}
          text="Back"
          icon={ArrowLeftIcon}
          theme="naked"
          onClick={(): void => history.goBack()}
        />
        <h1 className={classes.text}>{reportDateText}</h1>
      </div>
      <div className={classes.rightContainer}>
        <Logo />
        <QRCode value={qrUrl} size={72} />
      </div>
    </div>
  );
}
