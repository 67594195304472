import React from 'react';
import { useDidUpdateEffect } from '@odin-labs/components';
import { WorkerUnlockProfileType } from 'apollo/generated/client-operations';
import { ensureNonEmptyItems } from 'utils';
import { dateValidation, emailValidation, phoneNumberValidation, ssnValidation } from 'utils/validation';
import {
  FormInputTypes,
  GridColSpan,
  TypedFormInputs,
  RadioGroupFormOption,
  UseInputs,
  UseFormMethods,
} from 'components/form';
import { EnvelopeIcon } from 'components/icons';
import { UnlockProfileData } from './types';

export type ProfileUnlockTypeOption = RadioGroupFormOption<
  Omit<UnlockProfileData, 'profileUnlockType'>,
  WorkerUnlockProfileType
>;

export type UnlockProfileInputsArgs = {
  availableUnlockTypes: WorkerUnlockProfileType[];
};

export const getProfileUnlockTypeOptions = ({
  availableUnlockTypes,
}: UnlockProfileInputsArgs): ProfileUnlockTypeOption[] =>
  ensureNonEmptyItems<ProfileUnlockTypeOption>([
    availableUnlockTypes?.includes(WorkerUnlockProfileType.BirthDate) && {
      value: WorkerUnlockProfileType.BirthDate,
      label: 'Date of Birth',
      inputs: [
        {
          name: 'dateOfBirth',
          element: FormInputTypes.OdinField,
          elementProps: {
            placeholder: 'MM/DD/YYYY',
            fieldType: 'pastDate',
            showDefaultIcon: true,
          },
          validation: {
            required: true,
            pattern: dateValidation,
          },
          layout: GridColSpan.SpanFull,
        },
      ],
    },
    availableUnlockTypes?.includes(WorkerUnlockProfileType.PhoneNumber) && {
      value: WorkerUnlockProfileType.PhoneNumber,
      label: 'Phone Number',
      inputs: [
        {
          name: 'phoneNumber',
          element: FormInputTypes.OdinField,
          elementProps: {
            fieldType: 'phone',
            showDefaultIcon: true,
          },
          validation: {
            required: true,
            pattern: phoneNumberValidation,
          },
          layout: GridColSpan.SpanFull,
        },
      ],
    },
    availableUnlockTypes?.includes(WorkerUnlockProfileType.Email) && {
      value: WorkerUnlockProfileType.Email,
      label: 'Email address',
      inputs: [
        {
          name: 'emailAddress',
          element: FormInputTypes.OdinField,
          elementProps: {
            icon: EnvelopeIcon,
          },
          validation: {
            required: true,
            pattern: emailValidation,
          },
          layout: GridColSpan.SpanFull,
        },
      ],
    },
    availableUnlockTypes?.includes(WorkerUnlockProfileType.SsnLastFour) && {
      value: WorkerUnlockProfileType.SsnLastFour,
      label: 'Last Four Digits Of SSN',
      inputs: [
        {
          name: 'lastFourDigitsOfSsn',
          element: FormInputTypes.OdinField,
          elementProps: {
            fieldType: 'ssn4',
          },
          validation: {
            required: true,
            pattern: ssnValidation,
          },
          layout: GridColSpan.SpanFull,
        },
      ],
    },
  ]);

const getFormInputs = ({
  profileUnlockTypeOptions,
}: {
  profileUnlockTypeOptions: ProfileUnlockTypeOption[];
}): TypedFormInputs<UnlockProfileData> => {
  return {
    profileUnlockType: {
      element: FormInputTypes.RadioGroup,
      label: 'Please enter one of the following pieces of information to unlock this worker’s profile',
      elementProps: {
        options: profileUnlockTypeOptions,
        preventSubmitOnEnter: true,
      },
      layout: GridColSpan.SpanFull,
    },
  };
};

export const getFormInputsHook =
  (args: {
    profileUnlockTypeOptions: ProfileUnlockTypeOption[];
    onProfileUnlockTypeChange: () => void;
  }): UseInputs<UnlockProfileData> =>
  ({ watch }: UseFormMethods<UnlockProfileData>): TypedFormInputs<UnlockProfileData> => {
    const { profileUnlockTypeOptions, onProfileUnlockTypeChange } = args;
    const profileUnlockTypeOption = watch('profileUnlockType');
    const profileUnlockTypeOptionValue = profileUnlockTypeOption?.value;

    useDidUpdateEffect(() => {
      onProfileUnlockTypeChange();
    }, [profileUnlockTypeOptionValue]);

    return React.useMemo(() => getFormInputs({ profileUnlockTypeOptions }), [profileUnlockTypeOptions]);
  };

export const getFormDefaultValues = ({
  profileUnlockTypeOptions,
}: {
  profileUnlockTypeOptions: ProfileUnlockTypeOption[];
}): UnlockProfileData => ({
  profileUnlockType: profileUnlockTypeOptions.find((o) => !o.disabled) ?? null,
  dateOfBirth: null,
  phoneNumber: '',
  emailAddress: '',
  lastFourDigitsOfSsn: '',
});
