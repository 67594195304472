import { to } from 'acl';
import { AuthContext } from 'auth';
import classNames from 'classnames';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { getFormattedDate } from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { JobsiteWorker, Worker } from 'containers/worker/types';
import { NewButton } from '@odin-labs/components';
import { ExclamationTriangleIcon } from 'components/icons';

export interface JobsiteBan {
  jobsiteWorker: JobsiteWorker;
  jobsiteName: string;
  workerName: string;
}

export interface WorkerProfileBannerActionProps {
  jobsiteBan: JobsiteBan;
  openChangeWorkerAccessModal: (jobsiteWorker: JobsiteWorker) => void;
}

function WorkerProfileBannerAction(props: WorkerProfileBannerActionProps): ReactElement {
  const { jobsiteBan, openChangeWorkerAccessModal } = props;
  const { jobsiteWorker } = jobsiteBan ?? {};
  const { jobsiteId } = jobsiteWorker?.jobsiteContractor?.jobsite ?? {};
  const isSuspension = !!jobsiteWorker?.banExpiresAt;

  const { currentUser: user } = React.useContext(AuthContext);

  if (
    (isSuspension && user.isAllowed(to.unsuspendWorkers, jobsiteId)) ||
    (!isSuspension && user.isAllowed(to.unbanWorkers, jobsiteId))
  ) {
    return (
      <NewButton
        theme="white"
        size="xs"
        onClick={(): void => {
          openChangeWorkerAccessModal(jobsiteWorker);
        }}
        text={`${isSuspension ? 'Unsuspend' : 'Unban'} worker`}
      />
    );
  }

  return null;
}

export interface WorkerProfileBannerProps {
  worker: Worker;
  jobsiteBans: JobsiteBan[];
  openChangeWorkerAccessModal: (jobsiteWorker: JobsiteWorker) => void;
  openBanReasonModal: (jobsiteBan: JobsiteBan) => void;
  jobsiteDocumentConditionalPasses: string[];
  jobsiteMedicalConditionalPasses: string[];
}

export function WorkerProfileBanner({
  worker,
  jobsiteBans,
  openChangeWorkerAccessModal,
  openBanReasonModal,
  jobsiteDocumentConditionalPasses,
  jobsiteMedicalConditionalPasses,
}: WorkerProfileBannerProps): ReactElement {
  return (
    <>
      {worker?.isArchived && (
        <div className="bg-warning border border-2 h-100 p-3 text-white d-flex align-items-center">
          <ExclamationTriangleIcon className="pr-3" />
          <span className="flex-grow-1">
            <span>This worker&apos;s profile is archived</span>
          </span>
        </div>
      )}
      {jobsiteBans.map((jobsiteBan) => {
        if (
          jobsiteBan?.jobsiteWorker?.banExpiresAt &&
          moment(jobsiteBan?.jobsiteWorker?.banExpiresAt).isBefore(moment().format())
        ) {
          return null;
        }
        return (
          <div
            key={jobsiteBan?.jobsiteWorker.jobsiteWorkerId}
            className={classNames(
              {
                'bg-danger': !jobsiteBan?.jobsiteWorker?.banExpiresAt,
                'bg-warning': jobsiteBan?.jobsiteWorker?.banExpiresAt,
              },
              'border border-2 h-100 p-3 text-white d-flex align-items-center',
            )}
          >
            <ExclamationTriangleIcon className="pr-3" />
            <span className="flex-grow-1">
              {jobsiteBan?.jobsiteWorker?.banExpiresAt ? (
                <span>
                  This worker is suspended from&nbsp;
                  <span
                    className="text-decoration-underline cursor-pointer"
                    onClick={(): void => openBanReasonModal(jobsiteBan)}
                  >
                    {jobsiteBan?.jobsiteName} until{' '}
                    {getISODateTimeToFormattedDate(jobsiteBan?.jobsiteWorker?.banExpiresAt)}.
                  </span>
                </span>
              ) : (
                <span>
                  This worker is permanently banned from&nbsp;
                  <span
                    className="text-decoration-underline cursor-pointer"
                    onClick={(): void => openBanReasonModal(jobsiteBan)}
                  >
                    {jobsiteBan?.jobsiteName}.
                  </span>
                </span>
              )}
            </span>
            <WorkerProfileBannerAction
              jobsiteBan={jobsiteBan}
              openChangeWorkerAccessModal={openChangeWorkerAccessModal}
            />
          </div>
        );
      })}
      {jobsiteDocumentConditionalPasses.map((jobsiteConditionalPass) => {
        return (
          <div key={jobsiteConditionalPass} className="bg-warning border border-1 h-100 p-3 text-white">
            <ExclamationTriangleIcon className="p-3" />
            The worker has a conditional pass for documents that is valid until{' '}
            {getFormattedDate(jobsiteConditionalPass)}.
          </div>
        );
      })}
      {jobsiteMedicalConditionalPasses.map((jobsiteConditionalPass) => {
        return (
          <div key={jobsiteConditionalPass} className="bg-warning border border-1 h-100 p-3 text-white">
            <ExclamationTriangleIcon className="p-3" />
            The worker has a conditional pass for medical tests that is valid until{' '}
            {getFormattedDate(jobsiteConditionalPass)}.
          </div>
        );
      })}
    </>
  );
}
