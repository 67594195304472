import { UserRoleKey } from 'apollo/generated/client-operations';
import {
  BuildingIcon,
  BullhornIcon,
  CctvIcon,
  ChartPieIcon,
  ClipboardCheckIcon,
  CompassIcon,
  HardHatIcon,
  IdCardIcon,
  LandmarkIcon,
  SitemapIcon,
  GaugeHighIcon,
  UserHardHatIcon,
  UsersIcon,
  WrenchIcon,
} from 'components/icons';
import { EntityChangeContainer } from 'containers/entityChange';
import { ContractorsContainer } from 'containers/contractors';
import { ContractorContainer } from 'containers/contractor';
import { DashboardContainer } from 'containers/dashboard';
import { HomeContainer } from 'containers/home';
import { IssueTempBadgeContainer } from 'containers/issueTempBadge';
import { JobsitesContainer } from 'containers/jobsites';
import { JobsiteContainer } from 'containers/jobsite';
import { JobsiteConfigurationContainer } from 'containers/jobsiteConfiguration';
import { JobsiteAccessActivityContainer } from 'containers/jobsiteAccessActivity';
import { AddJobsiteContractorWorkerContainer } from 'containers/jobsiteContractorWorker';
import { AnnouncementsContainer } from 'containers/announcements';
import { AnnouncementContainer } from 'containers/announcement';
import { WorkerOnboardingContainer, WorkerOnboardingSidebarContainer } from 'containers/workerOnboarding';
import { WorkerOnboardingDocumentPrintContainer } from 'containers/workerOnboarding/print';
import { WorkerOnboardingsContainer } from 'containers/workerOnboardings';
import { WorkersContainer } from 'containers/workers';
import { WorkerContainerResolver } from 'containers/worker';
import { WorkerSafetyOrientationsContainer } from 'containers/workerSiteSafetyOrientations';
import { NotFoundContainer } from 'containers/notFound';
import { WorkerPrintContainer } from 'containers/print';
import { ReportsRedirectContainer } from 'containers/redirects/Reports';
import { WorkerReportsRedirectContainer } from 'containers/redirects/WorkerReports';
import { SearchWorkersContainer } from 'containers/searchWorkers';
import { SettingsContainer } from 'containers/settings';
import { UsersContainer } from 'containers/users';
import { RoutePage } from 'pages';
import { QrContainer } from 'containers/qr';
import { DevelopersContainer } from 'containers/developers';
import { DeveloperContainer } from 'containers/developer';
import { OrganizationContainer } from 'containers/organization';
import { OrganizationsContainer } from 'containers/organizations';
import { FormsContainer } from 'containers/forms';
import { FormSubmissionContainer } from 'containers/jobsiteFormSubmission';
import { FormSubmissionPrintContainer } from 'containers/jobsiteFormSubmission/print';
import { CamerasContainer } from 'containers/cameras';
import { AppRouteProps } from './types';

export const appRoutes: AppRouteProps[] = [
  {
    component: RoutePage,
    path: '/',
    exact: true,
    title: 'Home',
    container: HomeContainer,
    meta: [
      {
        name: 'description',
        content: 'Home',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/dashboard/?:tab?',
    title: 'Dashboard',
    exact: true,
    container: DashboardContainer,
    meta: [
      {
        name: 'description',
        content: 'Dashboard',
      },
    ],
    sidebar: {
      icon: GaugeHighIcon,
      item: 'Dashboard',
      pathname: `/dashboard`,
      order: 1,
      locked: (user): boolean => !user?.hasPaidAccess,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/worker-reports/?:tab?',
    title: 'Dashboard',
    exact: true,
    container: WorkerReportsRedirectContainer,
    meta: [
      {
        name: 'description',
        content: 'Dashboard',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/qr/:idNumber',
    title: 'QR',
    exact: true,
    container: QrContainer,
    meta: [
      {
        name: 'description',
        content: 'Qr',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/search',
    title: 'Search',
    exact: true,
    container: SearchWorkersContainer,
    meta: [
      {
        name: 'description',
        content: 'Search',
      },
    ],
  },
  // {
  //   component: RoutePage,
  //   path: '/jobsite/:jobsiteId/wellness?/:tab?',
  //   title: 'Wellness Tracking',
  //   exact: true,
  //   container: WellnessTrackingContainer,
  //   meta: [
  //     {
  //       name: 'description',
  //       content: 'Wellness Tracking',
  //     },
  //   ],
  //   acl: [
  //     'admin',
  //     'super',
  //     'jobsite_medic',
  //     'jobsite_medic_supervisor',
  //     'jobsite_medic_supervisor_lite',
  //     'jobsite_qualified_contact',
  //     'developer_group_admin',
  //     'jobsite_orientation_manager',
  //   ],
  // },
  // {
  //   component: RoutePage,
  //   path: '/wellness?/:tab?',
  //   title: 'Wellness Tracking',
  //   exact: true,
  //   container: WellnessTrackingContainer,
  //   meta: [
  //     {
  //       name: 'description',
  //       content: 'Wellness Tracking',
  //     },
  //   ],
  //   sidebar: {
  //     icon: StethoscopeIcon,
  //     item: 'Wellness Tracking',
  //     pathname: '/wellness?/:tab?',
  //     group: SidebarGroupsCategories.SidebarJobsite,
  //   },
  //   acl: [
  //     'admin',
  //     'super',
  //     'jobsite_medic',
  //     'jobsite_medic_supervisor',
  //     'jobsite_medic_supervisor_lite',
  //     'jobsite_qualified_contact',
  //     'developer_group_admin',
  //     'jobsite_orientation_manager',
  //   ],
  // },
  {
    component: RoutePage,
    path: '/jobsite/:jobsiteId/worker/:workerId/issue-badge/:step?',
    title: 'Issue Temp Badge',
    exact: true,
    container: IssueTempBadgeContainer,
    meta: [
      {
        name: 'description',
        content: 'Issue Temp Badge',
      },
    ],
    acl: [UserRoleKey.Admin, UserRoleKey.Super],
  },
  // {
  //   component: RoutePage,
  //   path: '/announcement/add',
  //   title: 'Add Jobsite Announcement',
  //   exact: true,
  //   container: AddJobsiteAnnouncementContainer,
  //   meta: [
  //     {
  //       name: 'description',
  //       content: 'Add Jobsite Announcement',
  //     },
  //   ],
  // },
  // {
  //   component: RoutePage,
  //   path: '/announcement/:id',
  //   title: 'Jobsite Announcement',
  //   exact: true,
  //   container: JobsiteAnnouncementContainer,
  //   meta: [
  //     {
  //       name: 'description',
  //       content: 'Jobsite Announcement',
  //     },
  //   ],
  // },
  // {
  //   component: RoutePage,
  //   path: '/announcements?/:tab?',
  //   title: 'Jobsite Announcements',
  //   exact: true,
  //   container: JobsiteAnnouncementsContainer,
  //   meta: [
  //     {
  //       name: 'description',
  //       content: 'Jobsite Announcements',
  //     },
  //   ],
  //   acl: [
  //     'admin',
  //     'super',
  //     'jobsite_admin',
  //     'jobsite_medic_supervisor',
  //     'jobsite_qualified_contact',
  //     'developer_group_admin',
  //     'jobsite_orientation_manager',
  //     'jobsite_site_safety_manager',
  //     'jobsite_editor',
  //   ],
  // },
  // {
  //   component: RoutePage,
  //   path: '/announcements?/:tab?',
  //   title: 'Jobsite Announcements',
  //   exact: true,
  //   container: JobsiteAnnouncementsContainer,
  //   meta: [
  //     {
  //       name: 'description',
  //       content: 'Jobsite Announcements',
  //     },
  //   ],
  //   sidebar: {
  //     icon: BullhornIcon,
  //     item: 'Announcements',
  //     pathname: '/announcements',
  //     group: SidebarGroupsCategories.SidebarJobsite,
  //   },
  //   acl: [
  //     'admin',
  //     'super',
  //     'jobsite_admin',
  //     'jobsite_medic_supervisor',
  //     'jobsite_qualified_contact',
  //     'developer_group_admin',
  //     'jobsite_orientation_manager',
  //     'jobsite_site_safety_manager',
  //     'jobsite_editor',
  //   ],
  // },
  {
    component: RoutePage,
    path: '/jobsite/:jobsiteId/access-activity?/:tab?',
    title: 'Access Events',
    exact: true,
    container: JobsiteAccessActivityContainer,
    meta: [
      {
        name: 'description',
        content: 'Access Events',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/access-activity/:tab?',
    title: 'Access Events',
    exact: true,
    container: JobsiteAccessActivityContainer,
    meta: [
      {
        name: 'description',
        content: 'Jobsite Access',
      },
    ],
    sidebar: {
      icon: IdCardIcon,
      item: 'Access Activity',
      pathname: '/access-activity',
      order: 3,
      locked: (user): boolean => !user?.hasPaidAccess,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/cameras',
    exact: true,
    title: 'Cameras',
    container: CamerasContainer,
    enabled: ({ user }): boolean => user?.hasCamerasEnabled,
    meta: [
      {
        name: 'description',
        content: 'Cameras',
      },
    ],
    sidebar: {
      icon: CctvIcon,
      item: 'Cameras',
      pathname: '/cameras',
      order: 4,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.JobsiteReader,
    ],
  },
  // {
  //   component: RoutePage,
  //   path: '/jobsite/:jobsiteId/workers',
  //   title: 'Jobsite Workers',
  //   exact: true,
  //   container: WorkersContainer,
  //   meta: [
  //     {
  //       name: 'description',
  //       content: 'Jobsite Workers',
  //     },
  //   ],
  //   acl: [
  //     UserRoleKey.Admin,
  //     UserRoleKey.Super,
  //     UserRoleKey.JobsiteAdmin,
  //     UserRoleKey.JobsiteMedicSupervisor,
  //     UserRoleKey.JobsiteSiteSafetyManager,
  //     UserRoleKey.JobsiteSecurity,
  //     UserRoleKey.JobsiteEditor,
  //     UserRoleKey.JobsiteReader,
  //     UserRoleKey.JobsiteDirectoryReader,
  //   ],
  // },
  {
    component: RoutePage,
    path: '/workers',
    title: 'Workers',
    exact: true,
    container: WorkersContainer,
    meta: [
      {
        name: 'description',
        content: 'Workers',
      },
    ],
    sidebar: {
      icon: UserHardHatIcon,
      item: 'Workers',
      pathname: '/workers',
      order: 2,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteDirectoryReader,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/contractors',
    title: 'Contractors',
    exact: true,
    container: ContractorsContainer,
    meta: [
      {
        name: 'description',
        content: 'Contractors',
      },
    ],
    sidebar: {
      icon: WrenchIcon,
      item: 'Contractors',
      pathname: '/contractors',
      order: 4,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteDirectoryReader,
      UserRoleKey.JobsiteEditor,
    ],
  },
  {
    component: RoutePage,
    path: '/announcements',
    title: 'Announcements',
    exact: true,
    container: AnnouncementsContainer,
    enabled: 'announcementsEnabled',
    meta: [
      {
        name: 'description',
        content: 'Announcements',
      },
    ],
    sidebar: {
      icon: BullhornIcon,
      item: 'Announcements',
      pathname: `/announcements`,
      order: 9,
      locked: (user): boolean => !user?.hasAnnouncementsEnabled,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecuritySupervisor,
    ],
  },

  {
    component: RoutePage,
    path: '/announcement/:announcementId',
    title: 'Jobsite Announcement',
    exact: true,
    hideNavbar: true,
    container: AnnouncementContainer,
    enabled: 'announcementsEnabled',
    meta: [
      {
        name: 'description',
        content: 'Jobsite Announcement',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecuritySupervisor,
    ],
  },
  {
    component: RoutePage,
    path: '/reports',
    title: 'Reports',
    exact: true,
    container: ReportsRedirectContainer,
    meta: [
      {
        name: 'description',
        content: 'Reports',
      },
    ],
    sidebar: {
      icon: ChartPieIcon,
      item: 'Reports',
      pathname: `/reports`,
      order: 12,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteEditor,
    ],
  },
  {
    component: RoutePage,
    path: '/onboarding/document/:jobsiteWorkerDocumentId/print',
    title: 'Print Worker Onboarding Document',
    exact: true,
    container: WorkerOnboardingDocumentPrintContainer,
    fullLayout: true,
    hideNavbar: true,
    meta: [
      {
        name: 'description',
        content: 'Print Worker Onboarding Document',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteEditor,
    ],
  },
  {
    component: RoutePage,
    path: '/onboarding/:jobsiteWorkerId/:step?',
    title: 'Jobsite Worker Onboarding',
    exact: true,
    hideNavbar: true,
    meta: [
      {
        name: 'description',
        content: 'Jobsite Worker Orientation',
      },
    ],
    container: WorkerOnboardingContainer,
    sidebarContainer: WorkerOnboardingSidebarContainer,
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteEditor,
    ],
  },
  {
    component: RoutePage,
    path: '/onboardings/?:tab?',
    title: 'Jobsite Onboarding',
    exact: true,
    container: WorkerOnboardingsContainer,
    meta: [
      {
        name: 'description',
        content: 'Jobsite Onboarding',
      },
    ],
    sidebar: {
      icon: CompassIcon,
      item: 'Onboarding',
      pathname: '/onboardings',
      order: 5,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/jobsite/:jobsiteId/site-orientations/?:tab?',
    title: 'Site Safety Orientations',
    exact: true,
    container: WorkerSafetyOrientationsContainer,
    meta: [
      {
        name: 'description',
        content: 'Site Safety Orientations',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteEditor,
    ],
  },
  {
    component: RoutePage,
    path: '/site-orientations/?:tab?',
    title: 'Site Orientation',
    exact: true,
    container: WorkerSafetyOrientationsContainer,
    meta: [
      {
        name: 'description',
        content: 'Site Orientation',
      },
    ],
    sidebar: {
      icon: HardHatIcon,
      item: 'Site Orientation',
      pathname: '/site-orientations',
      order: 6,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/jobsite/:jobsiteId/contractor/:contractorId/worker/add',
    title: 'Add Jobsite Worker for Contractor',
    exact: true,
    fullLayout: true,
    hideNavbar: true,
    container: AddJobsiteContractorWorkerContainer,
    meta: [
      {
        name: 'description',
        content: 'Create Worker',
      },
    ],
    acl: [UserRoleKey.Admin, UserRoleKey.Super, UserRoleKey.ContractorAdmin, UserRoleKey.ContractorForeman],
  },
  {
    component: RoutePage,
    path: '/contractor/:contractorId/worker/:workerId/:tab?',
    title: 'Worker',
    exact: true,
    container: WorkerContainerResolver,
    meta: [
      {
        name: 'description',
        content: 'Worker',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/jobsite/:jobsiteId/issue-badge/:step?',
    title: 'Issue Temp Badge',
    exact: true,
    container: IssueTempBadgeContainer,
    meta: [
      {
        name: 'description',
        content: 'Issue Temp Badge',
      },
    ],
    acl: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  },
  {
    component: RoutePage,
    path: '/worker/:workerId/print',
    title: 'Print Worker',
    exact: true,
    container: WorkerPrintContainer,
    fullLayout: true,
    hideNavbar: true,
    meta: [
      {
        name: 'description',
        content: 'Print Worker',
      },
    ],
    acl: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin, UserRoleKey.JobsiteMedicSupervisor, UserRoleKey.JobsiteEditor],
  },
  {
    component: RoutePage,
    path: '/worker/:workerId/:tab?',
    title: 'Worker',
    exact: true,
    container: WorkerContainerResolver,
    meta: [
      {
        name: 'description',
        content: 'Worker',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/entity-change/:entity?/:entityId?/:tab?',
    title: 'Entity Change Log',
    exact: true,
    container: EntityChangeContainer,
    meta: [
      {
        name: 'description',
        content: 'Entity Change Log',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/contractor/:contractorId?/:tab?',
    title: 'Contractor',
    exact: true,
    container: ContractorContainer,
    meta: [
      {
        name: 'description',
        content: 'Contractor',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/client/:clientId?/:tab?',
    title: 'Client',
    exact: true,
    container: DeveloperContainer,
    meta: [
      {
        name: 'description',
        content: 'Client',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/organization/:organizationId?/:tab?',
    title: 'Organization',
    exact: true,
    container: OrganizationContainer,
    meta: [
      {
        name: 'description',
        content: 'Organization',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/jobsites',
    title: 'Jobsites',
    exact: true,
    container: JobsitesContainer,
    meta: [
      {
        name: 'description',
        content: 'Jobsites',
      },
    ],
    sidebar: {
      icon: BuildingIcon,
      item: 'Jobsites',
      pathname: '/jobsites',
      order: 8,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
    ],
  },
  {
    component: RoutePage,
    path: '/users/:tab?',
    exact: true,
    title: 'Users',
    container: UsersContainer,
    meta: [
      {
        name: 'description',
        content: 'List all Users',
      },
    ],
    sidebar: {
      icon: UsersIcon,
      item: 'Users',
      pathname: '/users',
      order: 9,
    },
    acl: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin, UserRoleKey.Super],
  },
  {
    component: RoutePage,
    path: '/forms/:tab?',
    title: 'Forms',
    exact: true,
    container: FormsContainer,
    meta: [
      {
        name: 'description',
        content: 'Forms',
      },
    ],
    sidebar: {
      icon: ClipboardCheckIcon,
      item: 'Forms',
      pathname: '/forms',
      order: 9,
      locked: (user): boolean => !user?.hasPaidAccess,
    },
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/forms/:formType/:formSubmissionId/print',
    title: 'Print Form',
    exact: true,
    container: FormSubmissionPrintContainer,
    fullLayout: true,
    hideNavbar: true,
    meta: [
      {
        name: 'description',
        content: 'Print Form',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/forms/:formType/:formSubmissionId/:tab?',
    title: 'Form',
    exact: true,
    container: FormSubmissionContainer,
    hideNavbar: true,
    meta: [
      {
        name: 'description',
        content: 'Form',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
    ],
  },
  {
    component: RoutePage,
    path: '/clients',
    exact: true,
    title: 'Clients',
    container: DevelopersContainer,
    meta: [
      {
        name: 'description',
        content: 'List all Clients',
      },
    ],
    sidebar: {
      icon: LandmarkIcon,
      item: 'Clients',
      pathname: '/clients',
      order: 10,
    },
    acl: [UserRoleKey.Admin, UserRoleKey.Super],
  },
  {
    component: RoutePage,
    path: '/organizations',
    exact: true,
    title: 'Organizations',
    container: OrganizationsContainer,
    meta: [
      {
        name: 'description',
        content: 'List all Organizations',
      },
    ],
    sidebar: {
      icon: SitemapIcon,
      item: 'Organizations',
      pathname: '/organizations',
      order: 11,
    },
    acl: [UserRoleKey.Admin, UserRoleKey.Super],
  },
  {
    component: RoutePage,
    path: '/settings?/:id?',
    title: 'Settings',
    exact: true,
    container: SettingsContainer,
    meta: [
      {
        name: 'description',
        content: 'Settings',
      },
    ],
  },
  {
    component: RoutePage,
    path: '/jobsite/:jobsiteId?/settings',
    title: 'Jobsite',
    exact: true,
    hideNavbar: true,
    container: JobsiteConfigurationContainer,
    meta: [
      {
        name: 'description',
        content: 'Jobsite Settings',
      },
    ],
    acl: [UserRoleKey.Admin, UserRoleKey.Super],
  },
  {
    component: RoutePage,
    path: '/jobsite/:jobsiteId?/:tab?',
    title: 'Jobsite',
    exact: true,
    container: JobsiteContainer,
    meta: [
      {
        name: 'description',
        content: 'Jobsite',
      },
    ],
    acl: [
      UserRoleKey.Admin,
      UserRoleKey.Super,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
      UserRoleKey.JobsiteAdmin,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteSecurity,
      UserRoleKey.JobsiteSecuritySupervisor,
      UserRoleKey.JobsiteSiteSafetyManager,
      UserRoleKey.JobsiteSiteSafetyManagerFitout,
    ],
  },
  {
    component: RoutePage,
    path: '*',
    exact: true,
    title: 'Page Not Found',
    container: NotFoundContainer,
    meta: [
      {
        name: 'description',
        content: 'Not Found',
      },
    ],
  },
];
