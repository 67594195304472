/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import '@odin-labs/components/odin.scss';
import 'tailwind.scss';
import { DropdownButton, NewDropdownButton } from '@odin-labs/components';
import { App } from 'app/App';
import { initializeApp } from 'app/helpers/utils';
import { getApiBaseUrl, initializeAppConfig } from 'config/app';
import { polyfill } from 'es6-promise';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';

import 'flatpickr/dist/themes/light.css';
import 'react-dates/initialize';

import { CustomWindow } from 'types';

import './scss/main.scss';

polyfill();

// Grab the state from a global injected into
// server-generated HTML
const customWindow: CustomWindow = window;
// eslint-disable-next-line no-underscore-dangle
const { apolloState, appConfigState } = customWindow.__CLIENT_APP_STATE__ ?? {};
// eslint-disable-next-line no-underscore-dangle
delete customWindow.__CLIENT_APP_STATE__;

const appConfig = initializeAppConfig(appConfigState);
const { history } = initializeApp(appConfig);

if (appConfig.ddEnabled && appConfig.ddRumClientToken) {
  datadogLogs.init({
    clientToken: appConfig.ddRumClientToken,
    site: appConfig.ddRumSite,
    service: appConfig.ddService,
    env: appConfig.ddEnv,
    version: appConfig.ddVersion,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

if (appConfig.ddEnabled && appConfig.ddRumClientToken && appConfig.ddRumApplicationId) {
  const baseApiUrl = getApiBaseUrl();
  datadogRum.init({
    applicationId: appConfig.ddRumApplicationId,
    clientToken: appConfig.ddRumClientToken,
    site: appConfig.ddRumSite,
    service: appConfig.ddService,
    env: appConfig.ddEnv,
    version: appConfig.ddVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Allows the DD SDK to set proper request tracing headers on API calls and fully connect frontend
    // RUM sessions to backend logs and traces
    allowedTracingUrls: [baseApiUrl, /https:\/\/.*\.useodin\.com/],
    enableExperimentalFeatures: ['feature_flags'],
  });
  datadogRum.startSessionReplayRecording();
}

const APP_ID = 'app';

// dropdown popup will show up under #app element
DropdownButton.Portal = `#${APP_ID}`;
NewDropdownButton.Portal = `#${APP_ID}`;

// Fix: Warning: Expected server HTML to contain a matching <div> in <div>.
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

renderMethod(<App apolloState={apolloState} history={history} />, document.getElementById(APP_ID));
