import { getPrettyFormattedUtcDate, getPrettyFormattedUtcTime } from 'utils/dates';
import { emptyIf } from 'utils';
import { FormSubmission, FormSubmissionColumn, FormColumnName } from 'containers/forms/formSubmissionsWidget/types';

export const extraColumns: FormSubmissionColumn[] = [
  {
    id: FormColumnName.Form,
    Header: 'Form',
    accessor: (formSubmission: FormSubmission): string => formSubmission.jobsiteForm.form.name,
  },
  {
    id: FormColumnName.Date,
    Header: 'Date',
    accessor: (formSubmission: FormSubmission): string =>
      getPrettyFormattedUtcDate(formSubmission.startAt, formSubmission.timeZone),
  },
  {
    id: FormColumnName.StartTime,
    Header: 'Start',
    accessor: (formSubmission: FormSubmission): string => {
      const startTime = getPrettyFormattedUtcTime(formSubmission.startAt, formSubmission.timeZone);
      return emptyIf({ value: startTime, valueTest: '12:00 AM', extraTest: !formSubmission.isStartTimeSpecified });
    },
  },
  {
    id: FormColumnName.EndTime,
    Header: 'End',
    accessor: (formSubmission: FormSubmission): string =>
      getPrettyFormattedUtcTime(formSubmission.endAt, formSubmission.timeZone),
  },
];
