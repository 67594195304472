import React from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
  WorkerCard,
  WorkerCardType,
  useAddAndIssueMobileBadgeMutation,
  MutationRemoveCardFromWorkerArgs,
  Success,
} from 'apollo/generated/client-operations';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError, hasGraphQLErrorName } from 'utils/error';
import { CheckIcon } from 'components/icons';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { REMOVE_CARD_FROM_WORKER } from 'containers/worker/helpers/queries';
import { Worker } from 'containers/worker/types';
import { getFormDefaultValues, getFormInputs } from './IssueMobileBadgeModalContent.forms';
import { IssueMobileBadgeFormData } from './types';

export type IssueMobileBadgeModalContentProps = {
  setOpen: () => void;
  onAction: (issueMobileBadgeFormData?: IssueMobileBadgeFormData) => void;
  jobsiteId: string;
  workerId: string;
  workerCardFormatId: string;
  workerCards: WorkerCard[];
  worker: Worker;
};

export function IssueMobileBadgeModalContent(props: IssueMobileBadgeModalContentProps): React.ReactElement {
  const { setOpen, onAction, workerId, jobsiteId, workerCardFormatId, workerCards, worker } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [addAndIssueMobileBadge] = useAddAndIssueMobileBadgeMutation();

  const [removeCardFromWorker] = useMutation<Success, MutationRemoveCardFromWorkerArgs>(REMOVE_CARD_FROM_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      setIsFetching(false);
    },
  });

  const onSubmit: FormOnSubmit<IssueMobileBadgeFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    try {
      const { phoneNumber, badges } = nullifyEmptyFields(data);

      const badgesToDeactivate = badges.map((bs) => bs.workerCard);
      if (badgesToDeactivate.length > 0) {
        const badgeIds = badgesToDeactivate.map((wc) => wc.workerCardId);
        await removeCardFromWorker({ variables: { workerCardIds: badgeIds, deactivateCardReason: 'issue new' } });
      }

      await addAndIssueMobileBadge({
        variables: {
          jobsiteId,
          workerId,
          workerCardInput: {
            isTemporary: false,
            startDate: moment().toDate(),
            type: WorkerCardType.MobileBadge,
            workerCardFormatId,
          },
          newPhoneNumber: phoneNumber,
        },
      });

      onAction();
      AlertInstance.alert('tc', 'success', 'Success', 'Mobile badge issued successfully');
    } catch (error) {
      event.preventDefault();
      if (hasGraphQLErrorName(error, 'ResourceAlreadyExistsError')) {
        onAction(data);
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const formInputs = getFormInputs();
  const defaultValues = React.useMemo(() => getFormDefaultValues(worker, workerCards), [worker, workerCards]);

  return (
    <ModalFormContent
      setOpen={setOpen}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Issue mobile badge"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
