import React from 'react';
import {
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { DateTimeCell, DateTimeCellData } from 'containers/jobsiteAccessActivity/helpers/cells';
import { GetUserRolesOrderByField } from 'apollo/generated/client-operations';
import { faUserPen, faUserTimes } from 'components/icons';
import { WorkerUserRole } from './types';

export type WorkerUserRoleColumn = Column<WorkerUserRole>;

const UserPenIcon = getFixedWidthFaIcon({ icon: faUserPen });
const UserTimesIcon = getFixedWidthFaIcon({ icon: faUserTimes });

enum WorkerUserRoleColumnId {
  Jobsite = 'jobsite',
  Title = 'title',
  AddedDateTime = 'addedDateTime',
  UpdatedDateTime = 'updatedDateTime',
  Actions = 'actions',
}

export const getColumns = ({
  openEditModal,
  openDeleteModal,
}: {
  openEditModal: (siteArea: WorkerUserRole) => void;
  openDeleteModal: (siteArea: WorkerUserRole) => void;
}): WorkerUserRoleColumn[] => [
  {
    id: WorkerUserRoleColumnId.Jobsite,
    Header: 'Role',
    // cellClassName: cn('odin-whitespace-pre-wrap'),
    accessor: (): string[] => [],
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (role: WorkerUserRole): ChipsCellComponentProps => ({
      chips: [
        {
          text: role.object.objectDetails.name,
          secondaryText: role.displayName,
          withDot: false,
        },
      ],
    }),
  },
  {
    id: WorkerUserRoleColumnId.Title,
    Header: 'Title',
    accessor: (role: WorkerUserRole): string => role?.object.objectDetails.title,
  },
  {
    id: WorkerUserRoleColumnId.AddedDateTime,
    Header: 'Added at',
    accessor: (role: WorkerUserRole): DateTimeCellData => {
      const { mappingCreatedAt } = role ?? {};
      const timeZoneName = ''; // moment.tz(mappingCreatedAt, timeZone).zoneAbbr();
      return mappingCreatedAt && { date: new Date(mappingCreatedAt), timeZoneName };
    },
    Cell: DateTimeCell,
  },
  {
    id: WorkerUserRoleColumnId.UpdatedDateTime,
    Header: 'Updated at',
    accessor: (role: WorkerUserRole): DateTimeCellData => {
      const { mappingUpdatedAt } = role ?? {};
      const timeZoneName = ''; // moment.tz(mappingUpdatedAt, timeZone).zoneAbbr();
      return mappingUpdatedAt && { date: new Date(mappingUpdatedAt), timeZoneName };
    },
    Cell: DateTimeCell,
  },
  {
    id: WorkerUserRoleColumnId.Actions,
    Header: '',
    Cell: DropdownButtonCell,
    CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
    componentProps: (role: WorkerUserRole): DropdownButtonCellComponentProps => ({
      cellAlignment: 'right',
      menuItems: [
        [
          {
            icon: UserPenIcon,
            text: 'Edit User Role',
            theme: 'secondary',
            onClick: (): void => openEditModal(role),
          },
          {
            icon: UserTimesIcon,
            text: 'Remove User Role',
            theme: 'danger',
            onClick: (): void => openDeleteModal(role),
          },
        ],
      ],
    }),
  },
];

export const orderByFields: Record<string, GetUserRolesOrderByField> = {
  [WorkerUserRoleColumnId.Jobsite]: GetUserRolesOrderByField.Jobsite,
  [WorkerUserRoleColumnId.Title]: GetUserRolesOrderByField.Title,
  [WorkerUserRoleColumnId.AddedDateTime]: GetUserRolesOrderByField.CreatedAt,
  [WorkerUserRoleColumnId.UpdatedDateTime]: GetUserRolesOrderByField.UpdatedAt,
};
