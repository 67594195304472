import { getPrettyFormattedUtcDate } from 'utils/dates';
import { DocumentKey } from 'containers/worker/utils';
import { citizenshipStatusOptions } from 'containers/worker/helpers/utils';
import { getJobsiteContractorsRadioGroupOptions } from 'containers/contractor/helpers';
import {
  SelfOnboardingState,
  SelfOnboardingUser,
  SelfOnboardingWorker,
  JobsiteInvitation,
  Jobsite,
  LanguageOption,
} from './types';
import {
  statesOptions,
  unionAffiliationOptions,
  jobTitleOptions,
  tradeOptions,
  primaryLanguageOptions,
  genderOptions,
  raceOptions,
  veteranOptions,
} from './steps/profileStep/ProfileStep.forms';
import { emergencyContactRelationshipOptions } from './steps/emergencyContactStep/EmergencyContactStep.forms';
import { getValuesFromAdditionalFields, resolveDocumentKey } from './steps/documentStep/DocumentStep.forms';
import { getFeaturesModule } from './helpers/utils';

const getBasicInfoFormData = (user: SelfOnboardingUser): SelfOnboardingState['basicInfo'] => {
  const { identity, worker } = user ?? {};
  const { firstName, lastName, email, phoneNumber } = identity ?? {};
  const { middleInitial, suffix, birthDate, ssnLastFour } = worker ?? {};

  const document = worker?.documents.edges.find(
    ({ node }) => node.jobsiteWorkerDocumentType.workerDocumentType.key === DocumentKey.WorkerConsentDocument,
  )?.node;

  const { additionalFieldValues } = document?.latestVersion ?? {};
  const valuesFromAdditionalFields = getValuesFromAdditionalFields(
    DocumentKey.WorkerConsentDocument,
    additionalFieldValues,
  );

  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    middleInitial,
    suffix,
    birthDate: getPrettyFormattedUtcDate(birthDate),
    ssnLastFour,
    workerConsentStatus: valuesFromAdditionalFields.acknowledgmentStatus,
  };
};

const getProfileFormData = (worker: SelfOnboardingWorker): SelfOnboardingState['profile'] => {
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    primaryLanguage,
    citizenshipStatus,
    race,
    gender,
    isVeteran,
    trade,
    jobTitle,
    unionAffiliation,
  } = worker ?? {};

  return {
    addressLine1,
    addressLine2,
    addressCity,
    addressState: statesOptions.find((opt) => opt.value === addressState),
    addressZipCode,
    primaryLanguage: primaryLanguageOptions.find((opt) => opt.value === primaryLanguage),
    citizenshipStatus: citizenshipStatusOptions.find((opt) => opt.value === citizenshipStatus),
    race: raceOptions.find((opt) => opt.value === race),
    gender: genderOptions.find((opt) => opt.value === gender),
    isVeteran: veteranOptions[isVeteran ? 0 : 1],
    trade: tradeOptions.find((opt) => opt.value === trade),
    jobTitle: jobTitleOptions.find((opt) => opt.value === jobTitle),
    unionAffiliation: unionAffiliationOptions.find((opt) => opt.value === unionAffiliation),
  };
};

const getEmergencyContactFormData = (worker: SelfOnboardingWorker): SelfOnboardingState['emergencyContact'] => {
  const { emergencyContactName, emergencyContactRelationship, emergencyContactPhone } = worker ?? {};

  return {
    emergencyContactName,
    emergencyContactRelationship: emergencyContactRelationshipOptions.find(
      (opt) => opt.value === emergencyContactRelationship,
    ),
    emergencyContactPhone,
  };
};

const getDocumentTypes = (jobsite: Jobsite, language: LanguageOption): SelfOnboardingState['documentTypes'] => {
  return Object.fromEntries(
    jobsite.documentTypes.edges.map(({ node }) => [
      node.workerDocumentType.key,
      {
        id: node.id,
        acknowledgmentFile:
          node.files?.find((f) => f.language === language.value) ?? node.files?.find((f) => f.language === 'en'),
      },
    ]),
  );
};

const getDocumentsFormData = (
  worker: SelfOnboardingWorker,
  jobsite: Jobsite,
  language: LanguageOption,
): SelfOnboardingState['documents'] => {
  const documentTypes = jobsite && getDocumentTypes(jobsite, language);
  return worker?.documents.edges
    .map(({ node: document }): SelfOnboardingState['documents'][number] => {
      const documentKey = resolveDocumentKey(document.jobsiteWorkerDocumentType.workerDocumentType.key);
      if (!documentKey) return null;

      const { jobsiteWorkerDocumentVersionId, files, additionalFieldValues } = document.latestVersion;
      const valuesFromAdditionalFields = getValuesFromAdditionalFields(documentKey, additionalFieldValues);

      return {
        versionId: jobsiteWorkerDocumentVersionId,
        key: documentKey,
        acknowledgmentFile: documentTypes[documentKey]?.acknowledgmentFile,
        frontFile: files?.[0],
        backFile: files?.[1],
        ...valuesFromAdditionalFields,
      };
    })
    .filter(Boolean);
};

type GetSelfOnboardingStateArgs = {
  user: SelfOnboardingUser;
  jobsiteInvitation: JobsiteInvitation;
  contractorId: string;
  language: LanguageOption;
};

export const getSelfOnboardingLanguageDependentState = (
  args: Omit<GetSelfOnboardingStateArgs, 'contractorId'>,
): Pick<SelfOnboardingState, 'documentTypes' | 'documents'> => {
  const { jobsiteInvitation, language, user = null } = args;
  const { jobsite = null } = jobsiteInvitation?.jobsiteContractor ?? {};
  const { worker = null } = user ?? {};

  return {
    documentTypes: jobsite && getDocumentTypes(jobsite, language),
    documents: jobsite && getDocumentsFormData(worker, jobsite, language),
  };
};

export const getSelfOnboardingState = (args: GetSelfOnboardingStateArgs): SelfOnboardingState => {
  const { jobsiteInvitation = null, user = null, contractorId = null } = args;
  const { jobsite, contractor } = jobsiteInvitation?.jobsiteContractor ?? {};
  const { worker = null, userAccountId = null } = user ?? {};
  const {
    workerId = null,
    quickCode = null,
    jobsiteWorkers,
    profilePicture = null,
    editableFields,
    selfOnboardingCurrentStep = null,
    isAssignedToMultipleJobsites = null,
  } = worker ?? {};
  const { node: jobsiteWorker = null } = jobsiteWorkers?.edges?.[0] ?? {};

  const jobsiteContractors = jobsite?.jobsiteContractors.edges.map(({ node }) => node);
  const contractorOptions = getJobsiteContractorsRadioGroupOptions(jobsiteContractors);

  return {
    matchedWorker: null,
    worker: worker && { workerId, quickCode, editableFields, selfOnboardingCurrentStep, isAssignedToMultipleJobsites },
    jobsiteWorker,
    userAccountId,
    didWorkerMatch: false,
    shouldSelectContractor: Boolean(
      contractor?.isDefaultContractor && getFeaturesModule(jobsite?.modules)?.enforceValidContractorInSelfOnboarding,
    ),
    contractorOptions,
    contractorId: contractorOptions?.find((opt) => opt.value === contractorId) ?? null,
    basicInfo: user && getBasicInfoFormData(user),
    profile: worker && getProfileFormData(worker),
    emergencyContact: worker && getEmergencyContactFormData(worker),
    profilePhoto: { file: profilePicture },
    verificationPhoneNumber: null,
    ...getSelfOnboardingLanguageDependentState(args),
  };
};
