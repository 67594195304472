import React, { ReactElement } from 'react';
import { Avatar } from '@odin-labs/components';
import { getInitialsForUser, useBoolean, useUpdatableState } from 'utils';
import { PenIcon } from 'components/icons';
import { UpdateProfilePictureModalContainer } from 'containers/modals';
import { WorkerHeaderPictureProps } from './types';
import { classes } from './WorkerHeaderPicture.style';

export function WorkerHeaderPicture({ worker, className }: WorkerHeaderPictureProps): ReactElement {
  const user = worker?.user;

  const [profilePicture, setProfilePicture] = useUpdatableState<string>(worker?.profilePicture?.downloadUrl);
  const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false);

  const onPictureUpdated = (profilePictureUrl: string): void => {
    setProfilePicture(profilePictureUrl);
    closeModal();
  };

  return (
    <div data-test="worker-header-picture" className={classes.headerPicture(className)}>
      <div className="odin-relative">
        <Avatar size="2xl" src={profilePicture} placeholder={getInitialsForUser(user)} objectFit="cover" />
        <button className={classes.headerEditPictureButton} onClick={openModal}>
          <PenIcon className={classes.headerEditPictureIcon} aria-hidden="true" />
        </button>
      </div>
      {isModalOpen && (
        <UpdateProfilePictureModalContainer isOpen worker={worker} onCancel={closeModal} onAction={onPictureUpdated} />
      )}
    </div>
  );
}
