import moment, { Moment } from 'moment';
import cn from 'classnames';
import {
  DropdownFilterItemConfigProps,
  DropdownFilterOption,
  FilterItems,
  ResetButtonFilterItemConfigProps,
} from '@odin-labs/components';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { WorkerDashboardData, DashboardFilters } from 'containers/dashboard/types';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';
import { CalendarDayIcon } from 'components/icons';

export type DashboardFiltersOptions = {
  developers: DropdownFilterOption[];
  jobsites: DropdownFilterOption[];
  contractors: DropdownFilterOption[];
  compliant: DropdownFilterOption[];
  overnight: DropdownFilterOption[];
  currentlyOnsite: DropdownFilterOption[];
  trade: DropdownFilterOption[];
  tradeClass: DropdownFilterOption[];
};

function getFilterOptions(items: { name: string }[]): DropdownFilterOption[] {
  return items?.map(({ name }) => ({ value: name, label: name })) ?? [];
}

function getWorkShiftOptions(currentValue?: string): DropdownFilterOption[] {
  const values = [
    { label: 'Overnight Shift only', value: 'yes' },
    { label: 'Day Shift only', value: 'no' },
  ];
  if (currentValue?.length) return values.filter((dfo) => dfo.value === currentValue);
  return values;
}

export function getFiltersOptions({
  dashboardData,
  jobsites,
  overnight,
}: {
  dashboardData: WorkerDashboardData;
  jobsites: Jobsite[];
  overnight: string;
}): DashboardFiltersOptions {
  return {
    developers: getDeveloperOptionsFromJobsites(jobsites),
    jobsites: jobsites?.map((j) => ({ value: j.jobsiteId, label: j.name })) ?? [],
    contractors: getFilterOptions(dashboardData?.contractorBreakdowns),
    compliant: getFilterOptions(dashboardData?.compliantBreakdowns),
    overnight: getWorkShiftOptions(overnight),
    currentlyOnsite: getFilterOptions(dashboardData?.activeBreakdowns),
    trade: getFilterOptions(dashboardData?.tradeBreakdowns),
    tradeClass: getFilterOptions(dashboardData?.tradeClassBreakdowns),
  };
}

export const getFilterItems = ({
  filtersOptions,
  developerIds,
  jobsiteIds,
  contractor,
  trade,
  tradeClass,
  compliant,
  overnight,
  currentlyOnsite,
  startDate,
  endDate,
  defaultStartDate,
  defaultEndDate,
}: {
  filtersOptions: DashboardFiltersOptions;
  developerIds: string[];
  jobsiteIds: string[];
  contractor: string;
  trade: string;
  tradeClass: string;
  compliant: string;
  overnight: string;
  currentlyOnsite: string;
  startDate: Moment | string;
  endDate: Moment | string;
  defaultStartDate: Moment | string;
  defaultEndDate: Moment | string;
}): FilterItems<DashboardFilters> => {
  return {
    dateRange: {
      header: 'Date',
      type: 'datePicker',
      defaultValue: { startDate, endDate },
      layout: cn('odin-col-span-full'),
      componentProps: {
        isRange: true,
      },
    },
    today: {
      header: 'Today',
      type: 'resetButton',
      componentProps: (filterValues: DashboardFilters): ResetButtonFilterItemConfigProps => ({
        icon: CalendarDayIcon,
        disabled:
          moment(defaultStartDate).isSame(filterValues.dateRange?.startDate, 'day') &&
          moment(defaultEndDate).isSame(filterValues.dateRange?.endDate, 'day'),
        filtersValues: {
          dateRange: {
            startDate: moment(defaultStartDate),
            endDate: moment(defaultEndDate),
          },
        },
      }),
    },
    currentlyOnsite: {
      header: 'Currently Onsite',
      type: 'dropdown',
      defaultValue: currentlyOnsite,
      isLineBreaker: true,
      componentProps: (filterValues: DashboardFilters): DropdownFilterItemConfigProps => ({
        options: filtersOptions?.currentlyOnsite ?? [],
        isMulti: false,
        allowSearch: false,
        disabled:
          !filterValues.dateRange?.startDate?.isSame(defaultStartDate, 'day') ||
          !filterValues.dateRange?.endDate?.isSame(defaultEndDate, 'day'),
      }),
    },
    developerIds: {
      header: 'Client',
      type: 'dropdown',
      defaultValue: developerIds,
      componentProps: {
        options: filtersOptions?.developers ?? [],
        allowSearch: false,
      },
    },
    jobsiteIds: {
      header: 'Jobsite',
      type: 'dropdown',
      defaultValue: jobsiteIds,
      componentProps: {
        options: filtersOptions?.jobsites ?? [],
      },
    },
    contractor: {
      header: 'Contractor',
      type: 'dropdown',
      defaultValue: contractor,
      componentProps: {
        options: filtersOptions?.contractors ?? [],
        isVirtual: true,
        isMulti: false,
      },
    },
    compliant: {
      header: 'Compliant',
      type: 'dropdown',
      defaultValue: compliant,
      componentProps: {
        options: filtersOptions?.compliant ?? [],
        isMulti: false,
      },
    },
    trade: {
      header: 'Trade',
      type: 'dropdown',
      defaultValue: trade,
      componentProps: {
        options: filtersOptions?.trade ?? [],
        isMulti: false,
      },
    },
    tradeClass: {
      header: 'Title',
      type: 'dropdown',
      defaultValue: tradeClass,
      componentProps: {
        options: filtersOptions?.tradeClass ?? [],
        isMulti: false,
      },
    },
    overnight: {
      header: 'Shifts',
      type: 'dropdown',
      defaultValue: overnight,
      componentProps: {
        options: filtersOptions?.overnight ?? [],
        isMulti: false,
      },
    },
  };
};
