import React from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftIcon, PrintIcon, RedoIcon } from 'components/icons';
import { classes } from './WorkerHeaderActions.style';
import { WorkerHeaderButton } from './workerHeaderButton/WorkerHeaderButton';
import { WorkerHeaderActionsProps } from './types';

export function WorkerHeaderActions({
  children,
  baseRoute,
}: React.PropsWithChildren<WorkerHeaderActionsProps>): React.ReactElement {
  const history = useHistory();

  const handlePrintClick = (): void => {
    window.open(`${baseRoute}/print`, '_blank');
  };

  const handleReloadClick = (): void => {
    window.location.reload();
  };

  return (
    <>
      <div data-test="worker-header-actions-left">
        <WorkerHeaderButton
          className={classes.headerBackButton}
          icon={ArrowLeftIcon}
          onClick={(): void => history.goBack()}
        >
          <span className={classes.headerBackButtonText}>Back</span>
        </WorkerHeaderButton>
      </div>
      <div data-test="worker-header-actions-right" className={classes.headerActionsRightContainer}>
        <WorkerHeaderButton icon={RedoIcon} onClick={handleReloadClick} />
        <WorkerHeaderButton icon={PrintIcon} onClick={handlePrintClick} />
        {children}
      </div>
    </>
  );
}
