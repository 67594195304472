import React from 'react';
import { Alert } from '@odin-labs/components';
import { useBoolean } from 'utils';
import { UnlockIcon } from 'components/icons';
import { UnlockProfileModal } from 'containers/worker/modals';
import { Worker } from 'containers/worker/types';

export type LockedWorkerAlertProps = {
  worker: Worker;
};

export function LockedWorkerAlert(props: LockedWorkerAlertProps): React.ReactElement {
  const { worker } = props;

  const {
    value: isUnlockProfileModalOpen,
    setTrue: openUnlockProfileModal,
    setFalse: closeUnlockProfileModal,
  } = useBoolean();

  return (
    <>
      <Alert
        text="You don’t currently have access to this worker’s full profile"
        actionText="Unlock Profile"
        actionIcon={UnlockIcon}
        onAction={openUnlockProfileModal}
      />
      {isUnlockProfileModalOpen && (
        <UnlockProfileModal isOpen={isUnlockProfileModalOpen} closeModal={closeUnlockProfileModal} worker={worker} />
      )}
    </>
  );
}
