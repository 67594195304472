import React, { ReactElement } from 'react';
import { SequencedModalForm } from 'components/form/SequencedModalForm';
import {
  AnnouncementScheduleType,
  CreateAnnouncementModalContainerProps,
  ScheduleAnnouncementFormData,
} from 'containers/announcements/types';
import { ArrowRightIcon } from 'components/icons';
import { FormOnSubmit } from 'components/form';
import { getDateTime } from 'utils/dates';
import { getDefaultValues, getFormInputs } from './ScheduleAnnouncementModalContainer.forms';

export function ScheduleAnnouncementModalContainer({
  announcement,
  updateAnnouncement,
  onBack,
  onCancel,
  onAction,
}: CreateAnnouncementModalContainerProps): ReactElement {
  const formInputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues(announcement), [announcement]);

  const onSubmit: FormOnSubmit<ScheduleAnnouncementFormData> = async (data): Promise<void> => {
    const { scheduleType, startTime, startDate, timeZone } = data;
    const startAt =
      scheduleType.value === AnnouncementScheduleType.Immediate
        ? null
        : getDateTime({ date: startDate, time: startTime, timeZone });
    updateAnnouncement({ startAt });
    onAction();
  };

  return (
    <SequencedModalForm
      open
      setOpen={onCancel}
      onBack={onBack}
      title="Announcement Schedule"
      actionText="Continue"
      showBackButton
      actionIcon={ArrowRightIcon}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
    />
  );
}
