import React from 'react';
import {
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { faTrash, faWrench } from 'components/icons';
import { EditableSiteArea } from 'containers/jobsiteConfiguration/components/siteAreas/modals';

export type SiteAreaColumn = Column<EditableSiteArea>;

const formattedCoordinate = (value: number): string => value && `${value}\u00B0`;

const WrenchIcon = getFixedWidthFaIcon({ icon: faWrench });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

export const getColumns = ({
  openEditModal,
  openDeleteModal,
}: {
  openEditModal: (siteArea: EditableSiteArea) => void;
  openDeleteModal: (siteArea: EditableSiteArea) => void;
}): SiteAreaColumn[] => [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
  },
  {
    id: 'radius',
    Header: 'Radius',
    accessor: (siteArea: EditableSiteArea): string => `${siteArea.radius}m`,
  },
  {
    id: 'latLong',
    Header: 'Lat/long',
    accessor: (siteArea: EditableSiteArea): string =>
      [formattedCoordinate(siteArea.latitude), formattedCoordinate(siteArea.longitude)].join(', '),
  },
  {
    id: 'actions',
    Header: '',
    Cell: DropdownButtonCell,
    CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
    componentProps: (siteArea: EditableSiteArea): DropdownButtonCellComponentProps => ({
      cellAlignment: 'right',
      menuItems: [
        [
          {
            icon: WrenchIcon,
            text: 'Edit Area',
            theme: 'secondary',
            onClick: (): void => openEditModal(siteArea),
          },
          {
            icon: TrashIcon,
            text: 'Delete Area',
            theme: 'danger',
            onClick: (): void => openDeleteModal(siteArea),
          },
        ],
      ],
    }),
  },
];
