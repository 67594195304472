import React from 'react';
import QRCode from 'react-qr-code';
import { PreOnboardingInstructionsPdf } from 'containers/contractor/components';
import { PdfImageData, getPdfImageData } from 'utils/pdf';
import { DownloadIcon } from 'components/icons';
import { Logo } from 'components/logo';
import { PdfDocumentDownloadLink } from 'components/pdf';
import { titleToSnakeCase } from 'utils';

export const classes = {
  skeleton: 'odin-text-transparent odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
};

export type PdfInstructionsDownloadLinkProps = {
  onboardingLink: string;
  jobsiteName: string;
  contractorName: string;
};

type QRCodeRef = QRCode & SVGSVGElement;

const setRefFactory =
  <T extends Element>(setData: (data: PdfImageData) => void) =>
  (element: T): void => {
    if (element) {
      getPdfImageData(element).then(setData);
    }
  };

export function PdfInstructionsDownloadLink(props: PdfInstructionsDownloadLinkProps): React.ReactElement {
  const { onboardingLink, jobsiteName, contractorName } = props;

  const [qrCodeImage, setQrCodeImage] = React.useState<PdfImageData>();
  const [logoImage, setLogoImage] = React.useState<PdfImageData>();

  const document = (
    <PreOnboardingInstructionsPdf
      qrCodeImage={qrCodeImage}
      logoImage={logoImage}
      jobsiteName={jobsiteName}
      contractorName={contractorName}
    />
  );

  const setQRCodeRef = React.useCallback(setRefFactory<QRCodeRef>(setQrCodeImage), [setQrCodeImage]);
  const setLogoRef = React.useCallback(setRefFactory<HTMLImageElement>(setLogoImage), [setLogoImage]);

  const jobsiteNameAsSnake = titleToSnakeCase(jobsiteName);
  const contractorNameAsSnake = titleToSnakeCase(contractorName);
  const fileName = `self-onboarding_instructions-${jobsiteNameAsSnake}-${contractorNameAsSnake}.pdf`;

  return (
    <>
      <QRCode className="odin-hidden" ref={setQRCodeRef} value={onboardingLink} size={280} />
      <Logo className="odin-hidden" ref={setLogoRef} />
      <PdfDocumentDownloadLink
        document={document}
        fileName={fileName}
        text="Download PDF Instructions"
        icon={DownloadIcon}
      />
    </>
  );
}
